<template>
  <Dialog
    v-model:visible="model"
    modal
    :draggable="false"
    :header="$t(header)"
    :style="{ width: '30rem' }"
  >
    <div class="flex flex-col gap-4">
      <div class="text-base">
        {{ $t('egg_up.modal_body_first') }}
      </div>
      <div class="text-base">
        {{ $t('egg_up.modal_body_second') }}
        <br>
        {{ $t('egg_up.modal_body_third') }}
      </div>
      <div class="flex align-items-center">
        <Checkbox
          v-model="privacyChecked"
          input-id="privacy-check"
          binary
          :invalid="formInvalid"
          @change="formInvalid = false"
        />
        <label
          for="privacy-check"
          v-html="$t('egg_up.modal_accept_privacy')"
        />
      </div>
      <div
        v-if="formInvalid"
        class="text-red-500 text-sm mt-[-0.5rem]"
      >
        {{ $t('egg_up.modal_accept_privacy_error') }}
      </div>
      <div class="flex flex-col-reverse sm:flex-row justify-end gap-2">
        <Button
          type="button"
          :label="$t('egg_up.modal_cancel')"
          severity="tertiary"
          @click="model = false"
        />
        <Button
          type="button"
          class="text-base sm:text-lg"
          :label="$t(saveLabel)"
          @click="submitForm"
        />
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import { computed, defineModel, defineProps, ref } from 'vue';
// import { useInterview } from '@/composables/userData'
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import {useQuestionnaire} from "@/composables/useQuestionnaire";
import {useToasts} from "@/composables/useToasts";

// const { startInterview } = useInterview()
const { startQuestionnaire } = useQuestionnaire()
const privacyChecked = ref(false);
const formInvalid = ref(false);

const props = defineProps({
  mode: {
    type: String,
    required: true,
    default: 'initialize',
  },
});

const saveLabel = computed(() => {
  return props.mode === 'initialize' ? 'egg_up.modal_save_label_initialize' : 'egg_up.modal_save_label_continue';
});

const header = computed(()=> {
  return props.mode === 'initialize' ? 'egg_up.modal_header_initialize' : 'egg_up.modal_header_continue';
})
const model = defineModel({ default: false });
const { toastError } = useToasts()

const isApple = ref(false)
let UA = navigator.userAgent
let isSafari =
  /\b(iPad|iPhone|iPod|Safari)\b/.test(UA) &&
  /WebKit/.test(UA) &&
  /Safari/.test(UA) &&
  /like Gecko/.test(UA) &&
  !/Edge/.test(UA) &&
  !/Chrome/.test(UA) &&
  !/Chromium/.test(UA) &&
  !window.MSStream
// console.debug(`isApple=${this.isApple} isSafari=${isSafari}`)
if (isSafari === true) {
  isApple.value = true
  console.debug('Disabling Apple Safari')
}

const submitForm = async () => {
  if (!privacyChecked.value) {
    formInvalid.value = true;
    return;
  } else {
     startQuestionnaire().then((response) => {
       console.log('startQuestionnaire response', response)
       if(response?.result?.redirect_url) {
         if (isApple.value) {
           window.location.href = response.result.redirect_url
         } else {
           window.open(response.result.redirect_url, '_blank');
         }
       } else {
         toastError('egg_up.modal_error_title', 'egg_up.modal_error_desc')
       }
     })
  }
  model.value = false;
}

</script>
