import mitt from "mitt";

export const ModalBus = mitt();
export const ModalCommands = {
  OPEN: "open",
  CLOSE: "close"
};

export const ResourcesBus = mitt();
export const ResourcesCommands = {
  UPDATED: "updated",
  UPDATED_SINGLE: "updated_single"
};

export const ErrorBus = mitt();
export const ErrorCommands = {
  ERROR: "error"
};



