<template>
  <div class="shadow w-full flex-col justify-start items-start inline-flex rounded-xl m-2">
    <div class="h-44 w-full flex-col justify-start items-start flex rounded-t-xl bg-gray-400" />
    <div class="p-4 h-[12rem] w-full bg-white rounded-b-xl flex flex-col gap-4">
      <div class="flex-col w-full gap-1 flex">
        <Skeleton height="1.5rem" />
        <Skeleton :width="`${randomBetween(80, 100)}%`" />
        <Skeleton :width="`${randomBetween(50, 90)}%`" />
      </div>
      <Skeleton width="40%" />
      <div class="flex gap-4 mt-auto">
        <Skeleton
          class="flex-shrink-0"
          shape="circle"
          height="2rem"
          width="2rem"
        />
        <Skeleton height="2rem" />
      </div>
    </div>
  </div>
</template>
<script setup>
import Skeleton from 'primevue/skeleton';
const randomBetween = (min, max) => Math.random() * (max - min) + min;
</script>
