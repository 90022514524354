<template>
  <div
    v-if="levelMap[level]"
    class="justify-start items-center gap-2 flex"
  >
    <div class="text-gray-900 text-sm font-bold leading-tight">
      {{ $t(`course_results.level_values.${level}`) }}
    </div>
    <div class="justify-start items-start gap-0.5 flex">
      <div
        v-for="i in new Array(levelMap[level])"
        :key="`full-${i}`"
        class="w-2 h-2 bg-blue-900 rounded-full"
      />
      <div
        v-for="i in new Array(3 - levelMap[level])"
        :key="`empty-${i}`"
        class="w-2 h-2 bg-blue-100 rounded-full"
      />
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue';
const levelMap = {'Beginner': 1, 'Intermediate': 2, 'Advanced': 3};

defineProps({
  level: {
    type: String,
    required: true,
  },
});
</script>