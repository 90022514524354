import config from '../config'
import { defineStore } from 'pinia';
import { useUserData } from "@/composables/userData";
import Keycloak from 'keycloak-js';
import { toRaw } from 'vue';

import { ref } from 'vue';


const appLoading = ref(true)




export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        keycloak: null,
        authenticated: false

    }),
    actions: {
        async logout() {
            this.authenticated = false;
            this.keycloak.logout({redirectUri: config.FRONTEND_URL});
        },
        changePassword(){
            if(this.keycloak !== null){
                this.keycloak.login({
                    action: "UPDATE_PASSWORD",
                  });
            }
        },
        async StartRefreshToken() {
            const { logoutUser } = useUserData();
            if(this.keycloak!== null){
            setInterval(async() => {
              const data = toRaw(this.keycloak)
              const resp = await data.updateToken(70)
              const state = toRaw(resp)
             if (state == true){
                console.log('token updated')
             }
             else if (state == false){
                console.log('token still valid')
             }
             else{
                console.log('token expired')
                logoutUser()
             }
          }, 10000)
        }
    },
        getKCToken(){
            if(this.keycloak !== null){
            return this.keycloak.token;
            }
            return null;
        },
        getUserId(){
            if(this.keycloak !== null){
            return this.keycloak.tokenParsed.user_id;
            }
            return null;
        },

        async checkAuthentication() {
            const { loadedFromKc, loginUser } = useUserData()

                console.log('carico keycloak')
                const keycloak = new Keycloak({
                    "realm": config.VUE_APP_KC_REALM,
                    "url": config.VUE_APP_KC_URL+'/',
                    "clientId": config.VUE_APP_KC_CLIENT_ID,
                  });
                const authenticated = await keycloak.init({ onLoad: 'check-sso'});
                this.keycloak = keycloak

                this.authenticated = authenticated
                if(authenticated){
                    this.StartRefreshToken()
                    await loginUser()
                    loadedFromKc()
                }
                else{
                    loadedFromKc()
                }

            appLoading.value = false
          }
    }
});

export {
    appLoading
}
