<template>
  <div class="shadow w-full bg-white sm:h-[172px] flex-row justify-start items-start inline-flex rounded-xl relative">
    <div class="flex-col justify-start items-start flex m-2 sm:m-0 sm:h-[172px] sm:w-[172px] h-[96px] w-[96px] flex-shrink-0 rounded-lg sm:rounded-l-lg sm:rounded-r-none bg-gray-400">
      <Skeleton
        width="5rem"
        class="sm:hidden absolute top-[116px]"
      />
    </div>
    <div class="p-4 w-full h-full rounded-b-xl flex flex-col gap-4">
      <div class="flex-col w-full gap-1 flex">
        <div class="flex gap-2">
          <Skeleton
            width="5rem"
            height="20px"
            border-radius="16px"
          />
          <Skeleton
            width="5rem"
            height="20px"
            border-radius="16px"
          />
        </div>
        <Skeleton
          height="1.5rem"
          :width="`${randomBetween(40, 80)}%`"
        />
        <Skeleton :width="`${randomBetween(80, 100)}%`" />
        <Skeleton :width="`${randomBetween(80, 100)}%`" />
      </div>
      <div class="flex gap-4 items-center">
        <Skeleton
          width="5rem"
          class="hidden sm:flex"
        />
        <div class="flex ml-auto gap-4">
          <Skeleton
            class="flex-shrink-0"
            shape="circle"
            height="2rem"
            width="2rem"
          />
          <Skeleton
            class="w-[2rem] md:w-[7rem]"
            height="2rem"
            width="w-[7rem]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Skeleton from 'primevue/skeleton';
const randomBetween = (min, max) => Math.random() * (max - min) + min;
</script>