{
  "on_boarding": {
    "header_title": "Hello {name}, welcome to Viblio!",
    "header_subtitle": "I am your digital guide to keep up with your work, the skills that are currently in demand, and those that will be essential in the future.<br>You tell me what job you do, and I show you what there is to learn.<br>Thanks to my artificial intelligence, I search and select the content from the world's best digital platforms for you: not only courses but also podcasts, videos, articles.<br>The best time to start is now!",
    "role_edit": "Edit roles",
    "showing_n_job_roles": "{tot} results",
    "show_search_help": "If it represents you, click 'Proceed to the next step'. If we're not quite there yet, you can add another {item} by searching in my database.",
    "next_step": "Proceed to the next step | I don't want to add any more skills | Confirm",
    "next_button": "Next",
    "back_button": "Back",
    "finish_button": "Start, enter Viblio",
    "finish_button_short": "Start",
    "terms_and_conditions": "Terms and conditions",
    "role": {
      "label": "Role",
      "hello": "Hi {name}, I'm Viblio, your digital mentor!<br/>In the next few minutes I will ask you some questions to suggest the most suitable training contents for you!",
      "second": "<strong>Let's start: What is your role?</strong><div class='text-sm'>Search my database by entering the words that describe your role. You can select up to 2 roles</div>",
      "third": "Is this the only role you cover? If you are satisfied, select “Next”, otherwise add another role.",
      "forth": "Your roles have been saved. Select “Next” to proceed",
      "first_role_selected": "Selected role",
      "second_role_selected": "Second selected role",
      "search_placeholder": "Describe your role...",
      "error": "Select at least one role",
      "no_results": {
        "header": "No results found",
        "content": "Unfortunately, it was not possible to find your role, try with a similar role. Our international role database is constantly expanding, I'm sure there will be soon!"
      }
    },
    "skill": {
      "label": "Skill",
      "first": "<div>I already feel like I know you a little better! I know you are <strong>{roles}</strong>.<br/>What other skills do you want to learn?</div>",
      "second": "<strong>Select other interests and skills you want to learn!</strong><div class='text-sm'>Select up to 5 skills or interests you want to learn or train, Viblio will create a dedicated content collection for you.<br/>(The selection of interests is optional)</div>",
      "third": "I have saved all your skills. Select 'Next' to go to the summary",
      "search_placeholder": "Search for a skill...",
      "skill_selected": "Skill",
      "no_results": "No results found"
    },
    "summary": {
      "label": "Summary",
      "first": "<div><strong>I'm building learning content collections</strong> starting from the information you gave me:</div>",
      "second": "Check your data and select 'Start your training'.<br/>To change your selection, click on the edit icon.",
      "roles": "<strong>Your roles</strong>{roles}",
      "skills": "<strong>Your skills</strong/>{skills}"
    }
  },
  "preauthentication": {
    "login": {
      "title": "Authentication in progress...",
      "body": "We are completing your authentication. You will be redirected to Viblio shortly. Thank you for your patience."
    },
    "logout": {
      "title": "Please wait...",
      "body": "You are logging out of Viblio, please wait. Thank you for your patience."
    }
  },
  "modal_mobile": {
    "title": "Log in from PC to continue your training",
    "text": "Sorry, Viblio is not yet fully optimized for mobile devices: we are working to guarantee you a better user experience.",
    "button": "Back to previous page"
  },
  "sidebar": {
    "for_you": "For you",
    "collections": "Collections",
    "collections_personal": "Personal collections",
    "collections_company": "Company collections",
    "collections_shared": "Shared collections",
    "explore": "Explore",
    "analysis": "Analysis",
    "manage": "Manage",
    "organization_title": "Business Settings",
    "organizations": "Organizations",
    "groups": "Groups",
    "users": "Users",
    "plans": "My Plan",
    "explore_results": "Results",
    "missing_payment_title": "Your plan expires in {count} days",
    "missing_payment_text": "Add a payment method to continue using Viblio"
  },
  "career_coach": {
    "lead": "Leo, your personal Career Coach has arrived.",
    "sublead": "Discover what he can do for you right away.",
    "cta": "Talk to Leo"
  },
  "for_you": {
    "title": "Your path",
    "modal": "Your progress",
    "description": "Discover tailored content for your role and interests created for you by Viblio",
    "role_based": "Based on your role and potential",
    "skill_based": "Based on your interests",
    "suggestions_title": "Suggestions for you",
    "suggestions_description": "Expand your knowledge with new learning content suggested based on your preferences and those of other professionals like you",
    "radar_title": "Learning radar",
    "radar_description": "Collections curated by our community of experts",
    "global_collections": "Expert collections"
  },
  "your_path": {
    "title": "Your progress",
    "description": "In this section, you can see the completed and liked content. You can also see the content you have opened to resume where you left off and progress in your learning journey.",
    "completed": "Completed",
    "liked": "Liked",
    "opened": "Opened",
    "nb_completed": "Number of completed content",
    "nb_liked": "Number of liked content",
    "nb_opened": "Number of opened and unfinished content",
    "no_results": "No content"
  },
  "card": {
    "view_all": "View all",
    "free": "Free",
    "more_details": "More details",
    "expert_title": "Collection by",
    "paid": "Paid",
    "included": "Included in the plan"
  },
  "interview": {
    "none_title": "Discover your strengths and potential areas for improvement with the video interview!",
    "none_cta": "Get started",
    "done_title": "Personality traits",
    "done_subtitle": "Download the report inside",
    "failed_cta": "Repeat",
    "not_done_title": "Discover your strengths and potential areas for improvement with the video interview!",
    "not_done_subtitle": "Discover your strengths with the video interview!",
    "not_done_body": "Complete the video interview and discover the personality traits that identify your strengths and potential areas for improvement that impact your role.",
    "not_done_cta": "Start the video interview",
    "report_ready_title": "Download the report of your video interview!",
    "report_ready_subtitle": "Discover your strengths and potential areas for improvement improvement.",
    "report_ready_button": "Download the report",
    "created_title": "Finish the video interview and discover the strengths and areas for improvement that impact your role.",
    "created_subtitle": "Complete the video interview and discover your strengths and potential areas for improvement.",
    "created_button": "Continue",
    "in_progress_title": "We are processing your video interview!",
    "in_progress_subtitle": "You will receive an email when your report is ready",
    "banner_failed": "The analysis of your interview generated an error",
    "modal_header_initialize": "Start the video interview and identify and develop your potential",
    "modal_header_continue": "Continue the video interview and identify and develop your potential",
    "modal_save_label_initialize": "Start the video interview",
    "modal_save_label_continue": "Continue the video interview",
    "modal_body_first": "Viblio uses the YobsTech artificial intelligence engine and an evaluation mechanism based on the standards of the American Psychological Association (APA) to analyze your responses and suggest the most suitable learning path for you.",
    "modal_body_second": "At the end of the video interview, you will receive a detailed report with your strengths and areas for improvement, to analyze the results in depth firsthand.",
    "modal_body_third": "The video interview lasts only 6 minutes and you will have to answer 3 questions, and for each one, you will have 1 minute to prepare and 1 minute to respond.",
    "modal_accept_privacy": "I accept the <a target='_blank' href='https://www.viblio.com/en/privacy-policy-en/'>privacy policy</a> of the service",
    "modal_accept_privacy_error": "You must accept the privacy policy to continue",
    "modal_cancel": "Cancel"
  },
  "egg_up": {
    "none_title": "Discover the main skills of the future and customize your path!",
    "none_cta": "Start",
    "created_title": "Finish the test and discover the main skills of the future!",
    "created_button": "Continue",
    "in_progress_title": "We are processing the questionnaire answers!",
    "in_progress_subtitle": "You will receive an email when your report is ready",
    "failed_title": "The analysis of the skills of the future generated an error",
    "failed_cta": "Repeat",
    "done_title": "Skills of the future",
    "done_subtitle": "Download the report inside",
    "modal_header_initialize": "Start the questionnaire that identifies the skills of the future.",
    "modal_header_continue": "Continue the questionnaire that identifies the skills of the future.",
    "modal_save_label_initialize": "Start the questionnaire",
    "modal_save_label_continue": "Continue the questionnaire",
    "modal_body_first": "The questionnaire provides a detailed assessment of the soft skills designated as the main skills of the future. The aim of this tool is to gain greater awareness of one's skills in line with the evolution of the job market.",
    "modal_body_second": "The questionnaire examines the following soft skills:",
    "modal_body_third": "Open-mindedness, Learning propensity, Intuition, Creativity, Efficiency, Readiness, Resourcefulness, Task orientation, Analytical thinking, Accuracy, Happiness, Social intelligence.",
    "modal_accept_privacy": "I accept the <a target='_blank' href='https://www.viblio.com/en/privacy-policy-en/'>privacy policy</a> of the service",
    "modal_accept_privacy_error": "You must accept the privacy policy to continue",
    "modal_cancel": "Cancel",
    "modal_error_title": "Oops, something went wrong",
    "modal_error_desc": "Click 'start' again to try again",
    "report_ready_title": "Download the questionnaire report!",
    "report_ready_subtitle": "Discover the main skills of the future and customize your path!",
    "report_ready_button": "Download the report"
  },
  "subscriptions": {
    "expiration_date": "Expiration Date",
    "is_expirable": "Has it an expiration?"
  },
  "plans": {
    "select": "Select a Plan",
    "plan": "Plan",
    "subscription": "Plan",
    "start_date": "Subscriptions",
    "expiration_date": "Expiration Date",
    "renewal_date": "Renewal",
    "total_license": "Number of Subscriptions",
    "used_license": "Users",
    "trial_period": "Trial period",
    "none": "None",
    "cancel_plan": "Cancelled",
    "expired": "Expired",
    "pending": "Pending",
    "payed": "Payed",
    "plan_id": "Plan Id: {id}",
    "validity": "Validity",
    "validity_val": "From {start} to {end} ({frequency})",
    "msg_error_saving": "An error occurred while saving. Please try again later. {errormsg}",
    "msg_success_created": "Plan created successfully",
    "msg_success_updated": "Plan updated successfully",
    "msg_payed_success": "Plan payment update saved successfully",
    "msg_payed_failed": "Plan payment update failed",
    "no_subscription": "There aren't any subscriptions associated to this plan",
    "select_user": "Select users for associating this plan",
    "hide_expired": "Hide expired entities",
    "show_expired": "Show expired entities too",
    "manage_action": "Manage plan",
    "banner_upgrade_to_business": {
      "title": "Feature available with business plan",
      "message": "Click on “Change plan” to start using it immediately.",
      "cta_button": "Change plan"
    },
    "banner_limit_user_in_trial_period": "In the trial period you can create a maximum of 10 users to test the platform",
    "banner_trial": {
      "title": "Plan {plan} - Trial period",
      "message": "The trial period expires today. On {expireDate} your plan will be deactivated if you do not enter a payment method by clicking “Manage plan”. | The trial period expires in {totalDays} days. On {expireDate} your plan will be deactivated if you do not enter a payment method by clicking “Manage plan”.",
      "description": "To use the functions marked with the padlock icon you must switch to the “Business” plan: Try it for free. "
    },
    "banner_trial_with_payment": {
      "title": "Plan {plan} - Trial period",
      "message": "The trial period expires today but you don't have to do anything. | The trial expires in {totalDays} days but you don't have to do anything.",
      "description": "To use the functions marked with the padlock icon you must switch to the “Business” plan: Try it for free. "
    },
    "banner_expired": {
      "title": "You plan is expired! Click on “Manage plan” and add payment methode to continue using Viblio.",
      "message": "If you have any doubts please contact us at "
    },
    "banner_canceled": {
      "title": "",
      "message": "There are no active subscriptions on your account, click on “Select plan” to activate a subscription."
    },
    "banner_canceled_still_valid": {
      "title": "",
      "message": "You have canceled your plan: you will be able to use Viblio until {expireDate}. \n\rIf you wish to reactivate your plan, click “Manage plan” and select “Renew plan”"
    },
    "banner_pending": {
      "title": "Errors were detected on the payment method entered so it was not possible to renew or activate the plan.",
      "message": "Click on “Manage plan”, verify the payment method and click on “pay the amount due” to reactivate the plan. If you have any doubts, contact us at "
    },
    "banner_payment_error": {
      "title": "Errors occurred in the payment method added. Click “Manage plan” to solve them.",
      "message": "You will be able to use Viblio until the next renewal."
    },
    "info": {
      "title": "Viblio Plans",
      "description": "If you are interested in subscribing to add-ons",
      "contact_us": "Contact us",
      "user_month": "Per user / month",
      "includes": "What includes:",
      "includes_desc": "In addition to the plan “{planName}”, includes:",
      "upgrade": "Upgrade",
      "downgrade": "Downgrade",
      "actual_plan": "Current plan",
      "choose_plan": "Select Plan",
      "current": "Selected",
      "personal": {
        "title": "Personal",
        "users": "1 user",
        "features": {
          "0": "Individual learning path.",
          "1": "Onboarding and individual interview.",
          "2": "Personal collections",
          "3": "Personal Dashboard"
        }
      },
      "business": {
        "title": "Business",
        "users": "2 to 250 users",
        "features": {
          "0": "Corporate collections",
          "1": "Corporate Dashboard",
          "2": "Manage users, groups, roles",
          "3": "Add-on: premium course catalogue, company set up, expert collections"
        }
      },
      "enterprise": {
        "title": "Enterprise",
        "users": "More than 251 users",
        "price": "Custom price",
        "price_desc": "Annual subscription",
        "features": {
          "0": "Custom roles and skills",
          "1": "SAML-based single sign-on (SSO)",
          "2": "Add-on: Gamification and marketplace"
        }
      },
      "values": {
        "range_2_250": "2 to 250",
        "more_250": "More than 250",
        "unlimited": "Unlimited",
        "no": "No",
        "one": "1",
        "add_on": "Add on *"
      },
      "add_on_notes": "(*) All add-ons require a subscription to an annual plan",
      "features": {
        "users": "Users",
        "teams": "Groups",
        "organizations": "Organizations",
        "custom_path": "Custom learning path",
        "custom_path_desc": "Creation of a personalized training path starting from the selection of role, skills and interests",
        "interview": "Video interview about personality",
        "interview_desc": "Video interview which, through artificial intelligence, defines personality traits and personal attitudes",
        "system_coll_occ": "Suggested content for hard and soft skills",
        "system_coll_occ_desc": "Suggested content referring to the role's hard and soft skills",
        "system_coll_skill": "Suggested content for skills",
        "system_coll_skill_desc": "Suggested contents related to skills and interests (max 5)",
        "system_coll_hot_tech": "Suggested content for tech skills",
        "system_coll_hot_tech_desc": "Suggested content related to technology skills and trends",
        "system_coll_soft": "Contents suggested for personal aptitudes",
        "system_coll_soft_desc": "Suggested contents referring to personality traits and personal attitudes",
        "search": "Course search engine",
        "search_desc": "Course search engine with more than 55,000 contents including videos, courses, podcasts, talks and webinars",
        "free_providers": "Free content catalog",
        "free_providers_desc": "Free course catalog from all our providers (more than 25,500) such as POLIMI, Udemy, Udacity, EdX, Future Learn, Coursera, TED, Harvard Business School, Spotify, Youtube playlist and more.",
        "udemy_providers": "Udemy premium content catalog",
        "udemy_providers_desc": "Udemy paid course catalog with 10,000 certifications",
        "udemy_providers_note": "Min 21 users",
        "future_providers": "Future Learn premium content catalog",
        "future_providers_desc": "Future Learn paid course catalog with 5,000 certifications",
        "future_providers_note": "Min 50 users",
        "coursera_providers": "Coursera premium content catalog",
        "coursera_providers_desc": "Coursera paid course catalog with 9,000 certifications",
        "coursera_providers_note": "Min 150 users",
        "collections": "Personal collections",
        "collections_desc": "Create personal collections",
        "org_collections": "Corporate Collections",
        "org_collections_desc": "Create corporate collections by group",
        "global_collections": "Expert Collections",
        "global_collections_desc": "Expert collections and tailor-made training courses to achieve business objectives",
        "yearly_subscription_note": "For annual subscription only",
        "share_collections": "Sharing collections",
        "dashboard": "My analytics dashboard",
        "dashboard_desc": "Personal analytical dashboard for analyzing data on training and the progress of your growth",
        "org_dashboard": "Enterprise Analytics Dashboard",
        "org_dashboard_desc": "Corporate analytics dashboard for analyzing training and growth data, filterable by group",
        "management": "Management of collaborators and groups",
        "management_desc": "Creation and management of collaborators and groups",
        "roles_management": "Role management",
        "roles_management_desc": "Assignment of roles to all collaborators (manager, supervisor, etc.)",
        "std_setup": "Company standard setup",
        "std_setup_desc": "Collaborative Artificial Intelligence for company standard setup",
        "onboarding": "Onboarding and Support",
        "onboarding_desc": "Onboarding and Support which includes a dedicated Learning Architect who will support you with Set Up Profiles, Launch and Follow Up Webinars, collection, tailor-made, dedicated help desk",
        "custom_setup": "Custom company setup",
        "custom_setup_desc": "Custom corporate setup",
        "custom_roles": "Custom roles and skills",
        "custom_roles_desc": "Custom roles and skills integration",
        "org_providers": "Enterprise Content Catalog",
        "org_providers_desc": "Integration with proprietary company course catalog",
        "sso": "Single sign-on (SSO) ",
        "sso_desc": "SAML-based single sign-on (SSO)",
        "api": "Access to API",
        "data": "Data Management",
        "data_desc": "Import & Export of data from the platform",
        "performance": "Employee Tracking & Performance MGMT",
        "performance_desc": "Employee Tracking & Performance MGMT",
        "game": "Gamification and marketplace",
        "game_desc": "Platform marketplace and gamification-based engagement techniques"
      }
    },
    "miss_payment_modal": {
      "title": "There is no payment method",
      "subtitle": "to activate Viblio you must first add a payment method.<br> Click on “Enter payment method” and then select the plan you want to activate.",
      "confirm": "Enter payment method"
    },
    "change": {
      "title": "Change plan",
      "description": "Select plan type and billing period.",
      "type_of_plan": "Type of plan",
      "billing_frequency": "Billing",
      "summary_title": "Summary",
      "summary_actual_plan": "Current plan “{plan}” ",
      "summary_selected_plan": "Selected plan “{plan}”",
      "summary_current_case": "€ {amount} / month ( {monthly_amount} / month  € x {users} user)",
      "summary_actual_plan_amount": "Current plan: {amount} €",
      "summary_selected_plan_amount": "Selected plan: {amount} €",
      "summary_total": "Total to pay: € {amount}/month (VAT included)",
      "summary_downgrade": "Until the date indicated you will continue to benefit from the features of the “Business” plan",
      "summary_next_renewal": "Next charge: {renewal_date}",
      "footer_cta": "By selecting “Confirm selected plan”, I declare that I understand that I am subscribing to a subscription plan and I authorize Viblio to charge the current list price to the selected payment method, unless cancelled. I can cancel my subscription at any moment by going to the “Manage plan” page.",
      "footer_downgrade": "We noticed that you have created multiple users with your Business plan. By selecting a personal plan that includes only 1 user, only the user with whom you registered on Viblio will remain active, the others will be disabled and will not be able to log in. To give your team access again, you can subscribe to the “Business” plan at any time.",
      "cta_button": "Confirm selected plan",
      "change_plan_success": "Your plan has been successfully updated",
      "change_plan_error": "An error occurred while changing plans. Please try again later. {errormsg}",
      "summary_no_selected_plan": "No plan selected",
      "active_plan_success": "Your plan has been activated successfully",
      "active_plan_error": "An error occurred while activating the plan. Please try again later. {errormsg}",
      "plan_type_personal_label": "Personal (9,00€ user/month) - 1 user",
      "plan_type_business_label": "Business (12,00€ user/month) - 1 user - From 2 to 250 users",
      "billing_frequency_monthly": "Monthly",
      "billing_frequency_yearly": "Yearly"
    },
    "setpayed_action": "Set as Payed"
  },
  "users": {
    "empty_title": "No people in the organization",
    "empty_description": "No person has been created within the organization yet.",
    "empty_help": "Click on “add person” to start.",
    "empty_cta": "Add person",
    "go_to_dashboard": "Go to learning data",
    "not_available_licences": "You have reached the limit of people in your organization",
    "not_available_licences_desc": "You have reached the maximum limit of available licenses in your plan. Contact us to add new licenses.",
    "confirm_delete": {
      "title": "Do you want to delete the selected person?",
      "subtitle": "Are you sure you want to delete the account of {first_name} {last_name}?",
      "text": "Deleting the account will remove the person from Viblio and will permanently delete all their learning data.",
      "text_2": "This action cannot be reversed.",
      "text_red": "Please note that the requested deletion will take effect at the end of the billing period already paid for. The user will have access to Viblio and until {expiration_date}.",
      "btn": "Permanently delete"
    },
    "confirm_activate": {
      "title": "Do you want to reactivate the selected person?",
      "subtitle": "Are you sure you want to reactivate the profile of {first_name} {last_name}?",
      "text": "By activating the profile, the person will be able to access Viblio again and all their learning data will be available again.",
      "text_red": "Please note that the requested change corresponds to an additional Viblio license that will be automatically charged at the next renewal.",
      "text_gray": "Your plan will not be affected because the user is part of an already paid plan",
      "btn": "Reactivate profile"
    },
    "confirm_deactivate": {
      "title": "Do you want to deactivate the selected person?",
      "subtitle": "Are you sure you want to deactivate the profile of {first_name} {last_name}?",
      "text": "By disabling the profile, the person will no longer be able to access Viblio and all their learning data will no longer be available.",
      "text_red": "Please note that the requested change will be effective at the end of the billing period already paid for. The user will have access to Viblio and until {expiration_date}.",
      "btn": "Disable access"
    },
    "first_name": "Name",
    "last_name": "Surname",
    "full_name": "Name Surname",
    "only_parents": "Show only people present in the parent group",
    "email": "Email",
    "plan": "Plan",
    "language": "Default language",
    "role": "Profile",
    "info": "User information",
    "owner_groups": "Groups of membership",
    "occupation_1": "Primary role",
    "occupation_2": "Secondary role",
    "skills": "Interests",
    "skills help": "Maximum 5 interests selectable",
    "is_active": "Status",
    "is_active_desc": "A user's status can be active or inactive. Each active user corresponds to a monthly or annual license.",
    "is_active_help": "Inactive users will not receive the email to access Viblio and do not contribute to the total price of licenses.",
    "is_active_banner": "Inactive users cannot access the platform and do not contribute to the total price of licenses.",
    "is_active_options": {
      "true": "Active",
      "false": "Inactive"
    },
    "agreement": "Appr. data",
    "agreement_options": {
      "true": "Yes",
      "false": "No"
    },
    "my_user": "My User",
    "is_referent": "Plan Manager",
    "will_disable": "Active until {date}",
    "deactivate_action": "Deactivate",
    "activate_action": "Activate",
    "manage_action": {
      "tooltip": "Convert Plan",
      "title": "Are you sure you want to convert this user to {switchTo} ?",
      "subtitle": "This will result in {switchToMsg} of the cost",
      "subtitle_full_on": "an increase",
      "subtitle_flex": "a decrease",
      "message": "The new amount will be notified to the organization.",
      "message_full_on": "can enjoy",
      "message_flex": "cannot enjoy",
      "message_success": "The conversion was successful",
      "message_success_msg": "The user {name} {switchToMsg} of the Full-on benefits is now",
      "message_failed": "Error"
    },
    "confirm_remove": {
      "message": "The user will be deleted. Their subscriptions will also be deleted.",
      "remove": "Delete",
      "subtitle": "Are you sure you want to delete <strong>{name}</strong>?",
      "title": "Delete"
    },
    "no_select_record": "There are no users to select",
    "no_select_record_add_now": "Add a new user",
    "deleted": "The selected person has been deleted and will no longer be able to access Viblio.",
    "form": {
      "create_title": "Add new person",
      "create_help": "Fill out the following fields to add a person to your organization. By clicking on “Add” you will receive an email to access Viblio.",
      "create": "Add new person",
      "update_title": "Edit person",
      "update_help": "Edit the data and click on “Save changes“ to confirm",
      "update": "Save changes",
      "personal_title": "Personal Data",
      "role": "Access profile",
      "role_desc": "The access profile defines the permissions for managing the organization and visibility on company data. All profiles allow access to training content on Viblio.",
      "role_help": "The supervisor manages all people and groups and consults all learning data within the organization, the manager manages only the groups assigned to him and consults the learning data of the people within his groups. Member cannot manage organization.",
      "no_onboarding_title": "Professional profile not created yet",
      "no_onboarding_desc": "Person has not yet onboarded to Viblio",
      "manager": "manager",
      "signup_status_invited": "INVITED",
      "user_role_options": {
        "MEMBER": "Member",
        "MANAGER": "Manager",
        "SUPERVISOR": "Supervisor",
        "ADMIN": "Admin",
        "CONSUMER": "Consumer"
      },
      "wrong_link": "The link you followed is invalid or has expired. Please check the link and try again. If the error persists, contact an administrator.",
      "not_editable_fields": "You cannot edit this data",
      "manager_sets_role": "Set role for new person",
      "manager_sets_role_help": "If you set role and interests, the person will receive the invitation email but will not be onboarded.",
      "reset_job_titles_skills": "Reset job profile",
      "reset_job_titles_skills_help": "You cannot change this data. You can only restore the person's job profile, who will be able to select a new role and interests at the next login. This operation is irreversible."
    }
  },
  "general_table": {
    "search": "Search",
    "no_record": "No record",
    "no_record_add_now": "Add first record",
    "add_record": "New Record",
    "actions": "Actions",
    "action_not_available": "Not available in backend yet.Coming soon.",
    "confirm_remove": {
      "message": "The record will be delete.",
      "remove": "Delete",
      "subtitle": "Are you sure you want to delete <strong>{name}</strong>?",
      "title": "Delete"
    }
  },
  "collections": {
    "title_": "No",
    "title": "Collections",
    "create": "Create new collection",
    "create_cta": "Create collection",
    "create_desc": "Enter the name of the new collection you want to create and click on “Create collection” to add the content to the new collection",
    "create_success": "The collection {collection} has just been created!",
    "create_add_success": "Content added to the newly created {collection}",
    "collection": "Collection ",
    "system_hard": "Hard Skills",
    "system_soft": "Soft Skills",
    "system_hot_tech": "Tech Skills",
    "system_interview": "Personality traits collection",
    "system_created_for_you": "Created for you",
    "system_created_for_you_desc": "This section contains suggested content based on content you liked and completed",
    "system_suggested_for_you": "Suggested Contents",
    "system_suggested_for_you_desc": "In this section there are suggested contents based on the contents you have consulted on Viblio",
    "system_suggested_for_your_role": "Contents for your role trend",
    "system_suggested_for_your_skills": "Contents for your interests trend",
    "system_organization_top_10": "Organization top 10",
    "system_skill_desc": "Description of Viblio collection of this skill.",
    "wait_first_org_shared_collection_banner": "There are no collections shared by your company",
    "wait_first_org_shared_collection_banner2": "This section will show the collections shared with you by your company",
    "wait_first_shared_collection_banner": "There are no collections shared with you",
    "wait_first_shared_collection_banner2": "This section will show collections shared by people within your company.",
    "first_personal_collection_banner": "Create your first collection now! Save content, organize your insights, and begin building your curated knowledge hub.",
    "add_and_count_empty": "Add to a collection",
    "add_and_count_many": "Present in {count} collections",
    "add_and_count_one": "Present in 1 collection | Present in {count} collections",
    "add_to_a_collection": "Do you want to add this content to a collection?",
    "add_to_a_new_collection": "Enter the name of the new collection you want to create and click on “Create and add” to add the content to the new collection",
    "add_to_collection": "Add to Collection",
    "in_collection": "Added to collections",
    "added_to": "Added to: ",
    "collection_under_construction": "Some of the results are being curated for your professional profile.",
    "collection_created": "The new collection <strong>{name}</strong> has been successfully created.",
    "collection_updated": "The collection <strong>{name}</strong> has been successfully updated.",
    "collection_deleted": "The collection has been successfully deleted.",
    "confirm_remove_course_title": "Do you want to remove content from the collection?",
    "confirm_remove_course_subtitle": "Are you sure you want to remove the selected content from the collection?",
    "confirm_remove_course_message": "To confirm click on “Remove contents”.",
    "confirm_remove_course_button": "Remove contents",
    "confirm_delete_title": "Do you want to delete the collection?",
    "confirm_delete_subtitle": "Are you sure you want to delete the collection {collection}? Deleting the collection will delete all favorite content within it.",
    "confirm_delete_message": "To confirm click on “Delete collection”.",
    "confirm_delete_button": "Delete collection",
    "confirm_unshare_title": "Do you want to stop sharing the collection?",
    "confirm_unshare_subtitle": "If you stop sharing, people will no longer be able to consult the learning content in their portal in shared collections.",
    "confirm_unshare_message": "Select “Stop sharing” to confirm.",
    "confirm_unshare_button": "Stop sharing",
    "shared_msg": "You have shared your collection",
    "unshared_msg": "Your personal collection is no longer shared",
    "course_added": "The content has been added to collection | The content has been added to collections ",
    "courses_count": "contents",
    "create_new": "Create and add",
    "create_new_subtitle": "Give your collection a name and description to keep your content organized",
    "edit_collection": "Edit collection name",
    "edit_collection_help": "Edit the name of your collection and click on the “Save” button to confirm.",
    "new_collection": "New Collection",
    "new_collection_name": "Collection name",
    "new_collection_button_text": "or create a new collection",
    "new_collection_description_placeholder": "Is there any additional detail you want to add?",
    "new_collection_title_placeholder": "What is this collection about?",
    "no_collections_cta": "Create your first collection",
    "no_collections_description": "To create your first collection click on the “New Collection” button",
    "no_collections_title": "You have no personal collection",
    "remove_from_collection": "Remove from Collection",
    "resource_removed": "The selected content has been removed from the collection",
    "share_btn": "Share",
    "share_collection_title": "Share collection",
    "share_collection_org": "Share with all organization",
    "share_collection_global": "Share with the Viblio community",
    "share_collection_with_group": "Share with selected people and groups",
    "share_collection_group_placeholder": "Select groups",
    "shared_with": "Shared with:",
    "shared_with_org": "All company",
    "shared_with_global": "All community",
    "shared": "Already Shared",
    "share_title": "Sharing Collection",
    "share_subtitle": "Select who you want to share the collection with.",
    "edit_share_org_subtitle": "Edit which organizations can access the collection.",
    "edit_share_group_subtitle": "Edit who or what group in {organization} can access the collection.",
    "share_radio_error": "Select at least one recipient to share the collection",
    "share_groups_users": "Groups or members",
    "edit_share_groups_users": "Add groups or members",
    "shared_global": "Shared with the Viblio community",
    "users": "{total} person | {total} persons",
    "SHARE_NONE": "Private",
    "SHARE_ORG": "Shared with your organization",
    "SHARE_GLOBAL": "Shared with the Viblio community",
    "back_to_list": "Go to the collections list",
    "personal_title": "Personal Collections",
    "company_title": "Company Collections",
    "shared_with_groups_title": "Shared Collections",
    "personal_list_subtitle": "Collections help you reach your growth goals faster! Create your own collections to save and organize your favorite content.",
    "company_list_subtitle": "In this section you will find the collections shared by your company",
    "shared_with_me_list_subtitle": "In this section you will find collections shared with you.",
    "shared_with_group_title": "Shared with your group",
    "shared_with_user_title": "Shared with you",
    "classification_hard": "Hard Skills",
    "classification_soft": "Soft Skills",
    "classification_hot_tech": "Hot Technologies Skills",
    "classification_interview": "Personality Traits Collection",
    "title_job_role": "Competenze per il tuo ruolo: {jobName}",
    "title_job_role_desc": "Qui sotto trovi i contenuti che Viblio ha selezionato per te per stare al passo con il tuo ruolo da un punto di vista tecnico, (non puoi farne a meno!).",
    "title_skill": " Since you have chosen: {skillName}",
    "title_skill_desc": "",
    "title_from_interview_desc": "",
    "title_from_interview": "Transferable Skills / My Personality",
    "unshare_collection": "Stop sharing",
    "user_collections": "Personal Collections",
    "private_empty": "There is no content in this collection",
    "private_empty_desc": "There is no content in this collection. You can add content from the list or from the detail of a training content available on Viblio.",
    "shared_with_me_empty": "There is no content in this collection",
    "shared_with_me_empty_desc": "There is no content in this collection but content selected and suggested by your group will soon be shown",
    "shared_with_org_empty_desc": "There is no content in this collection but content selected and suggested by your organization will soon be shown",
    "go_to_collection": "Go to collection"
  },
  "course_detail": {
    "access_needs_account": "Access to this course is reserved for registered users. Click here to request information.",
    "access_needs_upgrade": "To access this course, upgrade to the Full On plan. Click here to request information.",
    "access": "Access Course",
    "access_podcast": "Listen to the podcast",
    "unavailable": "Content Unavailable",
    "unavailable_desc": "Content no longer available from the provider",
    "free": "FREE",
    "payed": "PAYED",
    "resources": {
      "course": "Course",
      "podcast": "Podcast",
      "video": "Video",
      "document": "Article",
      "webinar": "Webinar",
      "youtube_playlist": "Youtube Playlist",
      "codecademy_playlist": "Codecademy Playlist",
      "access_content": "Access the Content",
      "access_course": "Access the Course",
      "access_podcast": "Listen the Podcast",
      "access_video": "Watch the Video",
      "access_document": "Read the Article",
      "access_webinar": "Access the Webinar",
      "access_youtube_playlist": "Access the Youtube Playlist",
      "access_codecademy_playlist": "Access the Codecademy Playlist"
    },
    "content_level": "CONTENT LEVEL",
    "skills_level": "LEVEL UP YOUR SKILLS"
  },
  "course_results": {
    "cost": "Price Range",
    "provider": "Provider",
    "language": "Language",
    "filters": "Search Filters",
    "reset_filters": "Reset Filters",
    "searching": "searching...",
    "active_filters": "Active Filters:",
    "course_tabs": {
      "author_many": "Authors",
      "author_one": "Author",
      "description": "Description",
      "requisites": "Prerequisites",
      "source": "Source"
    },
    "effort": "Effort",
    "effort_periods": {
      "months_many": "months",
      "months_one": "month",
      "weeks_many": "weeks",
      "weeks_one": "week"
    },
    "effort_values": {
      "less:2:hours": "Up to two hours",
      "less_2_hours": "Up to two hours",
      "more:1:months|less:3:months": "1-3 months",
      "more_1_months__less_3_months": "1-3 months",
      "more:1:weeks|less:4:weeks": "1-4 weeks",
      "more_1_weeks__less_4_weeks": "1-4 weeks",
      "more:3:months": "More than 3 months",
      "more_3_months": "More than 3 months"
    },
    "level": "Level",
    "level_values": {
      "Advanced": "Advanced",
      "Beginner": "Beginner",
      "Intermediate": "Intermediate",
      "exclude_others": "For everyone",
      "Anyone": "For everyone"
    },
    "no_results": "It seems that your search for <strong>\"{searchTerm}\"</strong> did not yield any results.",
    "price": "Price Range",
    "price_values": {
      "all": "All",
      "pay": "Payment",
      "free": "Free",
      "included": "Included in the plan"
    },
    "not_only_fullon": "Included",
    "only_fullon": "Full On Only",
    "tot_results_many": "{count} results for",
    "tot_results_one": "One result for",
    "try_changing_text": "Try checking the search terms",
    "try_changing_text_or_filters": "Try modifying the active filters or checking the search terms",
    "type": "Content Type",
    "type_values": {
      "document": "Article",
      "course": "Online course",
      "podcast": "Podcast",
      "webinar": "Webinar",
      "video": "Video",
      "youtube_playlist": "YouTube playlist",
      "codecademy_playlist": "Codecademy playlist"
    },
    "uh_oh": "UH-OH."
  },
  "search": {
    "title": "Hello!",
    "description": "What do you want to learn today?",
    "search_button": "Search",
    "results_found": "results found",
    "filters": "Filters",
    "all_types": "All content types",
    "all_prices": "All price ranges",
    "no_results": {
      "title": "Oh no! No results found for “{query}”",
      "description": "We couldn't find any learning content that matches the term entered in our catalog. What can you do?",
      "step_1": "Change the entered term",
      "step_2": "Check the applied filters",
      "step_3": "Try modifying your search",
      "last_paragraph": "Our catalog is constantly expanding, so you will find more learning content in the future."
    }
  },
  "entities": {
    "hide_soft_deleted": "Hide deleted entities",
    "show_soft_deleted": "Show deleted entities"
  },
  "Error": "Error",
  "errors": {
    "generic_msg": "Save error, please try again",
    "api_down": "We are experiencing issues with our server.",
    "failed_login": "Login failed. Please check your email and password.",
    "failed_logout": "Logout failed.",
    "failed_registration": "Registration failed. Please check your information and try again.",
    "general_title": "An error occurred while saving. Please try again.",
    "general_msg": "If the error persists, please try again later. ",
    "general": "An error occurred. Please try again later.",
    "logout": "Logout Error",
    "offline": "It seems that the connection is not available.",
    "retry": "Retry",
    "session_expired": "Your session has expired. We are redirecting you to the login page."
  },
  "footer": {
    "about_us": "About Us",
    "contact_us": "Contact Us",
    "terms": "Terms of Privacy and Cookie Policy",
    "about_us_link": "https://www.viblio.com/about",
    "contact_us_link": "https://www.viblio.com/contacts",
    "terms_link": "https://www.viblio.com/privacy"
  },
  "general": {
    "add": "Add",
    "show": "Show",
    "new": "New",
    "mark_as_completed": "Mark as completed",
    "completed": "Completed",
    "completeds": "Completed",
    "content": "Content",
    "contents": "Contents",
    "optional": " (optional)",
    "desktop_only": "Not available on mobile",
    "required_fields_tooltip": "Fill in all required fields to proceed",
    "select": "Select",
    "select_all": "Select All",
    "select_added": " (Already added)",
    "change": "Change",
    "settings": "Settings",
    "address": "Address",
    "back": "Back",
    "skip_to_conent": "Go to content",
    "similar_courses": "Other similar courses",
    "similar_users": "Other people with your same role have viewed",
    "skills": "Skills",
    "level": "Level",
    "course_description": "Course Description",
    "back_to": "Back to ",
    "cancel": "Cancel",
    "city": "City",
    "click_here": "Click here",
    "confirm": "Confirm",
    "cost": "Cost",
    "authors": "Authors",
    "languages": "Languages",
    "more_details": "More details",
    "less_details": "Less details",
    "language": "Language",
    "country": "Country",
    "create": "Create",
    "create_and_insert": "Create and insert",
    "description": "Description",
    "password": "Password",
    "phone": "Phone",
    "delete": "Delete",
    "remove": "Remove",
    "role": "Role",
    "save": "Save",
    "title": "Title",
    "update": "Update",
    "edit":"Edit",
    "deleted_content": "The content has been removed from the provider",
    "detail": "Detail",
    "website": "Website",
    "province": "Province",
    "zipcode": "Zipcode",
    "form": {
      "field_errors": {
        "text": "{name} is a required field",
        "email": "Please enter a valid email to proceed",
        "email_exists_title": "Cannot create a new profile",
        "email_exists_desc": "A profile was found on Viblio with the email entered",
        "password": "Password must have at least 8 characters, one uppercase letter, a number and a special character"
      }
    }
  },
  "home_box": {
    "error_cta": "Retry the interview",
    "error_msg": "You can retake the interview to get a new analysis.",
    "error_pre_title": "An error occurred",
    "error_title": "Unfortunately, the interview analysis failed",
    "has_interview_cta": "View Results",
    "has_interview_msg": "I have curated this collection of content based on the results of your video interview. Here, you can learn specific skills to highlight your strengths and strengthen your areas for improvement.",
    "has_interview_processing_msg": "We are processing your report. Check out the collections and choose what interests you. As soon as it's ready, you'll find it in your profile.",
    "has_interview_title": "Let's work together",
    "logged_cta": "start the interview",
    "logged_msg": "Discover tailored suggestions for faster and more personalized learning.",
    "logged_pre_title": "Ready to level up your content game?",
    "logged_title": "Take the Video Interview",
    "interview_banner": "The video interview is available for the browser",
    "started_cta": "Take the Video Interview",
    "started_msg": "We noticed that you started the video interview but didn't complete it. Answer the questions; it will take a maximum of 10 minutes. My artificial intelligence will help suggest the most suitable content for you.",
    "unlogged_cta": "Sign up to access",
    "unlogged_msg": "Want personalized recommendations? Let's get to know each other better. Answer the questions in my video interview, and my artificial intelligence will suggest the most suitable content for you.",
    "unlogged_title": "Customized Learning Paths"
  },
  "home": {
    "created_by": "created by"
  },
  "login": {
    "forgot_password": "Forgot Password?",
    "no_account": "Don't have a Viblio account?",
    "placeholders": {
      "email": "enter the email you registered with",
      "password": "enter your password"
    },
    "welcome": "Log in to discover a universe of content and continue your growth journey."
  },
  "organizations": {
    "add_user": "Add people",
    "add_user_no_limit_for_trial": "You cannot add more than 10 users during the trial period.",
    "users_counter": "Users:",
    "filter_name": "Organization Name",
    "small_title": "Edit workspace name",
    "small_subtitle": "Edit the name of your workspace and click on 'Save' to confirm.",
    "small_name": "Organization",
    "name": "Name and surname or company name",
    "email": "Email",
    "status": "Status",
    "status_disabled": "Disabled",
    "confirm_remove": {
      "message": "Organization data will not be deleted and will remain in the database. All associated users will be disabled.",
      "remove": "Disable",
      "subtitle": "Are you sure you want to disable <strong>{name}</strong>?",
      "title": "Disable Organization"
    },
    "created": "The organization <strong>{organization}</strong> has been created.",
    "create_organization": "Create Organization",
    "edit_billing_organization": "Add billing information",
    "cta_create": "Create the first",
    "deleted": "The organization has been deleted",
    "edit_organization": "Edit Organization {name}",
    "empty": "There are no organizations",
    "error_saving_user": "An error occurred while saving. Please try again later. {errormsg}",
    "error_deleting_user": "Si è verificato un errore nell' eliminazione dell'utente. {errormsg}",
    "form": {
      "company_info": {
        "billing_title": "Enter billing address",
        "billing_subtitle": "Fill in the following data and click on “Save”.",
        "address": "Headquarters",
        "source": "Source",
        "plan": "Plan",
        "pay_in_app": "Payment via stripe",
        "trial": "Trial period",
        "trial_days": "Duration",
        "address_detail": "Stair, interior, floor",
        "help": "Personal data",
        "legal_address": "Legal Address",
        "title": "Company Information",
        "country_help": "Two-letter country code (Eg. IT, DE, FR ...)",
        "cf_code": "Tax Id",
        "vat_code": "VAT Code",
        "vat_code_help": "Add the country prefix before the VAT number"
      },
      "general": {
        "email": "Organization Email",
        "title": "Personal Information"
      },
      "referent": "Referent",
      "personal": "Personal",
      "payment_attached_date": "Payment method attached",
      "pay_in_app": "Pay in app",
      "stripe_customer_id": "Stripe customer ID"
    },
    "covert_to_pay_in_app": "Convert to pay in App",
    "covert_to_pay_in_app_subtitle": "Are you sure you want to convert {name}?",
    "covert_to_pay_in_app_message": "This operation cannot be revoked.",
    "covert_to_pay_in_app_success": "The operation converted successfully.",
    "join_us_payload": "Hello! You have been invited by <strong>{orgName}</strong> to join Viblio!<br><br>Create your account: the future of learning is just a click away.",
    "manage_users": "Users",
    "manage_users_description": "Create users in your organization and assign them the role of member, manager and supervisor. \nEach user created will be sent an email to immediately start using Viblio.",
    "no_users": "There are no users in this organization.",
    "no_users_add_now": "Add the first one",
    "organizations": "Organizations",
    "updated": "The organization <strong>{organization}</strong> has been updated.",
    "user_created": "You have successfully added a new person to your organization.",
    "users": "Your users",
    "plans": "Your plan | Your plans",
    "add_plan": "Add plan",
    "user_updated": "User updated successfully",
    "user_activated": "The selected person now has an active account on Viblio",
    "user_deactivated": "The selected person has been deactivated",
    "your_organization": "Your Organization",
    "update_collection": "Refreshing collections!",
    "unavailable_plans": "There are not available licenses. Please contact your admin",
    "teams": "Groups"
  },
  "manage": {
    "select_organization": "Select organization",
    "organization_item_users": "{count} People",
    "organization_item_groups": "{count} Groups"
  },
  "groups": {
    "create": "Create new group",
    "create_help": "Fill in the following fields to create a new group of people with whom you want to share learning content.",
    "update": "Edit group",
    "update_help": "Edit information about group {name}",
    "confirm_delete": {
      "title": "Do you want to delete the group?",
      "subtitle": "Are you sure you want to delete the group {name}? ",
      "text": "Remember that if you delete a group, all shared collections will no longer be available to people belonging to the selected group.",
      "text_2": "To confirm click on “Delete group”.",
      "btn": "Delete group"
    },
    "confirm_remove_user": {
      "title": "Do you want to remove this person from the group?",
      "subtitle": "Are you sure you want to remove {full_name} from the group?",
      "text": "To confirm click on “Remove from group”.",
      "btn": "Delete from group"
    },
    "select_user_title": "Add people to group",
    "select_users": "Add people group",
    "select_user_help": "Select the people to add to the {name} group and click add to group to confirm.",
    "empty_title": "Empty group",
    "empty_description": "There are no people in the group. To start sharing collections with groups, add people from your organization",
    "empty_cta": "Add people",
    "team": "Group | Groups",
    "groups_description": "Create and manage groups of people to faster share collections within your organization and stimulate shared learning.",
    "no_record_title": "No group",
    "no_record_description": "No learning content sharing group has been created yet.",
    "no_record_help": "Click on “New Group” to start.",
    "no_record_cta": "New group",
    "name": "Group name",
    "description": "Description",
    "assigned_to": "Responsible",
    "assigned_to_team": "Head: {full_name} - {email}",
    "assigned_to_help": "The manager is the person who will be able to see people's learning data for the entire group in the Company Dashboard",
    "is_subgroup": "Is it a subgroup?",
    "parent_group": "Select the parent group",
    "user_counter": "Users",
    "share_coll_counter": "Shared collections",
    "share_coll_counter_n": "{count} Shared collections with the groups",
    "msg_error_saving": "An error occurred while saving. Please try again later. {errormsg}",
    "msg_success_created": "The Group {name} created, go to the detail and add people to the group.",
    "msg_success_updated": "The Group {name} updated",
    "msg_success_deleted": "The Group {name} has been deleted.",
    "msg_success_users_added": "Selected people have been added to your group.",
    "msg_success_users_removed": "Selected people have been removed to your group.",
    "assigned_to_me": "Me",
    "no_record": "There is no {group}.",
    "no_record_add_now": "Click Create to add the first one",
    "contents": "{count} contents"
  },
  "register": {
    "already_account": "Already an account?",
    "access": "Sign-in",
    "access_1": "Sign-in and start learning",
    "continue": "Continue",
    "complete": "Complete Sign up",
    "pick_password": "Choose a password",
    "create_password": "Create a password",
    "wait": "Wait a moment",
    "back": "Back",
    "process": "we are processing your data...",
    "select": "Select",
    "title": "Sign up and discover your learning path",
    "terms": "Terms and conditions",
    "sub_line_1": "No credit card requested",
    "sub_line_2": "14 days of free trial",
    "sub_line_3": "Possibility to delete the subscription",
    "separator": "OR",
    "choose_password": "Choose a Password",
    "confirm_password": "Confirm Password",
    "google_register": "Sign-up with Google",
    "linkedin_register": "Sign-up with Linkedin",
    "weak": "Weak Password",
    "medium": "Good Password",
    "complex": "Awesome Password",
    "have_account": "Already have an account? {login}",
    "same_email": "Email already registered.",
    "title_2": "Tell us something more about you",
    "mandatory": "Mandatory fields must be filled",
    "first_name": "First Name",
    "last_name": "Last Name",
    "identify": "How do you identify yourself professionally?",
    "objective": "What professional goal do you want to achieve?",
    "company": "Company",
    "company_size": "Company size",
    "student": "I am a student",
    "worker": "I am a worker",
    "conselour": "I am a consultant/freelancer",
    "team_manager": "I am a manager or a team manager",
    "hr": "I am a Human Resource",
    "other": "Other",
    "updated": "I want to stay updated",
    "new_skills": "I want to learn new skills",
    "change_path": "I want to change my career path",
    "continous_formation": "I want a tool that allows my team or company to do continuous training",
    "1": "Only me",
    "2-9": "2-9 employees",
    "10-49": "10-49 (small business)",
    "50-249": "50 - 249 (medium business)",
    "+250": "over 250 employees",
    "privacy_policy": "I accept ",
    "tec": "terms and conditions",
    "and": "and the",
    "newsletter": "I want to stay updated on what's new in Viblio (only when we add something interesting)",
    "end_registration": "You have completed registration!",
    "end_registration_sub": "Welcome on Viblio!",
    "end_registration_text": "Hi, I'm Viblio, your digital mentor. Thanks to my artificial intelligence, I select content from the best e-learning platforms to evolve your role and skills to adapt to current and future European standards.",
    "check_email": "Check your email inbox",
    "check_email_text_1": "We have sent you an email at",
    "check_email_text_2": ". Check your inbox and click on the verification link to confirm your email address.",
    "check_email_sub": "(If you don't find the verification email in your inbox, check your spam or junk mail folder)",
    "email_error": "Email field can't be empty",
    "password_error": "Password field can't be empty",
    "join_us": "Join Us",
    "join_us_payload": "Sign up on Viblio and start learning the knowledge of the future with the tools and guidance of our experts",
    "password_format": "At least 6 alphanumeric characters",
    "placeholders": {
      "email": "enter your email",
      "name": "enter your name",
      "surname": "enter your surname",
      "work_email": "enter your work email"
    },
    "registering_user": "Validating data and registering user...",
    "privacy1": "I accept the: ",
    "privacy2": "privacy policy",
    "privacy3": " of the service"
  },
  "reset_password": {
    "enter_email": "Enter your email",
    "info": "Enter the email address you used to register. You will receive a message with instructions to change your password and access Viblio.",
    "warning": "If your address is not in our records, you will not receive any notifications."
  },
  "user": {
    "login": "LOGIN",
    "profile": "Profile",
    "progress": "Activities and Collections",
    "subscription": "Subscription",
    "register": "Sign up",
    "logout": "Logout"
  },
  "user_profile": {
    "save_changes": "Save changes",
    "wait_save_changes": "No changes were detected",
    "profile_section_title": "Login data, privacy, and notifications",
    "my_data": "My data",
    "no_skills": "No interests selected",
    "change_name_surname_lang": "Update",
    "account_settings": "Accounts and notifications",
    "change_password_title": "Login password",
    "change_password": "Change Password",
    "change_password_description": "Click on “Change password” to change your login password. Once changed you will have to log in again",
    "change_password_error": "Change of password failed. Please log in again and try again.",
    "change_password_success": "The password was changed successfully",
    "delete_account": "Delete account",
    "delete_account_description": "Send an email to ",
    "delete_account_instructions": " to delete your account and all your data.",
    "delete_account_instructions_2": "By deleting your account you will lose all suggestions and the history of your consultations.",
    "description": "Here you will find the information you shared during the initial profiling. You can change your name and surname, but not the registration email.",
    "interview": {
      "title": "Personality Traits - Video Interview",
      "to_be_initialize": "Want even more personalized learning suggestions? Take part in the video interview that, thanks to artificial intelligence, defines the personality traits that impact your role. At the end, you can download the detailed report and discover the content best suited to your professional growth.",
      "to_be_initialize_cta": "Start the video interview",
      "started": "You have not completed the video interview.",
      "started_2": "Click on 'continue the video interview' to pick up where you left off. At the end, you can download the detailed report and discover the content best suited to your professional growth.",
      "started_cta": "Continue the video interview",
      "processing": "You have completed the video interview and your report will be available soon.",
      "processing_2": "As soon as it is available, you will receive an email and you can discover the content best suited to you on Viblio.",
      "completed": "You have already completed the video interview to discover the personality traits that impact your role.",
      "completed_2": "You can download the report or view the contents suggested for you.",
      "go_collection": "Explore the contents",
      "download_cta": "Download the report",
      "failed": "The analysis of your interview generated an error.",
      "failed_2": "It was not possible to process the interview data. We ask you to repeat the interview that elaborates the personality traits.",
      "failed_cta": "Repeat the interview"
    },
    "interview_finish_title": "CONGRATULATIONS! YOU HAVE COMPLETED YOUR VIDEO INTERVIEW!",
    "interview_finish_desc": "We are creating your report. We will send you an email as soon as it's ready! Typically, it takes 1 or 2 days.",
    "interview_finish_desc2": "In the meantime, you can continue to explore the myriad of content selected by our experts.",
    "interview_finish_back": "Back to homepage",
    "egg_up": {
      "title": "Questionnaire on the skills of the future",
      "to_be_initialize": "The questionnaire offers an in-depth assessment of the soft skills considered essential for the future. At the end, you can download a complete report and access a collection of content to develop these key skills.",
      "to_be_initialize_cta": "Start the questionnaire",
      "started": "You have not completed the questionnaire.",
      "started_2": "Click on 'continue questionnaire' to pick up where you left off. At the end, you can download a detailed report and access the most suitable content to develop the skills of the future.",
      "started_cta": "Continue questionnaire",
      "processing": "You have completed the questionnaire on the skills of the future and your report will be available shortly.",
      "processing_2": "As soon as it is available, you will receive an email and you can discover the most suitable content for you on Viblio.",
      "completed": "You have already completed the questionnaire and your soft skills assessment considered fundamental for the future is ready.",
      "completed_2": "Download the report or view the collection of contents suggested for you.",
      "go_collection": "Explore contents",
      "download_cta": "Download the report",
      "failed": "The analysis of your questionnaire generated an error and it was not possible to process your answers.",
      "failed_2": "We ask you to repeat the questionnaire that analyzes the soft skills for your professional future.",
      "failed_cta": "Repeat the questionnaire"
    },
    "intro_section_title": "My professional profile",
    "profile": "Professional profile",
    "title_form": "Personal information",
    "job_title": "Job Title",
    "skills": "Interests",
    "update_profile_success": "The profile has been successfully updated.",
    "logout_cta": "Log out of Viblio",
    "logout_description": "Click on the “Exit Viblio” button to log out.",
    "logout_description_2": "The next time you log in you will need to enter your credentials again.",
    "notification_title": "Email preferences",
    "email_notification": "Receive emails with tips about your training",
    "notification_description": "These emails allow you to receive additional training content, insights and advice based on your learning habits",
    "update_notification_success": "Your preferences have been updated",
    "notification": "Receive functional emails",
    "agreement_title": "Data Sharing Preferences",
    "agreement": "Share data about education and interests",
    "agreement_description": "If you choose not to share information about your learning journey, it will be more difficult for your company to provide you with the training you need and give you support.",
    "policies_title": "Terms and conditions, privacy and cookie policy",
    "policies_term_link": "Terms and conditions",
    "policies_privacy_link": "Privacy policy",
    "policies_cookie_link": "Cookie policy"
  },
  "agreement": {
    "title": "Welcome to Viblio!",
    "text": "Viblio is a corporate tool that allows you to train and develop new skills adopted by {nome}. Before starting your training path, {nome} asks you to indicate your preference regarding data sharing on learning contents, which allow your company to fully exploit the potential of Viblio by guaranteeing personalized and timely training and support.",
    "button-ag": "I agree to sharing",
    "button-not": "Do not share",
    "undertext": "If you choose not to share information about your learning path, it will be more difficult for your company to offer you the training you need and give you support. You can change your preferences in your profile at any time."
  },
  "support": {
    "nav_bar_text": "Support",
    "categories": {
      "ho_bisogno_di_supporto": "I need support",
      "voglio_segnalare_un_errore_bug": "I want to report an error (Bug)",
      "richiesta_di_nuove_funzionalita": "Request for new features",
      "informazioni_sui_piani_pagamenti_fatturazione": "Information about plans, payments, billing",
      "annullamento_piano": "Plan cancellation",
      "contatta_un_commerciale": "Contact a salesperson",
      "feedback_e_suggerimenti": "Feedback and suggestions",
      "domande": "Questions about Viblio",
      "errori_tecnici": "Technical errors",
      "attivita_amministrative": "Administrative activities"
    },
    "customer_request": {
      "question": "Questions about Viblio",
      "task": "I need support",
      "incident": "I want to report an error (Bug)",
      "payment": "Information about plans, payments, billing",
      "feedback": "Feedback and suggestions",
      "request_new_feature": "Request for new features",
      "cancel_plan": "Cancel plan",
      "contact_sales": "Contact a sales representative"
    },
    "success": "Your request has been sent.",
    "title": "Write to support",
    "subtitle": "Fill in the following fields and send your report/question, our support team will respond to you as soon as possible.",
    "contact_label": "How can we help you?",
    "object": "Describe your request",
    "cta": "Send"
  },
  "expired": {
    "title": "Continue learning with Viblio",
    "subtitle": "There are no active subscriptions on your account. Go back to the plan table, choose the plan that best suits your needs and click on “Manage plan” and add a valid payment method.",
    "cta": "Choose plan and activate Viblio",
    "help_cta": "Do you need support? contact us at ",
    "footer_title": "Do you no longer want to continue with Viblio for now?",
    "footer_subtitle": "Tell us briefly why. Your feedback is essential for us to improve our product",
    "footer_cta": "Help us improve"
  },
  "dashboard": {
    "nav_bar_text": "Data highlights",
    "description": "In this section you can view your organization's analytical data relating to users and groups",
    "tab_my": "My data",
    "tab_org": "Company data",
    "back_all_members": "Back to all users",
    "filters_title": "Filters",
    "members": "Users",
    "contents": "Searches",
    "view_more": "More details",
    "open_table": "view as table",
    "title": "Personal Dashboards for Individuals",
    "go_to_user_dashboard": "Go to user dashboard",
    "go_to_content": "View content",
    "modal_pagination": "Showing {limit} of {total}",
    "no_data": "No data available",
    "no_users_title": "No members found with the current filters",
    "no_users_desc": "Try adjusting your filters for a better match.",
    "popular_skill_per_users_chart_title": "Popular interests",
    "popular_skill_per_users_chart_tooltip": "Main interests in your organization not directly related to professional roles.",
    "company_skill_chart_title": "Popular company collections",
    "company_skill_chart_tooltip": "",
    "top_users_chart_title": "Top users",
    "top_users_chart_tooltip": "Ranking of users who viewed more content",
    "popular_skill_per_contents_chart_title": "Common skills",
    "popular_skill_per_contents_chart_tooltip": "Ranking of the professional skills of your organization's roles by level",
    "days_of_week_distribution_chart_title": "Visits by day of the week",
    "days_of_week_distribution_chart_tooltip": "",
    "top_contents_chart_title": "Most popular learning contents",
    "top_contents_chart_tooltip": "",
    "soft_skill": "Soft skills",
    "soft_skill_tooltip": "",
    "hard_skill": "Hard skills",
    "hard_skill_tooltip": "",
    "tech_knowledge": "Technological skills",
    "tech_knowledge_tooltip": "In this section you can find trending skills that could impact in your career growth  in the short term.",
    "no_tech_knowledge_for_role": "No skills found for this job title from European employment systems.",
    "company_collections_title": "Interaction with company collections",
    "company_collections_tooltip": "Each graph represents a company collection, the percentage indicates how many contents of the total collection have been opened.",
    "top_contents_for_user_chart_title": "Trend skill related to your job title",
    "top_contents_for_user_chart_tooltip": "Learning contents that other similar professionals in your role have viewed.",
    "search_focus_user_title": "Interests",
    "search_focus_user_tooltip": "Skills you are developing that are not directly related to your role when you view learning content",
    "collection_legend": "Content interacted with",
    "counter_total_origin_by": "Contents viewed",
    "counter_total_levels": "Learning contents levels",
    "counter_content_total_levels": " for level {level}",
    "counter_origin_by_user": "From search and personal collection",
    "counter_origin_by_share": "From shared company collections",
    "counter_origin_by_system": "From collections suggested by Viblio",
    "counter_level_beginner": "Beginner",
    "counter_level_intermediate": "Intermediate",
    "counter_level_advanced": "Advanced",
    "user_chart_agreement": "It is not possible to see <br />the contents of the selected user <br />because he hasn't agreed <br />to the sharing of personal <br />data policy.",
    "job_charts_row": "Professional skills related to your job title",
    "trend_charts_row": "Trend skill related to your job title",
    "your_focus_row": "Interests",
    "org_focus_trend_row": "Trend learning contents you may consider",
    "org_focus_row": "Company collections",
    "improve_skill": "To improve the skill: ",
    "progress_collection": "Collection: {collectionName}",
    "content_searched_level": "{level} level contents viewed",
    "week_days": {
      "mon": "Mon",
      "tue": "Tue",
      "wed": "Wed",
      "thu": "Thu",
      "fri": "Fri",
      "sat": "Sat",
      "sun": "Sun"
    },
    "search_member": "Search user",
    "skills": "Skills",
    "points": "Points",
    "top_5": "Top {limit}",
    "total_searched_members": " user viewed content related to the skill |  users viewed content related to the skill ",
    "total_interacted_members": " User who interacted with: |  Users who interacted with: ",
    "collection_title": "company collection - ",
    "no_data_with_filter": "No results found for “{search}”",
    "from_for_level": "Skill “{skill}” improved after viewing  following learning contents",
    "resources_title_from_org_bubble": "{totalUsers} users viewed content related to the skill “{skill}”",
    "resources_title_from_top": "{userFullName} has viewed the following learning contents",
    "filters": {
      "search": "Refresh",
      "from_members": "Users personal dashboard",
      "organization_placeholder": "Select Organization",
      "occupation_placeholder": "Select Job Titles",
      "group_placeholder": "Select groups",
      "limit": {
        "item0": "All",
        "item10": "Top 10",
        "item20": "Top 20"
      }
    },
    "banner_manager_without_groups": {
      "title": "No data is available at the moment because you have not created your group",
      "message": "In this section you can view the data relating to the formation of your groups. \n\r Create your group to monitor the skills and interests of the people within the group.",
      "cta_button": "Create your group"
    }
  },
  "notification": {
    "empty": "No notification available"
  },
  "privacy": {
    "title": "PRIVACY POLICY",
    "header": "Articles 13-14 EU Regulation April 27, 2016, No. 679 'General Data Protection Regulation'",
    "hello": "Dear User,",
    "subtitle": "Apprendo S.r.l. is committed to respecting the user's privacy in accordance with the applicable regulations on the protection of personal data, particularly Regulation (EU) 2016/679 (hereinafter 'Regulation').",
    "info": "This document ('Privacy Policy') provides information on the processing of personal data collected by the company APPRENDO S.r.l. through this website (hereinafter 'Site') and therefore constitutes information for the data subjects in accordance with the aforementioned regulations. The information is provided only for this website www.viblio.com and not for other websites that may be consulted by the user through links.",
    "title_1": "1. WHO IS THE DATA CONTROLLER AND THE DPO?",
    "answer_1": "The data controller is the company Apprendo S.r.l. with headquarters in 33100 Udine, via Marinoni n. 12 (VAT ID 03029040304). The Data Controller can be contacted at the email address:",
    "title_2": "2. WHAT PERSONAL DATA DO WE COLLECT AND PROCESS?",
    "answer_2_1": "The categories of personal data that Apprendo S.r.l. may collect during navigation on the website www.viblio.com are as follows:",
    "answer_2_2": "We process data from your navigation on our site, necessary for the operation of the systems managing the Site and Internet communication protocols.",
    "answer_2_3": "These data are used solely for the purpose of obtaining anonymous statistical information about the use of the site and checking its correct operation, and are deleted immediately after processing. The data may be used to ascertain responsibility in case of hypothetical computer crimes against the site.",
    "answer_2_4": "We process the personal data you provide (including name, surname, company, email) to provide you with the requested information.",
    "answer_2_5": "If you choose to participate in the Beta Test community, we will use your email to update you on platform developments.",
    "answer_2_6": "We use cookies, small text files that the website inserts into user devices, to enhance usability and enable certain features. For more details, refer to the site's Cookie Policy.",
    "title_3": "3. FOR WHAT PURPOSES DO WE PROCESS PERSONAL DATA AND WHAT IS THE LEGAL BASIS",
    "answer_3_1": "For the operation of computer systems managing the Site. Only navigation data necessary for the operation of these systems and Internet communication protocols are processed.",
    "answer_3_2": "Legal Basis: The legitimate interest of the Data Controller in the technical management related to the functionality and security of the site.",
    "answer_3_3": "To provide assistance and respond to your queries.",
    "answer_3_4": "Legal Basis: The lawful basis for processing is the execution of the contract of which you are a part.",
    "answer_3_5": "To update you on platform developments if you have chosen to participate in Viblio's Beta Test community (for private users only).",
    "answer_3_6": "Legal Basis: The lawful basis for processing is the execution of the contract of which you are a part.",
    "answer_3_7": "Purposes necessary to ascertain, exercise, or defend a right in court or related to cases in which judicial authorities perform their functions.",
    "answer_3_8": "It is the legitimate interest of the Data Controller in exercising or defending a right.",
    "title_4": "4. NATURE OF DATA PROVISION AND CONSEQUENCES OF REFUSAL TO RESPOND",
    "answer_4_1": "Regarding points 3 a), 3 b), and 3 c) of this information, providing data is necessary to fulfill legal and contractual obligations. The user is free to provide them, but refusal may result in Apprendo S.r.l. being unable to manage your requests, navigate the site, inform you about the development phases to participate in the beta test version of the Viblio platform (for private users only), or carry out all the obligations required by current regulations.",
    "title_5": "5. HOW LONG DO WE KEEP THE DATA?",
    "answer_5_1": "Data will be stored for a limited period depending on the type of activity involving the processing of your personal data. After this period, your data will be permanently deleted or made irreversible anonymous. Your personal data is stored in accordance with the terms and criteria specified below:",
    "answer_5_2": "Data related to your assistance request is stored until the request is satisfied.",
    "answer_5_3": "Regarding the purpose under 3 c) - data used to participate in the beta test of the Viblio platform - they are stored until the request is satisfied. If you are no longer interested in participating in this initiative and do not wish to receive our communications, you can exercise your right to object through the 'unsubscribe' link found in the communications we send you.",
    "title_6": "6. TO WHOM COULD YOUR PERSONAL DATA BE COMMUNICATED?",
    "answer_6_1": "Your personal data may be processed by personnel of the Data Controller, appropriately trained and committed to confidentiality, or by third parties providing instrumental services to the Company, typically acting as data processors. These subjects will only receive data necessary for their respective functions and commit to using them only for the purposes mentioned above and treating them in compliance with applicable privacy regulations. The updated list of data processors is available at the Data Controller's offices and will be provided upon request by the data subject by writing to the following email address:",
    "title_7": "7. WHAT ARE YOUR RIGHTS?",
    "answer_7_1": "You have the right to obtain information about the processing of personal data concerning you, to access it, and obtain a copy, even by electronic means commonly used.",
    "answer_7_2": "You also have the right to update, integrate, or rectify your data.",
    "answer_7_3": "You can request the portability of data processed due to the execution of a contract or based on your consent.",
    "answer_7_4": "If interested, you have the right to request the deletion of your data.",
    "answer_7_5": "You also have the right to request the limitation of processing or to object, in whole or in part, to the processing of personal data concerning you, even for marketing purposes.",
    "answer_7_6": "If you believe that one or more processing activities carried out by us may violate regulations, or if you believe that your rights have not been protected, you can file a complaint with the Control Authority of the place where you usually reside, work, or where the alleged violation occurred. In Italy, you can contact the Privacy Guarantor Authority.",
    "answer_7_7": "These rights can be exercised by writing by postal mail to the above addresses or by email to the following email address:"
  },
  "subscription": {
    "title": "Unfortunately, Viblio cannot be used",
    "subtitle": "It appears that the plan associated with your organization has expired.",
    "contact_supervisor": "Contact the contact person {supervisor} for more information."
  }
}
