<template>
  <Dialog
    v-model:visible="model"
    class="w-full sm:w-[1040px]"
    :draggable="false"
    :header="$t('your_path.title')"
    modal
  >
    <div class="flex flex-col gap-4">
      {{ $t('your_path.description') }}
      <TabMenu
        v-model:activeIndex="active"
        :model="tabs"
      >
        <template #item="{ item, props }">
          <a
            v-if="item.label"
            v-bind="props.action"
            class="flex align-items-center gap-2"
          >
            <i
              v-if="item.icon"
              :class="item.icon"
            />
            {{ $t(item.label) }}
          </a>
        </template>
      </TabMenu>
      <div class="text-xl text-gray-900 font-extrabold">
        {{ $t(countText) }}: {{ totalItems }}
      </div>
      <div class="flex flex-col w-full divide-y">
        <div
          v-for="(item, idx) in items"
          :key="idx"
          class="flex justify-between py-2"
        >
          <div class="flex flex-col w-full">
            <div class="text-gray-900 font-bold text-sm">
              {{ item.resource_title || 'Course' }}
            </div>
            <div class="flex items-center justify-between w-full h-6">
              <div class="text-sm text-gray-700">
                {{ formatDate(item.date_update) }}
              </div>
              <Button
                size="small"
                severity="tertiary"
                icon-pos="right"
                icon="uil uil-arrow-right"
                label="Vai al contenuto"
                @click="
                  $router.push({ name: 'detail', params: { id: item.resource_id } })
                "
              />
            </div>
          </div>
        </div>
        <div
          v-if="items.length === 0"
          class="text-gray-900 py-4"
        >
          {{ $t('your_path.no_results') }}
        </div>
      </div>
      <div
        v-if="isLoading"
        class="flex flex-col divide-y"
      >
        <div
          v-for="i in 10"
          :key="i"
          class="py-2"
        >
          <div class="flex flex-col gap-2">
            <Skeleton
              shape="rounded"
              height="1rem"
              width="5rem"
            />
            <Skeleton
              shape="rounded"
              height="1rem"
              width="8rem"
            />
          </div>
        </div>
      </div>
      <Paginator
        v-if="totalItems > 10"
        v-model:first="pageOffset"
        :pt="{ root: 'bg-white' }"
        :rows="10"
        :total-records="totalItems"
        :template="{
          '767px':
            'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
          '1300px':
            'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
          default:
            'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        }"
      />
    </div>
  </Dialog>
</template>
<script setup>
import { defineModel, onMounted, ref, watch, computed } from 'vue'
import Dialog from 'primevue/dialog'
import TabMenu from 'primevue/tabmenu'
import Paginator from 'primevue/paginator'
import Button from 'primevue/button'
import Skeleton from 'primevue/skeleton'

import { authorizedRequest } from '@/api'
import { useUserData } from '@/composables/userData'
const { getUser } = useUserData()

const items = ref([])
const active = ref(0)
const pageOffset = ref(0)
const totalItems = ref(0)
const isLoading = ref(false)

const countText = computed(() => {
  return active.value === 0
    ? 'your_path.nb_completed'
    : active.value === 1
    ? 'your_path.nb_liked'
    : 'your_path.nb_opened'
})
const currentUser = getUser()

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('en-GB')
}

const loadTrackers = async () => {
  isLoading.value = true
  items.value = []

  const activeTabParamsMap = {
    0: { completed: true },
    1: { resource_status: 'LIKED' },
    2: { count__gt: 0 },
  }
  const params = new URLSearchParams({
    user: currentUser.id,
    limit: 10,
    offset: pageOffset.value,
    ...activeTabParamsMap[active.value],
  }).toString()
  const result = await authorizedRequest().get(
    `api/v2/drf/trackers/?${params}`
  )
  items.value = result.data.results
  totalItems.value = result.data.count
  isLoading.value = false
}

watch([pageOffset, active], async () => {
  await loadTrackers()
})

onMounted(async () => {
  await loadTrackers()
})

const tabs = ref([
  { label: 'your_path.completed', icon: 'uil uil-check-circle' },
  { id: 1, label: 'your_path.liked', icon: 'uil uil-thumbs-up' },
  { id: 2, label: 'your_path.opened', icon: 'uil uil-external-link-alt' },
])

const model = defineModel({ default: false })
</script>
