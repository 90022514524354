<template>
  <div
    class="absolute bottom-0 hidden lg:flex w-full justify-between"
  >
    <img src="../../assets/search-boy.svg">
    <img
      src="../../assets/search-girl.svg"
      class="right-0 bottom-4 absolute"
    >
  </div>
</template>