<template>
  <Breadcrumb
    :pt="{ root: 'border-0 text-sm p-0 hidden md:block' }"
    :model="breadcrumbs"
  />
</template>
<script setup>
import Breadcrumb from "primevue/breadcrumb";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from 'vue-i18n'

const route = useRoute()
const currentRouteName = computed(() => route.name)
const { t } = useI18n();

const getCurrentBreadcrumbs = () => {
  switch (currentRouteName.value) {
    case 'home':
      return []
    case 'search':
      return [{ label: t('sidebar.explore'), url: '/search'}]
    case 'search-results':
      return [{ label: t('sidebar.explore'), url: '/search'}, { label: t('sidebar.explore_results')}]
    case 'dashboard':
      return [{ label: t('sidebar.analysis'), url: '/dashboard'}]
    case 'organizations':
      return [{ label: t('sidebar.manage'), url: '/organizations'}]
    case 'organization-groups':
      return [{ label: t('sidebar.groups'), url: '/organizations/groups'}]
    case 'group-detail':
      return [{ label: t('sidebar.groups'), url: '/organizations/groups'}]
    case 'organization-users':
      return [{ label: t('sidebar.users'), url: '/organizations/users'}]
    case 'plan-users':
      return [{ label: t('sidebar.plans'), url: '/organizations/plans'}]
    case 'collection-detail':
      return [{ label: t('sidebar.collections')}]
    case 'collections-list':
      return [{ label: t('sidebar.collections')}]
    case 'career-coach':
      return [{ label: 'Career Coach'}]
    default:
      return []
  }
}

const breadcrumbs = computed(() => [
    { icon: 'uil uil-home', url: '/' },
    ...getCurrentBreadcrumbs()
  ]
)
</script>
