<template>
  <div
    class="w-fit rounded-full justify-center items-center gap-1 inline-flex"
    :class="{[color]: color, 'h-5 px-2 py-0.5': size === 'small', 'px-3 py-1': size === 'normal'}"
  >
    <i
      v-if="icon"
      class="text-sm"
      :class="icon"
    />
    <div
      class="font-bold leading-none"
      :class="{'text-base': size === 'normal', 'text-xs': size === 'small'}"
    >
      {{ text }}
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
    icon: {
        type: String,
        default: null,
    },
    text: {
        type: String,
        default: ''
    },
    size: {
        type: String,
        default: 'small'
    },
});

const color = computed(() => {
    switch (props.type) {
        case 'primary':
            return 'text-blue-50 bg-blue-700';
        case 'secondary':
            return 'text-blue-700 bg-white border border-gray-300 border-solid';
        case 'success':
            return 'text-green-900 bg-green-200';
        case 'info':
            return 'text-blue-900 bg-blue-100';
        case 'info-dark':
            return 'text-blue-100 bg-blue-900';
        case 'warn':
            return 'text-orange-900 bg-orange-100';
        default:
            return '';
    }
});

</script>
