import {authorizedRequest, transformFilters, manageApiError, responseApi} from "@/api";
import config from "@/config";
import Resource from "@/resources/Resource";
import {ErrorBus, ErrorCommands} from "@/eventBus";


async function list(limit, offset, paramsArgs) {
  const filtersFormatted = transformFilters(paramsArgs.filters);
  const ordering = paramsArgs.sortField ? ((paramsArgs.sortOrder || 0) >= 0 ? '' : '-') + paramsArgs.sortField : '';
  const paginationParams = `limit=${limit}&offset=${offset}&ordering=${ordering}`;
  const params = filtersFormatted ? filtersFormatted+'&'+paginationParams : '?'+paginationParams;
  try {
    const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/resources/${params}`);
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }

}

async function retrieve(id) {try {
  const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/resources/${id}/`);
  return {error: false, result: result.data};
} catch (error) {
  return manageApiError(error);
}
}


// async function getTopContentsForOccupations(limit=6) {
//     // return the title of system collections (phantom collections)
//     try {
//       const results = await authorizedRequest().get(`api/v2/drf/resources/get_top_for_occupations/?limit=${limit}`);
//       return responseApi(Resource, {error: false, result: results.data}, false, true);
//     } catch (error) {
//       return manageApiError(error);
//     }
//   }
//
  // async function getContentsForStandardCollection(collection_id) {
  //   // return the title of system collections (phantom collections)
  //   try {
  //     const results = await authorizedRequest().get(`api/v2/drf/resources/for_standard_collection/?collection_id=${collection_id}`);
  //     return responseApi(Resource, {error: false, result: results.data}, false, true);
  //   } catch (error) {
  //     return manageApiError(error);
  //   }
  // }
//
// async function getContentsForOccupation(occupation='') {
//   // return the title of system collections (phantom collections)
//   try {
//     const results = await authorizedRequest().get(`api/v2/drf/resources/for_user_occupation/?occupation=${occupation}`);
//     return responseApi(Resource, {error: false, result: results.data}, false, true);
//   } catch (error) {
//     return manageApiError(error);
//   }
// }
async function getResourcesLanguages() {
  try {
    const results = await authorizedRequest().get(`api/v2/drf/resources/languages/`);
    if(!results || results.error){
      ErrorBus.emit(ErrorCommands.ERROR, { message: 'errors.general' });
      return {error: results?.error || 'errors.general'};
    }
    return {
      count: results.data.count,
      next: results.data.next,
      results: results.data.results
    };
  } catch (error) {
    return manageApiError(error);
  }
}

async function searchElastic(filters, paging, q = null) {
  let _start, _rows;
  if (Array.isArray(paging)) {
      [_start, _rows] = paging;
  } else {
      _start = paging;
      _rows = 0;
  }
  const filtersFormatted = transformFilters(filters);
  try {
      let queryUrl = `api/v2/drf/resources/search/${filtersFormatted}&start=${_start || 0}`;
      if (_rows) {
          queryUrl += `&rows=${_rows}`;
      }
      if (q) {
          queryUrl += `&q=${q}`;
      }
      const response = await authorizedRequest().get(queryUrl);
      if (!response || !response.data) {
          throw new Error("no response");
      }
      return responseApi(Resource, {error: false, result: response.data}, false, true)

  } catch (error) {
    return manageApiError(error);
  }
}
// async function checkUdemyLink(link) {
//     const {isAuthenticated} = useUserData();
//     const req = isAuthenticated.value ? authorizedRequest() : request();
//     const response = await req.get(`api/v2/drf/resourceitems/check_udemy_viblio_link/?link=${link}`);
//     return response.data.link_valid
//
// }

export { list, retrieve, searchElastic, getResourcesLanguages };
