<template>
  <Modal
    :is-open="!!component"
    :title="componentTitle"
    :large="componentLarge"
    :fit="componentFit"
    :modal-style="modalStyle"
    :modal-class="modalClass"
    :allowed-in-expired="allowedInExpired"
    :unclose="unclose"
    @onClose="handleModalClose"
  >
    <component
      :is="component"
      v-bind="componentProps"
      v-on="componentListeners"
      @onClose="handleClose"
    />
  </Modal>
</template>

<script>
import { ref, shallowRef } from "vue";
import { ModalBus, ModalCommands } from "@/eventBus";
import Modal from "@/components/common/Modal";
import { useKeypress } from 'vue3-keypress'

export default {
  components: { Modal },
  setup(){
    const component = shallowRef(null);
    const componentTitle = ref(null);
    const componentProps = ref(null);
    const componentLarge = ref(null);
    const componentFit = ref(null);
    const allowedInExpired = ref(false);
    const modalStyle = ref(null);
    const modalClass = ref(null);
    const componentListeners = ref({});
    const componentCloseOnClick = ref(null);
    const unclose = ref(false);
    ModalBus.on(
      ModalCommands.OPEN,
      ( componentInfo ) => {
        component.value = componentInfo.component;
        componentTitle.value = componentInfo.title ?? '';
        componentLarge.value = componentInfo.large ?? false;
        componentProps.value = componentInfo.props ?? {};
        componentFit.value = componentProps && componentProps.value && componentProps.value.fit ? true : false;
        modalStyle.value = componentInfo.style ?? '';
        modalClass.value = componentInfo.modalClass ?? '';
        allowedInExpired.value = componentInfo.allowedInExpired ?? false;
        componentListeners.value = componentInfo.eventsOn ?? {};
        unclose.value = componentInfo.unclose ?? false;
        componentCloseOnClick.value = componentInfo.closeOnClick ?? false;
      }
    );
     ModalBus.on(
      ModalCommands.CLOSE,
      () => {
        handleClose()
      }
    );
    const handleClose = () => {
      component.value = null;
    }
    const handleModalClose = (force = false) => {
      if (!componentCloseOnClick && !force) return;
      handleClose();
    }
     useKeypress({
        keyEvent: "keydown",
        keyBinds: [
          {
            keyCode: "esc",
            success: handleClose,
          },
        ]
    })
    return {
      handleClose,
      handleModalClose,
      component,
      componentTitle,
      componentProps,
      componentLarge,
      componentFit,
      modalStyle,
      modalClass,
      allowedInExpired,
      componentListeners,
      componentCloseOnClick,
      unclose
    };
  }
};
</script>
