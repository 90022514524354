<template>
  <div
    class="w-full h-[128px] md:w-[484px] md:h-[152px] relative group cursor-pointer shrink-0"
    tabindex="0"
  >
    <div class="absolute w-full h-full transition ease-in-out duration-500 group-hover:shadow-wide group-hover:brightness-75 group-focus:outline-blue-500 group-focus:outline-2 cursor-pointer rounded-2xl overflow-hidden bg-gradient-to-tl from-blue-500 to-blue-900 flex-shrink-0" />
    <div class="relative flex gap-4 p-4">
      <SmartImageBackground
        class="w-[96px] h-[96px] md:w-[120px] md:h-[120px] shrink-0 p-4 bg-cover bg-center bg-no-repeat z-10 rounded-lg"
        placeholder="https://pi.tedcdn.com/r/talkstar-photos.s3.amazonaws.com/uploads/f28b5220-67e5-4a8d-a863-b436ee3e19dd/AnthonyFauci_2022H-embed.jpg?h=360&origin=rss&w=480"
        :src="image"
      />
      <div class="flex flex-col justify-between">
        <div class="text-white truncate">
          {{ $t('card.expert_title') }} <strong>{{ name }}</strong>
        </div>
        <div class="text-xl font-extrabold text-white line-clamp-2">
          {{ title }}
        </div>
        <div class="text-white truncate">
          {{ description }}
        </div>
      </div>
    </div>
    <div class="relative">
      <LogoRound
        fill="rgba(255, 255, 255, 0.15)"
        class="w-[554px] h-[554px] absolute top-[-492px] right-[-174px]"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import LogoRound from '@/components/cards/LogoRound.vue';
import SmartImageBackground from '@/components/common/SmartImageBackground.vue';

const props = defineProps({
  title: {
    type: String,
    default: 'Collection',
  },
  description: {
    type: String,
    default: 'Lorem ipsum dolor sit amet',
  },
  name: {
    type: String,
    default: 'Lorem ipsum',
  },
  resources: {
    type: Array,
    default: () => [],
  },
});



const image = ref('')
const found = ref(false)


const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true,url);
    } else {
      img.onload = () => {
        callback(true,url);
      };

      img.onerror = () => {
        callback(false,url);
      };
    }
  }


for(let i=0;i< props.resources.length;i++){
  if(found.value != true){
  checkIfImageExists(props.resources[i].image, (exists,url) => {
  if (exists && image.value.length == 0) {
    found.value = true
    image.value = url
  }
});
  }
}


</script>

