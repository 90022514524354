<template>
  <div
    class="components-form-input"
    :class="{
      'components-form-input-right': labelPosition === 'right'
    }"
  >
    <label
      :for="name"
      :class="{'hidden': !label, 'text-red-500': invalid}"
    >
      {{ label ? $t(label, labelOption) : "" }}
    </label>
    <div :class="wrapperClassList">
      <!--      <span-->
      <!--        v-if="icon.length"-->
      <!--        :class="['fas', icon]"-->
      <!--        class="fas-icon"-->
      <!--      />-->
      <div
        v-if="type==='select'"
        class="z-10"
      >
        <Dropdown
          v-model="selectedOption"
          :value="modelValue"
          :options="options"
          option-label="label"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="invalid ? 'input-error' : ''"
          @change="updateValue"
        >
          <template #value="slotProps">
            <div class="flex align-options-center">
              <div>{{ optionsTrad ? $t(slotProps.value.label) : slotProps.value.label }}</div>
            </div>
          </template>
          <template #option="slotProps">
            <div class="flex align-options-center">
              <div>{{ optionsTrad ? $t(slotProps.option.label) : slotProps.option.label }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div
        v-else-if="type==='date'"
        class="input-date-wrap"
      >
        <Calendar
          v-model="selectedOption"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"
          :required="required"
          :class="invalid ? 'input-error' : ''"
          :title="title"
          :value="modelValue"
          :show-icon="true"
          :show-button-bar="true"
          date-format="dd/mm/yy"
          :min-date="minDate"
          :max-date="maxDate"
          @hide="updateValue"
        />
      </div>
      <div
        v-else-if="type==='area'"
      >
        <Textarea
          v-model="selectedOption"
          :disabled="disabled"
          :placeholder="placeholder"
          :required="required"
          :class="invalid ? 'input-error' : ''"
          :rows="rows"
          :cols="cols"
          @input="updateValue"
        />
      </div>
      <div
        v-else-if="type==='switch'"
      >
        <InputSwitch
          v-model="selectedOption"
          :disabled="disabled"
          :placeholder="placeholder"
          :required="required"
          :class="invalid ? 'input-error' : ''"
          @change="updateValue"
        />
      </div>
      <!-- TODO: miss radio-group button input field  -->
      <div
        v-else-if="dynamicType === 'number'"
      >
        <InputNumber
          v-model="selectedOption"
          :disabled="disabled"
          :placeholder="placeholder"
          :required="required"
          :class="invalid ? 'input-error' : ''"
          :value="modelValue"
          :min="minValue"
          :max="maxValue"
          @input="updateValue"
        />
      </div>
      <div
        v-else-if="type === 'password'"
      >
        <Password
          v-model="selectedOption"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"
          :required="required"
          :class="invalid ? 'input-error' : ''"
          :feedback="false"
          :toggle-mask="true"
          @input="updateValue"
        />
      </div>
      <div
        v-else
      >
        <InputText
          :autocomplete="autocomplete"
          :disabled="disabled"
          :name="name"
          :pattern="pattern"
          :placeholder="placeholder"
          :required="required"
          :type="dynamicType"
          :value="modelValue"
          :class="invalid || field_error ? 'input-error' : ''"
          @input="updateValue"
        />
        <!--        <span-->
        <!--          v-if="type === 'password'"-->
        <!--          :class="{ 'fas fa-eye': show, 'fas fa-eye-slash': !show }"-->
        <!--          @click="show = !show"-->
        <!--        />-->
      </div>
    </div>
    <small
      v-if="help"
      :id="name+'-help'"
    >
      {{ $t(help) }}
    </small>
    <small
      v-if="field_error"
      :id="name+'field-error'"
      class="error"
    >
      {{ $t('general.form.field_errors.'+type) }}
    </small>
  </div>
</template>

<script>
import {ref, toRefs, watch} from "vue";


import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import Password from 'primevue/password';
import InputSwitch from 'primevue/inputswitch';

export default {
  name: "FormInput",
  components: {
    Calendar,
    Dropdown,
    Textarea,
    InputText,
    InputNumber,
    Password,
    InputSwitch,
  },
  props: {
    modelValue: {type: [String, Number, Boolean, Date], default: ""},
    icon: {type: String, default: ""},
    type: {type: String, default: "text"},
    name: {type: String, default: ""},
    help: {type: String, default: ""},
    placeholder: {type: String, default: ""},
    label: {type: String, default: ""},
    labelOption: {type: Object, default: () => {}},
    labelPosition: {type: String, default: 'top'},
    disabled: {type: Boolean, default: undefined},
    required: {type: Boolean, default: false},
    invalid: {type: Boolean, default: false},
    autocomplete: {type: String, default: ""},
    pattern: {type: String, default: undefined},
    title: {type: String, default: undefined},
    field: {type: String, default: ''},
    rows: {type: Number, default: 3},
    cols: {type: Number, default: 80},
    minValue: {type: [String, Number, Date], default: null},
    maxValue: {type: [String, Number, Date], default: null},
    options: {type: Array, default: () => []},
    optionsTrad: {type: Boolean, default: false},
  },
  setup(props, {emit}) {
    // const {icon, type, disabled} = toRefs(props);
    const {type, disabled} = toRefs(props);
    const updateValue = (e) => {
      if (props.type === 'select'){
        emit("update:modelValue", e.key);
      } else if (['date', 'area', 'password', 'switch'].indexOf(props.type) > -1){
        if (props.type === 'password') {
          field_error.value = configuredRules['password'](selectedOption.value)
        } else {
          field_error.value = false
        }
        emit("update:modelValue", selectedOption.value);
      } else if (['text', 'email'].indexOf(props.type) > -1){
        if (props.type === 'email') {
          field_error.value = configuredRules['email'](e.target.value)
        } else {
          field_error.value = false
        }
        emit("update:modelValue", e.target.value);
      } else {
        emit("update:modelValue", e.value);
        if(['number'].indexOf(props.type) > -1) {
          selectedOption.value = e.value
        }
      }
    };
    const selectedOption = ref(props.modelValue)

    if (props.type === 'select') {
      // console.log('options', selectedOption, props.options )

      // set Initial selectedOption
      if (!selectedOption.value.key) {
        const matchingOptions = props.options.filter(
          (o) => o.key === selectedOption.value
        );
        selectedOption.value = matchingOptions[0]
      }

      // add binding selectOption - modelValue
      watch(selectedOption, updateValue);
    } else if (props.type === 'date' && props.modelValue) {
      emit("update:modelValue", props.modelValue)
    }
    const minDate = ref(props.minValue)
    const maxDate = ref(props.maxValue)
    if (props.type === 'date') {
      if(minDate.value) {
        minDate.value = new Date(minDate.value)
      }
      if(maxDate.value) {
        maxDate.value = new Date(maxDate.value)
      }

    }

    const field_error = ref(false);
    // const email_rule = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/
    const email_rule = /^\w+[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/

    const containsUppercaseRule = /[A-Z]/
    const containsLowercaseRule = /[a-z]/
    const containsNumberRule = /[0-9]/
    const containsSpecialRule = /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/
    const configuredRules = {
      'email': (val) => {
        return !email_rule.test(String(val).toLowerCase())
      },
      'password': (val) => {
        return !(
          containsUppercaseRule.test(val)
          && containsLowercaseRule.test(val)
          && containsNumberRule.test(val)
          && containsSpecialRule.test(val)
          && val .length > 7
        )
      }
    }
    let wrapperClassList = ["input-wrapper"];
    if (props.type === 'select') {
      wrapperClassList.push('input-wrapper_select');
    }
    // if (icon.value.length) {
    //   wrapperClassList.push("input-wrapper__icon");
    // }
    // if (type.value === "password") {
    //   wrapperClassList.push("input-wrapper__password");
    // }
    if (disabled.value === true) {
      wrapperClassList.push("input-wrapper__disabled");
    }
    const show = ref(false);
    let dynamicType = ref(type.value);
    // if (type.value === "password") {
    //   dynamicType = computed(() => {
    //     return show.value ? "text" : "password";
    //   });
    // }
    return {
      dynamicType,
      updateValue,
      show,
      wrapperClassList,
      selectedOption,
      minDate,
      maxDate,
      field_error,
    };
  },
};
</script>

<style scoped>
.components-form-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.components-form-input-right {
    width: fit-content;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}
.components-form-input-right > .input-wrapper {
  width: fit-content !important;
}
.components-form-input-right > label {
  /*color: var(--Grays-Gray-800, #1E293B);*/
  /*font-feature-settings: 'calt' off;*/
  font-size: 0.875rem !important;
  /*font-weight: 400;*/
  line-height: 1.75rem !important;
}
.input-wrapper > div,
.input-wrapper > div > input,
.input-wrapper > div > textarea,
.input-wrapper > div > span,
.input-wrapper > div > div > input {
  width: 100%;
}
.components-form-input > label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  /* text-sm/bold */
  font-family: var(--font-text);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem; /* 133.333% */
}
.components-form-input > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.21875rem;
  width: 100%;
  /*width: fit-content;*/
}
.hide {
  display: unset;
}
.input-wrapper {
  display: grid;
  /* width: calc(100% - 2rem); */
  /*background: var(--color-gray-lighten);*/
  border-radius: 4px;
  grid-template-columns: auto;
}

.input-wrapper_select {
  /*background: none;*/
  max-height: 3rem;
/*  margin-bottom: 2.5rem;*/
}

.input-wrapper_select button {
  background: var(--color-gray-lighten);
}

.input-wrapper_select.input-wrapper.input-wrapper__icon span {
  align-self: start;
  background: var(--color-gray-lighten);
}

.input-wrapper_select > .fas-icon {
  padding: 0.75rem 0;
}

.z-10 {
  z-index: 10;
}

.input-wrapper.input-wrapper__icon {
  grid-template-columns: 2.5rem auto;
}

.input-wrapper.input-wrapper__password {
  grid-template-columns: auto 2.5rem;
}

.input-wrapper.input-wrapper__icon.input-wrapper__password {
  grid-template-columns: 2.5rem auto 2.5rem;
}

input {
  display: block;
}

span {
  display: inline-block;
  color: var(--color-gray-darken);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-22);
  align-self: center;
  justify-self: center;
}

.input-select {
  width: 100%;
}

.select-dropdown {
  position: inherit;
  width: inherit;
}

.input-wrapper__disabled input,
.input-wrapper__disabled span {
  color: var(--color-gray);
}
</style>
