<template>
  <div class="w-full min-h-[100dvh] pb-10 pt-20 bg-gray-100 relative">
    <NewSearchSidebar
      v-model="advancedFilterVisible"
      :filters="{...filters}"
      @update-filters="updateFilters($event)"
    />
    <div class="flex flex-col items-center">
      <div class="flex gap-2 px-4 w-full sm:w-[80%] max-w-[718px]">
        <IconField
          icon-position="left"
          class="w-full"
        >
          <InputIcon class="uil uil-search text-lg top-5" />
          <InputText
            v-model="internalSearchQuery"
            @keyup.enter="updateSearchQuery(internalSearchQuery)"
            class="h-full pl-10"
          />
        </IconField>
        <Button
          class="flex-shrink-0"
          :label="$t('search.search_button')"
          @click="updateSearchQuery(internalSearchQuery)"
        />
      </div>
    </div>
    <div
      v-if="showResults || showNoResults"
      class="flex flex-col gap-4 m-4 sm:m-6"
    >
      <div class="flex justify-between items-center">
        <div class="text-base text-gray-900">
          {{ totalItems }} {{ $t('search.results_found') }}
        </div>
        <Button
          class="text-gray-900 flex gap-2"
          link
          @click="advancedFilterVisible = true"
        >
          <div class="relative">
            <i class="uil uil-filter" />
            <Badge
              :value="filtersApplied"
              severity="info"
              class="absolute -top-1 -right-2 w-4 h-4 text-xs bg-blue-700"
            />
          </div>
          {{ $t('search.filters') }}
        </Button>
      </div>
      <NoResults
        v-if="showNoResults"
        :query="searchQuery"
      />
      <CardHorizontal
        v-for="course in data"
        :key="course.id"
        :course="course"
        @openDetails="() => $router.push({
          name: 'detail',
          params: { id: course.id },
          state: {click_source: sourceCollection('SEARCH')}})"
      />
      <Paginator
        v-if="totalItems > 10"
        v-model:first="pageOffset"
        :rows="10"
        :total-records="totalItems"
        :template="{
          '767px': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
          '1300px': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
          default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink'
        }"
        @update:first="scrollToTop"
      />
      <!-- TODO: here RowsPerPageDropdown is missing because search api doesn't manage it! -->
      <!--        :rows-per-page-options="[10, 20, 50]"-->
      <!--        :pt="{-->
      <!--          root: 'bg-transparent', pageButton: ({ context }) => {-->
      <!--            if (context.active) return 'bg-black text-white rounded-full cursor-pointer'-->
      <!--            return 'bg-white text-black rounded-full cursor-pointer'-->
      <!--          }-->
      <!--        }"-->
    </div>
    <SearchLoading v-if="isLoading" />
  </div>
</template>
<script setup>
import { computed, ref, watch } from "vue"
// import { vue3Debounce } from 'vue-debounce'
import { useRoute } from "vue-router";

import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Paginator from 'primevue/paginator';
import Badge from "primevue/badge";
import sourceCollection from "@/lib/sourceCollection";

import NewSearchSidebar from "@/components/NewSearchSidebar.vue";
import { pushToDataLayer } from "../../composables/useGoogleTagManager";

import useCourseSearch from "@/composables/useCourseSearch";
import CardHorizontal from "@/components/CardHorizontal.vue";
import NoResults from './NoResults.vue';
import SearchLoading from "./SearchLoading.vue";
import router from "../../router";

const route = useRoute();
// const vDebounce = vue3Debounce({ lock: true })

const advancedFilterVisible = ref(false)

const defaultFilters = {
  price: '',
  level: '',
  language: '',
  provider: '',
  type: '',
}

const internalSearchQuery = ref(route.query.q || '')
const searchQuery = ref(internalSearchQuery.value)
const pageOffset = ref(route.query.page ? parseInt(route.query.page) * 10 : 0)
const value = computed(() => searchQuery.value)
const filters = ref({
  price: route.query.price || '',
  level: route.query.level ? route.query.level.split(',').map(level => ({ key: level })) : '',
  language: route.query.language ? route.query.language.split(',').map(language => ({ key: language })) : '',
  provider: route.query.provider ? route.query.provider.split(',').map(provider => ({ key: provider })) : '',
  type: route.query.type ? route.query.type.split(',').map(type => ({ key: type, label: type })) : '',
})
const filtersApplied = computed(() => {
  if(filters.value === undefined) {
    return 0
  }
  return Object.keys(filters.value).filter(key => filters.value[key] !== defaultFilters[key]).length
})
const { data, isLoading, totalItems } = useCourseSearch(value, pageOffset, filters);

const updateFilters = (event) => {
  const query = createQuery(event.filters)
  router.push({ query })
  filters.value = event.filters
  pageOffset.value = 0
}

const createQuery = (filters) => {
  const query = { q: searchQuery.value, page: pageOffset.value / 10 }

  if (filters.price) query.price = filters.price
  const arrayAttributes = ['level', 'language', 'provider', 'type', 'price']
  for (const key of arrayAttributes) {
    if (filters[key] && Array.isArray(filters[key]) && filters[key].length > 0) query[key] = filters[key].map(({ key }) => key).join(',')
  }
  return query
}

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const updateSearchQuery = (value) => {
  searchQuery.value = value
  pageOffset.value = 0
  router.push({ query: createQuery(filters.value) })
  pushToDataLayer({
    event: 'view_search_results',
    search_term: value
  })
}

const showResults = computed(() => {
  return data.value.length > 0 && searchQuery.value !== '' && searchQuery.value.length > 2
})

const showNoResults = computed(() => {
  return data.value.length === 0 && searchQuery.value !== '' && !isLoading.value
})

watch(pageOffset, (offset) => {
  router.push({ query: {...createQuery(filters.value), page: offset / 10}})
})
</script>
