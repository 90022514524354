import { onBeforeRouteUpdate, useRouter } from "vue-router";
import { useAuthenticated } from "@/composables/userData.js"

// to be used in the setup function of a route
// does not return anything
// will only add the event handler for
// onBeforeRouteUpdate to prevent unauthenticated users
// to access a page by entering its URL directly
export default function useRedirectUnauthorized () {
  const { isAuthenticated } = useAuthenticated()
  const router = useRouter()

  function redirectUnauthorized (to) {
    if (isAuthenticated.value) { return }
    return router.push({
      path: '/login',
      name: "login",
      // save the location we were at to come back later
      query: { redirect: to.fullPath }
    })
  }

  onBeforeRouteUpdate(redirectUnauthorized)
}
