
export default function sourceCollection (systemType, fromSingleShare=false) {
  let tag = "";

  let is_sugg = systemType && systemType.indexOf('sugg.same') > -1 ? 'SUGG_SAME-' : (systemType && systemType.indexOf('sugg.similar') > -1 ? 'SUGG_SIMILAR-' : '');
  if (is_sugg) {
    let pieces = systemType.split('-');
    systemType = pieces[pieces.length-1];
  }
  switch (systemType) {
    case 'occupation':
    case 'COLLECTION_SYSTEM_OCCUPATION':
      tag = "COLLECTION_SYSTEM_OCCUPATION";
      break;
    case 'occupation_hard':
    case "COLLECTION_SYSTEM_HARD":
      tag = "COLLECTION_SYSTEM_HARD";
      break;
    case 'occupation_soft':
    case "COLLECTION_SYSTEM_SOFT":
      tag = "COLLECTION_SYSTEM_SOFT";
      break;
    case 'occupation_hot_tech':
    case "COLLECTION_SYSTEM_HOT_TECH":
      tag = "COLLECTION_SYSTEM_HOT_TECH";
      break;
    case 'interview':
    case "COLLECTION_SYSTEM_INTERVIEW":
      tag = "COLLECTION_SYSTEM_INTERVIEW";
      break;
    case 'skill':
    case "COLLECTION_SYSTEM_SKILLS":
      tag = "COLLECTION_SYSTEM_SKILLS";
      break;
    case 'global':
    case "COLLECTION_GLOBAL":
      tag = "COLLECTION_GLOBAL";
      break;
    case 'from_mine':
    case "COLLECTION_PRIVATE":
      tag = "COLLECTION_PRIVATE";
      break;
    case 'from_organization':
    case "COLLECTION_ORG":
      tag = "COLLECTION_ORG";
      break;
    case 'from_group':
    case "COLLECTION_MEMBER":
    case "COLLECTION_GROUP":
      tag = fromSingleShare ? "COLLECTION_MEMBER" : "COLLECTION_GROUP";
      break;
    case 'SEARCH':
      tag = "SEARCH";
      break;
    default:
      tag = "";
  }
  if (is_sugg) {
    tag = is_sugg + tag;
  }
  return tag;
}
