import { ref } from 'vue';
import {getQuestionnaireByUserId, getQuestionnaireReport, newQuestionnaire} from '@/api/user';
import {useUserData} from "@/composables/userData";
const { getUser } = useUserData();

export function useQuestionnaire() {

  const currentUser = getUser();
  const questionnaire = ref(null);
  const isLoadingQuestionnaire = ref(true);
  const questionnaireStatusData = ref({
    dataTest: '',
    ctaDataTest: '',
    text: '',
    textDesc: '',
    ctaLabel: ''
  });

  const loadQuestionnaireDetails = () => {
    getQuestionnaireByUserId(currentUser.id).then((response) => {
      if (response.error){
        isLoadingQuestionnaire.value = false;
        questionnaire.value = { status: null };
        return ;
      }
      questionnaire.value = response.result;
      refreshStatusData();
      isLoadingQuestionnaire.value = false;
    }).catch((e) => {
      console.error('[useQuestionnaire.vue] error: ' + e);
      isLoadingQuestionnaire.value = false;
    });
  };

  const startQuestionnaire = async () => {
    return await newQuestionnaire(currentUser.id);
  };

  const getQuestReport = async () => {
    return await getQuestionnaireReport(questionnaire.value.id);
  };

  const refreshStatusData = () => {

      if (['CREATED', 'STARTED'].indexOf(questionnaire.value.status) > -1) {
        questionnaireStatusData.value = {
          dataTest: 'eggup_started',
          ctaDataTest: 'go-to-questionnaire',
          text: 'user_profile.egg_up.started',
          textDesc: 'user_profile.egg_up.started_2',
          ctaLabel: 'user_profile.egg_up.started_cta',
        };
      } else if (['COMPLETED', 'PROCESSED'].indexOf(questionnaire.value.status) > -1) {
        questionnaireStatusData.value = {
          dataTest: 'eggup_processing',
          ctaDataTest: 'go-to-eggup_processing',
          text: 'user_profile.egg_up.processing',
          textDesc: 'user_profile.egg_up.processing_2',
          ctaLabel: ''
        };
      } else if (questionnaire.value.status === 'FAILED') {
        questionnaireStatusData.value = {
          dataTest: 'eggup_failed',
          text: 'user_profile.egg_up.failed',
          textDesc: 'user_profile.egg_up.failed_2',
          ctaDataTest: 'go-to-eggup-after-error',
          ctaLabel: 'user_profile.egg_up.failed_cta',
        };
      } else if (questionnaire.value.status === 'REPORT_READY') {
        questionnaireStatusData.value = {
          dataTest: 'get-report',
          ctaDataTest: 'eggup_completed',
          text: 'user_profile.egg_up.completed',
          textDesc: 'user_profile.egg_up.completed_2',
          ctaLabel: 'user_profile.egg_up.download_cta'
        };
      } else {
        questionnaireStatusData.value = {
          dataTest: 'start',
          ctaDataTest: 'start-questionnaire',
          text: 'user_profile.egg_up.to_be_initialize',
          textDesc: '',
          ctaLabel: 'user_profile.egg_up.to_be_initialize_cta'
        };
      }
  };

  loadQuestionnaireDetails();

  return {
    questionnaire,
    questionnaireStatusData,
    isLoadingQuestionnaire,
    startQuestionnaire,
    getQuestReport,
  };
}
