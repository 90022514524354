<template>
  <Dialog
    v-model:visible="model"
    modal
    :draggable="false"
    :header="$t(header)"
    :style="{ width: '30rem' }"
  >
    <StartInterview
      :mode="mode"
      :on-modal="true"
      @close="() =>{model = false}"
    />
  </Dialog>
</template>
<script setup>
import { computed, defineModel, defineProps } from 'vue';
import StartInterview from '@/views/StartInterview.vue';
import Dialog from 'primevue/dialog';

const props = defineProps({
  mode: {
    type: String,
    required: true,
    default: 'initialize',
  },
});

const header = computed(()=> {
  return props.mode === 'initialize' ? 'interview.modal_header_initialize' : 'interview.modal_header_continue';
})
const model = defineModel({ default: false });


</script>
