<template>
  <div class="finish-insterview">
    <div class="container">
      <box-l class="form-illustrated">
        <div class="form-box form-box__interview">
          <div class="form-box--top">
            <h3> {{ $t('user_profile.interview_finish_title') }} </h3>
            <p> {{ $t('user_profile.interview_finish_desc') }} </p>
            <p> {{ $t('user_profile.interview_finish_desc2') }} </p>
          </div>
          <form @submit.prevent="toHome">
            <stack-l>
              <SubmitButton> {{ $t('user_profile.interview_finish_back') }} </SubmitButton>
            </stack-l>
          </form>
        </div>
        <div class="illustration-box">
          <img src="../assets/man-telephone.svg">
        </div>
      </box-l>
    </div>
  </div>
</template>

<script>
import "@/vanilla/Box.js";
import "@/vanilla/Stack.js";

import { useRouter } from "vue-router";

// import useRedirectUnauthorized from "@/composables/useRedirectUnauthorized";

import SubmitButton from "@/components/SubmitButton";

export default {
  name: "InterviewFinishedLanding",
  components: { SubmitButton },
  setup() {
    // useRedirectUnauthorized();
    const router = useRouter();
    const toHome = () => router.push({ name: "ForYou" });
    return { toHome };
  },
};
</script>

<style scoped>
.finish-insterview {
  padding-top: var(--dimension-height-header);
}
.container {
  padding-top: 1rem;
}

box-l {
  display: block;
  padding: var(--s1);
  border-width: var(--border-thin);
  /* ↓ For high contrast mode */
  outline: var(--border-thin) solid transparent;
  outline-offset: calc(var(--border-thin) * -1);
}

.form-illustrated {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  margin: 1rem 0;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
}
.form-box {
  padding: 1rem 2rem;
  background: white;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 13px/18px
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--color-gray-darken);
  border-radius: 4px 0 0 4px;
}
.form-box__interview {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.form-box h1 {
  font: var(--unnamed-font-style-normal) normal bold 22px/27px
    var(--unnamed-font-family-open-sans);
}
.form-box__interview form {
  align-self: end;
  margin-bottom: 1.2rem;
}
.illustration-box {
  border-radius: 0 4px 4px 0;
  background: var(--color-gray-light);
  position: relative;
}
.illustration-box img {
  background: transparent;
  height: 512px;
}
</style>
