const CUSTOMER_SUPPORT_REQUEST_TYPES = [
  { text: 'support.customer_request.question', value: 'question' },
  { text: 'support.customer_request.task', value: 'task' },
  { text: 'support.customer_request.incident', value: 'incident' },
  { text: 'support.customer_request.payment', value: 'question'},
  { text: 'support.customer_request.feedback', value: 'task' },
  { text: 'support.customer_request.request_new_feature', value: 'question'},
  { text: 'support.customer_request.cancel_plan', value: 'task' },
  { text: 'support.customer_request.contact_sales', value: 'question' }
]
const ZENDESK_CATEGORY = [
  { key: 'ho_bisogno_di_supporto', label: 'support.categories.ho_bisogno_di_supporto' },
  { key: 'voglio_segnalare_un_errore_bug', label: 'support.categories.voglio_segnalare_un_errore_bug' },
  { key: 'richiesta_di_nuove_funzionalita', label: 'support.categories.richiesta_di_nuove_funzionalita' },
  { key: 'informazioni_sui_piani_pagamenti_fatturazione', label: 'support.categories.informazioni_sui_piani_pagamenti_fatturazione' },
  { key: 'annullamento_piano', label: 'support.categories.annullamento_piano' },
  { key: 'contatta_un_commerciale', label: 'support.categories.contatta_un_commerciale' },
  { key: 'feedback_e_suggerimenti', label: 'support.categories.feedback_e_suggerimenti' },
  { key: 'domande', label: 'support.categories.domande' },
  { key: 'errori_tecnici', label: 'support.categories.errori_tecnici' },
  { key: 'attività_amministrative', label: 'support.categories.attività_amministrative' },
]

const url = "https://viblio.zendesk.com/api/v2/requests.json"
const sendSupportMessage = data => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ request: {
      created_at: new Date().toISOString(),
      requester: {
        name: [data.first_name, data.last_name].join(" "),
        email: data.email,
      },
      type: data.requestType,
      subject: 'Viblio Support',
      comment: { body: data.content },
        custom_fields: [
          {
              "id": 18016744393245,
              "value": data.category.key
          }
        ],
    } }),
  })
}


import {authorizedRequest, manageApiError} from "@/api/index";
import config from "@/config";

async function sendSupport(data) {
  try {
    const result = await authorizedRequest().post(config.BACKEND_URL+`/api/v2/send_support/`,{
      category: data.category,
      content: data.content,
    });
    return {error: false, result: result.data};
  } catch (error) {
    return manageApiError(error);
  }
}

export {
  CUSTOMER_SUPPORT_REQUEST_TYPES,
  ZENDESK_CATEGORY,
  sendSupport,
  sendSupportMessage
};
