<template>
  <div
    class="smart-background"
    :class="{ 'fallback' : loadFailed }"
    :style="loadFailed? `background-image: url(${placeHolder});` : `background-image: url(${src});`"
  >
    <slot />
  </div>
</template>
<script>
import { onMounted } from '@vue/runtime-core'
import { ref,watch } from 'vue'
export default {
  name: "SmartImageBackground",
  props: {
    src: {
      type: String,
      required: true
    },
    placeholder:
    {
      type: String,
      default: require(`@/assets/collection-placeholder.svg`)
    },
  },
  setup(props) {
    const loadFailed = ref(false);
    const placeHolder = props.placeholder;
    const checkImage = (path) =>{
      const img = new Image()
      img.src = path
      img.onerror = () => {
        loadFailed.value = true;
      }
    }
    onMounted(() => {
      checkImage(props.src)
    })
    watch(() => props.src, (value) => {
      loadFailed.value = false;
      checkImage(value)
    });
    return {
      loadFailed,
      placeHolder
    }
  },
}
</script>
<style scoped>

</style>
