import TrackedModel from "@/resources/TrackedModel";
import Subscription from "@/resources/Subscription";
import {useUserRoles} from "@/composables/userData";

export default class Plan extends TrackedModel {

  constructor(data) {
    super(data);

    const { userIsAdmin, userIsOrganizationAdmin, userOrganizationId } = useUserRoles();
    this.organization = data.organization || "";
    this.subscription = data.subscription || "";
    this.subscription_display = data.subscription_display || "";
    this.organization_name = data.organization_name || "";
    this.total_license = data.total_license || 0;
    this.used_license = data.used_license || 0;
    this.used_on_total_license = this.used_license.toString() + ' / ' + this.total_license.toString();
    this.start_date = data.start_date ? TrackedModel.asDate(data.start_date) : null;
    this.current_start_date = data.current_start_date ? TrackedModel.asDate(data.current_start_date) : this.start_date;
    this.expiration_date = data.expiration_date ? TrackedModel.asDate(data.expiration_date) : null;
    this.renewal_date = data.renewal_date ? TrackedModel.asDate(data.renewal_date) : null; // (this.expiration_date ? this.expiration_date.setDate(this.expiration_date.getDate() +1): null);
    this.renewalDateStr = data.expiration_date ? TrackedModel.asOutputDate(data.renewal_date, 'it') : '';
    this.stripe_subscription_id = data.stripe_subscription_id;
    this.stripe_price_id = data.stripe_price_id;
    this.billing_frequency = data.billing_frequency;
    this.trial_end_date = data.trial_end_date ? TrackedModel.asDate(data.trial_end_date) : null;
    this.payment_date = data.payment_date ? TrackedModel.asDate(data.payment_date) : null;
    this.payed = !!data.payment_date;
    this.users_subscriptions = data.users_subscriptions || [];

    const today = TrackedModel.asInputDate(new Date());
    this.is_expired = data.expiration_date < today;
    this.permissions.edit = userIsAdmin();
    this.permissions.delete = !this.used_license && userIsAdmin();
    this.permissions.setpayed = !this.payed && userIsAdmin();

    this.permissions.select = (this.total_license > this.used_license) && (!this.is_expired);
    this.permissions.manage = (userIsOrganizationAdmin() && userOrganizationId() === data.organization) || (userIsAdmin() && !data.stripe_subscription_id);
    this.permissions.renewal = (userIsAdmin() || userIsOrganizationAdmin()) && !this.stripe_subscription_id;
    this.disabled = this.is_expired;

    if (data.users_subscriptions) {
      this.users_subscriptions = data.users_subscriptions.map(sub =>
        Subscription.fromApiResult(sub));
    }

    return this;
  }

  static getValidListOptions(records, fieldName, defaultEmptyLabel, addEmptyItem=true){
    let forListBoxOption = []
    if (addEmptyItem) {
      forListBoxOption.push({key: 0, label: defaultEmptyLabel, subscription: 0});
    }
    forListBoxOption = forListBoxOption.concat(records.filter(plan => !plan.is_expired && plan.total_license > plan.used_license).map(record => {
      return record.convertForListBoxOption();
    }));

    return forListBoxOption;
  }



  convertForListBoxOption() {
    return {
      key: this.id,
      label: this.subscription_display +
        '( Dates ' +
        TrackedModel.asOutputDate(this.start_date, 'it') + ' - ' +
        TrackedModel.asOutputDate(this.expiration_date, 'it') +
        ' Used ' + this.used_license + '/' + this.total_license + ')',
      subscription: this.subscription
    };
  }

  static fromApiResult(planData) {
    const planObj = new Plan(planData);
    return planObj;
  }

  static forApi(recordObj) {
    let recordData = {};
    const dateFields = ['start_date', 'expiration_date', 'payment_date', 'trial_end_date', 'current_start_date', 'renewal_date'];
    Object.getOwnPropertyNames(recordObj).forEach(key => {
      if (dateFields.indexOf(key) > -1 && recordObj[key]) {
        recordData[key] = TrackedModel.asInputDate(recordObj[key]);
      } else {
        recordData[key] = recordObj[key];
      }
    });
    return recordData;
  }

  thisForApi() {
    return Plan.forApi(this);
  }

  static parseError(errorObject) {
    let errors = [];
    const keys = Object.keys(errorObject);
    if (Array.isArray(keys) && keys.length > 0) {
      errorObject.map((fieldError) => {
        let msg = Array.isArray(fieldError) ? Object.values(fieldError) : fieldError;
        errors.push(msg);
      });
    } else {
      errors.push("Operazione fallita");
    }
    return '<li>'+errors.join('</li><li>')+'</li>';
  }

  static mapPlans = {
    personal: 9,
    business: 12,
  }

}
