<template>
  <div class="flex flex-col md:flex-row justify-between gap-4">
    <div
      v-if="interviewFlow.text"
      :data-test="interviewFlow.dataTest"
      class="p-section--content-description md:max-w-[60%]"
    >
      {{ $t(interviewFlow.text) }}
      <br>
      {{ $t(interviewFlow.textDesc) }}
    </div>
    <div class="flex flex-col md:flex-row gap-4 flex-shrink-0">
      <Button
        v-if="interviewFlow.action"
        class="max-h-12 flex-shrink-0"
        severity="secondary"
        :icon="userInterviewState.isReady() ? 'uil-download-alt' : ''"
        :data-test="interviewFlow.ctaDataTest"
        :label="$t(interviewFlow.ctaLabel)"
        @click="interviewFlow.action"
      />
      <Button
        v-if="userInterviewState.isReady()"
        class="max-h-12 flex-shrink-0"
        :link="true"
        icon="uil-arrow-right"
        icon-pos="right"
        :label="$t('user_profile.interview.go_collection')"
        @click="openInterviewSystemCollection"
      />
    </div>
    <InterviewModal
      v-model="showDialog"
      :mode="mode"
    />
  </div>
</template>

<script setup>
import {defineEmits, ref} from "vue";
import {useRouter} from "vue-router";

  // name: "InterviewStatusSection"
import Button from "primevue/button";
import {getReport} from "@/api/user";
import {useInterview} from "@/composables/userData";
import InterviewModal from "@/views/InterviewModal.vue";

const router = useRouter();
const { userInterviewState } = useInterview();

const showDialog = ref(false);
const mode = ref('initialize');
const emit = defineEmits(['close-dialog'])

const startInterview = () => {
  showDialog.value = true;
};
const getReportApi = async () => {
  getReport().then((bytes) => {
    let blob = new Blob([bytes.data], { type: "application/pdf" });
    let url = window.URL || window.webkitURL;
    let l = url.createObjectURL(blob);

    var link = document.createElement("a");
    link.href = l;
    link.setAttribute("download", "report.pdf");
    link.click();
    link.remove();
  });
};

const interviewFlow = ref({
  dataTest: null,
  ctaDataTest: null,
  text: '',
  ctaLabel: '',
  action: null
})
if (userInterviewState.isCreated()) {
  interviewFlow.value = {
    dataTest: 'interview_started',
    ctaDataTest: 'go-to-interview',
    text: 'user_profile.interview.started',
    textDesc: 'user_profile.interview.started_2',
    ctaLabel: 'user_profile.interview.started_cta',
    action: startInterview
  }
  mode.value = 'continue'
} else if (userInterviewState.isProcessing()) {
  interviewFlow.value = {
    dataTest: 'interview_processing',
    text: 'user_profile.interview.processing',
    textDesc: 'user_profile.interview.processing_2',
  }
} else if (userInterviewState.isFailed()) {
  interviewFlow.value = {
    dataTest: 'interview_failed',
    text: 'user_profile.interview.failed',
    textDesc: 'user_profile.interview.failed_2',
    ctaDataTest: 'go-to-interview-after-error',
    ctaLabel: 'user_profile.interview.failed_cta',
    action: startInterview
  }
} else if (userInterviewState.isReady()) {
  interviewFlow.value = {
    dataTest: 'get-report',
    ctaDataTest: 'interview_completed',
    text: 'user_profile.interview.completed',
    textDesc: 'user_profile.interview.completed_2',
    action: getReportApi,
    ctaLabel: 'user_profile.interview.download_cta'
  }
} else {
  interviewFlow.value = {
    dataTest: 'start',
    ctaDataTest: 'start-interview',
    text: 'user_profile.interview.to_be_initialize',
    action: startInterview,
    ctaLabel: 'user_profile.interview.to_be_initialize_cta'
  }
  mode.value = 'initialize'
}

const openInterviewSystemCollection = () => {
  emit('close-dialog')
  router.push({ name: "collection-detail", params: { id: 'interview|interview', systemTitle: 'interview|interview' } });
}

</script>

<style scoped>

</style>
