export default class Course {
  constructor(data) {
    this.id = data.id || "NO_ID"; // new UUID()
    this._version = data._version || "--";

    this.deleted = data.deleted || false;

    this.title = data.title || "--";
    this.description = data.description || "--";
    this.longDescription = data.longDescription || "--";

    this.link = data.link || "javascript:;";
    this.type = data.type || "course";

    this.source = data.source || "unknown";
    this.level = data.level || "none";

    this.user_resource_status = data.user_resource_status;

    this.authors = [];
    this.tags = [];

    this.price = data.price || 0;
    this.skills = data.skills || [];

    this.currency = data.currency || "$";
    this.effort = {
      min: 0,
      max: 1,
      length: 1,
      period: "hours"
    };

    this.version = { major: 0, minor: 1 };
    const placeholderType = this.getPlaceHolderType();
    this.image = {
      src: require(`@/assets/${placeholderType}`),
      width: 512,
      height: 512
    };
    this.placeholder = {
      src: require(`@/assets/${placeholderType}`),
      width: 512,
      height: 512
    };

    this.setCreatedDate(data.created);
    this.setUpdatedDate(data.updated);
  }

  getPlaceHolderType(){
    if(this.type === "podcast"){
      return 'placeholder-podcast-kid.jpg';
    }

    if(this.source === 'udemy'){
      return 'course-udemy-placeholder.jpg';
    }

    return 'course-placeholder.svg';
  }

  setVersion(version) {
    this.version = version;
  }
  setCreatedDate(date) {
    this.created = Course.asDate(date);
  }
  setUpdatedDate(date) {
    this.updated = Course.asDate(date);
  }
  setEffort(effort) {
    this.effort = effort;
  }
  setImage(imageData) {
    if (imageData.src) {
      this.image = imageData;
    }
  }
  setAuthors(authors) {
    if (authors && authors.length) {
      this.authors = authors;
    }
  }
  setTags(tags) {
    if (tags && tags.length) {
      this.tags = tags;
    }
  }
  static asDate(date) {
    try {
      const asDate = new Date(date);
      return asDate;
    } catch (e) {
      console.warn("Could not set created date", date, e);
      return new Date(); // fallback: current date
    }
  }
  static fromApiResult(doc) {
    const mappedData = {
      id: doc.uuid,
      title: doc.title_txt_ap,
      description: doc.description_txt_ap,
      longDescription: doc.description_long_txt_ap,
      link: doc.link_s ? doc.link_s : "",
      type: doc.type_s,
      source: doc.source_s,
      level: doc.level_s,
      price: doc.price_i,
      skills: doc.skills,
      availability: doc.availability_s,
      created: doc.date_create_dt,
      updated: doc.date_update_dt,
      deleted: doc.deleted,
      _version: doc._version_
    };
    const course = new Course(mappedData);
    course.setVersion({
      major: doc["version.major_i"],
      minor: doc["version.minor_i"]
    });
    course.setImage({
      src: doc["image.src_s"],
      height: doc["image.height_i"],
      width: doc["image.width_s"]
    });
    course.setEffort({
      min: doc["effort.min_effort_i"],
      max: doc["effort.max_effort_i"],
      length: doc["effort.length_i"],
      period: doc["effort.length_um_s"]
    });
    course.setAuthors(doc["authors.name"]);
    course.setTags(doc.tags);

    return course;
  }

  static fromElasticApiResult(fulldoc){
    const doc = fulldoc._source;
    const mappedData = {
      id: fulldoc._id,
      title: doc.title,
      description: doc.short_description,
      longDescription: doc.description,
      link: doc.url ?? "",
      type: doc.content_type,
      source: doc.source,
      level: doc.level,
      price: doc.prices.length?doc.prices[doc.prices.length-1].price: 0,
      skills: doc.skills,
      availability: doc.active,
      currency:doc.currency??null,
      deleted: doc.deleted,
      // created: 'doc.date_create_dt',
      // updated: doc.date_update_dt,
      // _version: doc._version_
    };


    // console.log(mappedData);
    const course = new Course(mappedData);

    course.setImage({
      src: doc.image
    });
    course.setEffort({
      min: doc.min_effort_hours_per_week,
      max: doc.min_effort_hours_per_week,
      length: Math.floor(doc.duration_days/5),
      period: 'weeks'
    });
    // console.log(course);
    course.setTags(doc.tags);
    course.setAuthors(doc.staff.map(el => el.name));

    return course;
  }
  static fromBackendDoc(doc){
    const mappedData = {
      id: doc.uuid,
      title: doc.title,
      description: doc.short_description,
      longDescription: doc.description,
      link: doc.url ?? "",
      type: doc.content_type,
      source: doc.source,
      level: doc.level,
      price: doc.prices.length?doc.prices[doc.prices.length-1].price: 0,
      skills: doc.skills,
      availability: doc.active,
      currency:doc.currency??null,
      deleted: doc.deleted,
    };
    const course = new Course(mappedData);

    course.setImage({
      src: doc.image
    });
    course.setEffort({
      min: doc.min_effort_hours_per_week,
      max: doc.min_effort_hours_per_week,
      length: Math.floor(doc.duration_days/5),
      period: 'weeks'
    });
    // console.log(course);
    course.setTags(doc.tags);
    course.setAuthors(doc.staff.map(el => el.name));

    return course;
  }
}
