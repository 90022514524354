<template>
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <div class="text-xl font-extrabold">
        {{ name }}
      </div>
      <Button
        v-if="showViewAll"
        :label="$t('card.view_all')"
        severity="link"
        class="text-gray-900"
        icon="uil uil-arrow-right"
        icon-pos="right"
        @click="$router.push({ name: 'collection-detail', params: { id } })"
      />
    </div>
    <Carousel
      unstyled
      :num-visible="3"
      :num-scroll="3"
      :value="isLoading ? [1, 2, 3] : subData"
      :show-indicators="false"
      :responsive-options="responsiveOptions"
    >
      <template #item="slotProps">
        <SkeletonCard
          v-if="isLoading"
        />
        <Card
          v-else-if="slotProps.data"
          :course="slotProps.data"
          :only-create-mode="onlyCreateMode"
          @openDetails="() => $router.push(getDetailParams(slotProps.data))"
        />
        <div v-else /> <!-- Empty slot to hide empty space -->
      </template>
    </Carousel>
  </div>
</template>
<script setup>
import { ref, defineProps, computed, toRef } from 'vue';
import Card from '@/components/Card.vue'
import Button from 'primevue/button'
import SkeletonCard from '@/components/skeletons/SkeletonCard.vue'
import Carousel from 'primevue/carousel';
import sourceCollection from "@/lib/sourceCollection";

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  data: {
    type: Array,
    required: true
  },
  showViewAll: {
    type: Boolean,
    default: true
  },
  systemType: {
    type: String,
    default: ''
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  onlyCreateMode: { type: Boolean, default: false }
})

const getDetailParams = (data) => ({ name: 'detail', params: { id: data.id }, state: { click_source: sourceCollection(props.systemType) } })
const data = toRef(props, 'data')

const isMobile = computed(() => window.innerWidth < 768)

// Artificially add empty slots to make the carousel look good on desktop
const subData = computed(() => !isMobile.value && data.value && data.value.length < 3 ? [...data.value, ...Array(3 - data.value.length).fill(null)] : data.value)

const responsiveOptions = ref([
  {
    breakpoint: '1400px',
    numVisible: 3,
    numScroll: 3
  },
  {
    breakpoint: '1024px',
    numVisible: 2,
    numScroll: 2,
  },
  {
    breakpoint: '767px',
    numVisible: 1,
    numScroll: 1
  },
]);


</script>
