import { ref } from 'vue'
import { useUuidSearch } from '@/composables/useElasticCourseResults'
import { useUserData } from '@/composables/userData'

const { getUser } = useUserData();
const { getResources } = useUuidSearch();

export default function useCollectionItems(id, resourcesIds=[],limit=30, offset=0) {
  const data = ref([]);
  const isLoading = ref(true);
  const [type, name] = id.split('|');
  const currentUser = getUser();

  let params;

  const comparePrice = ( a, b ) => {
    if ( a.price < b.price ){
      return -1;
    }
    if ( a.price > b.price ){
      return 1;
    }
    return 0;
  }

  if (resourcesIds.length > 0) {
    params = {
      filters: {
        id: {
          key: 'ids',
          value: resourcesIds,
          matchMode: 'equals',
        },
      },
    };
  } else if (['occupation', 'interview', 'suggestion'].includes(type)) {
    params = {
      filters: {
        system_collection: {
          key: 'system_collection',
          value: currentUser.id + '|' + name,
          matchMode: 'equals',
        },
      },
    };
  } else if (type === 'skill' || ['eggup'].includes(type)) {
    params = {
      filters: {
        system_collection: {
          key: 'system_collection',
          value: id,
          matchMode: 'equals',
        },
      },
    };
  } else {
    params = {
      filters: {
        collection: {
          key: 'collection',
          value: id,
          matchMode: 'equals',
        },
      },
    };
  }
  const loadResources = (forceLoad) => {
    if (data.value.length === 0 || forceLoad) {
      getResources(limit, offset, params).then((response) => {
        if (!response.error) {
          if(['occupation', 'interview', 'skill', 'eggup'].includes(type)){
            data.value = response.results.sort(comparePrice);
          }
          else{
            data.value = response.results
          }
          isLoading.value = false;
        } else {
          isLoading.value = false;
          console.error('Error fetching collection items:', response.error);
        }
      });
    }
  };
  loadResources();
  const refreshResources = () => {
    loadResources(true);
  };

  return {
    data,
    isLoading,
    refreshResources
  };
}
