import {getResourcesLanguages, searchElastic, list, retrieve} from "@/api/courses.js";
import { ref, reactive } from "vue";
import Course from "@/resources/Course.js";
import Resource from "@/resources/Resource.js";
import { ErrorBus, ErrorCommands } from "@/eventBus";
import {responseApi} from "@/api";
import { useUserData } from "./userData";
// import { useUserData } from "@/composables/userData.js";

// TODO: store in LocalStorage or SessionStorage
const resources = ref([]);
const totalItems = ref(0);
const searched = ref("");
const searchTerm = ref("");
const lang = ref("")
const filterMap = reactive({
    effort: ref(""),
    language: ref(""),
    price: ref(""),
    type: ref(""),
    level: ref(""),
    provider: ref(""),
    tags: ref([])
});
const searching = ref(false);
const start = ref(0);
const rowsPerPage = ref(10);
const searchErrorMessage = ref("");

const languagesList = ref([]);
const loadLanguagesList = () => {

  getResourcesLanguages().then((response) => {
    languagesList.value = response.results;
  });
}
const minQueryLength = 3;

/*
const trans = [
    // { value: "any", label: "qualunque" },

    { value: "effort", label: "Durata" },
    { value: `more:${60 * 24 * 30}|less:${60 * 24 * 30 * 3}`, label: "more_1_months__less_3_months" },
    { value: `more:${60 * 24 * 7}|less:${60 * 24 * 7}`, label: "more_1_weeks__less_4_weeks" },
    { value: `less:${60 * 2}`, label: "less_2_hours" },
    { value: `more:${60 * 24 * 30 * 3}`, label: "more_3_months" },

    { value: "type", label: "type" },
    { value: "webinar", label: "webinar" },
    { value: "podcast", label: "podcast" },
    { value: "course", label: "course" },
    { value: "video", label: "video" },
    { value: "youtube playlist", label: "youtube_playlist" },
    { value: "codecademy playlist", label: "codecademy_playlist" },

    { value: "level", label: "level" },
    { value: "Beginner", label: "Beginner" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Advanced", label: "Advanced" },
    { value: "exclude_others", label: "exclude_others" },

    { value: "price", label: "Costo" },
    { value: "0:0", label: "gratuito" },
    { value: "5:*", label: "a_pagamento" },
    { value: "free", label: "free" },
    { value: "pay", label: "pay" }
];*/


const typeFilterOptions = [
    { value: "video", label: "course_results.type_values.video" },
    { value: "podcast", label: "course_results.type_values.podcast" },
    { value: "document", label: "course_results.type_values.document" },
    { value: "webinar", label: "course_results.type_values.webinar" },
    { value: "course", label: "course_results.type_values.course" },
    { value: "youtube playlist", label: "course_results.type_values.youtube_playlist" },
    { value: "codecademy playlist", label: "course_results.type_values.codecademy_playlist" }
];

const durationFilterOptions = [
  // TODO 24 ore? non consideriamo 8 ore al giorno?
    { value: "", label: "course_results.effort" },
    {
        value: `more:${60 * 24 * 30}|less:${60 * 24 * 30 * 3}`,
        label: "course_results.effort_values.more:1:months|less:3:months"
    },
    { value: `more:${60 * 24 * 7}|less:${60 * 24 * 7}`, label: "course_results.effort_values.more_1_weeks__less_4_weeks" },
    { value: `less:${60 * 2}`, label: "course_results.effort_values.less_2_hours" },
    {
        value: `more:${60 * 24 * 30 * 3}`,
        label: "course_results.effort_values.more_3_months"
    }
];

const levelFilterOptions = [
    { value: "Beginner", label: "course_results.level_values.Beginner" },
    { value: "Intermediate", label: "course_results.level_values.Intermediate" },
    { value: "Advanced", label: "course_results.level_values.Advanced" }
];

const priceSortOptions = [
    // { value: "", label: "course_results.price_values.all" },
    { value: "free", label: "course_results.price_values.free"},
    { value: "pay", label: "course_results.price_values.pay" },
    { value: "included", label: "course_results.price_values.included" }
];

// map source abbreviation to logo file name

// function compileTagsFilter() {
//     if (filterMap.tags.length) {
//         return `tags:(${filterMap.tags.join(" OR ")})`;
//     }
//     return null;
// }

/**
 *
 * @param {string | string[]} uuids
 */
// function compileUuidSearch(uuids) {
//   return {
//     field: 'uuid',
//     value: uuids
//   };
// }

function resetStart() {
    start.value = 0;
}

// TODO: cache result on client
function useUuidSearch() {
    const loading = ref(false);
    //const { token } = useUserData();
    const getResources = async (limit, offset, params) => {
      let results= await list(limit, offset, params);
      return responseApi(Resource, results, false, true);
    };

    return { getResources, loading };
}

function useCourseResults() {
    // const { token } = useUserData();
    const getCourseById = async id => {
        if (id === "test") {
            return new Course({});
        }
        // look for local cache from previous search
        // We remove skills from search, every time we open a detail, we need the detail api
        // const result = resources.value.filter(c => c.id === id);
        // if (result.length) {
        //     return result[0];
        // }
        // no cache, load from backend
        const searchResult = await retrieve(id);

        if (searchResult.error) {
            ErrorBus.emit(ErrorCommands.ERROR, { message: searchResult.error });
        }
        //console.log(Object.prototype.hasOwnProperty.call(searchResult.result, "id"))
        if (Object.prototype.hasOwnProperty.call(searchResult.result, "id")) {
            //console.log(searchResult.result)
            return searchResult.result;
        }
        // not found
        throw new Error("No course found with id " + id);
    };

    const resetFilters = () => {
        filterMap.effort = "";
        filterMap.language = "";
        filterMap.price = "";
        filterMap.type = "";
        filterMap.level = "";
        filterMap.tags = [];
        filterMap.provider = "";
        start.value = 0;
    };

    const updateFilter = (field, value) => {
        if (!(field in filterMap)) {
            throw new Error("Unknown Filter field:" + field);
        }
        if (field === "tags" && !Array.isArray(value)) {
            throw new TypeError("Tags must be an Array got " + value);
        }
        if (filterMap[field] === value) {
            return; /* do nothing */
        }
        filterMap[field] = value;
        // important side-effect: reset start whenever a filter changes
        resetStart();
    };

    const getFilter = field => {
        if (!(field in filterMap)) {
            throw new Error("Unknown Filter field:" + field);
        }
        return filterMap[field];
    };

    const newSearchQuery = () => {
        return {q: searchTerm.value, f:btoa(JSON.stringify(filterMap))};
    }
    const runSearchFromQuery = async (q, f, p = 1, r = 10) => {
      start.value = (p-1) * r;
      searchTerm.value = decodeURIComponent(q);
      if(f){
        Object.assign(filterMap,JSON.parse(atob(f)));
      }
      searchCourses();
    }
    const searchCourses = async function() {
        if(searchTerm.value.length < minQueryLength) return;
        // const { token } = useUserData();
        // reset
        const { getUser } = useUserData()
        const user = await getUser()
        lang.value = user.lang
        resources.value = [];
        totalItems.value = 0;
        searched.value = "";
        searchErrorMessage.value = "";
        searching.value = true;
        // search
        let newFilters = {}
        Object.entries(filterMap).forEach(([key, value]) => {
          if (value) {
            newFilters[key] = ({key: key, value: value})
          }
        })
        newFilters['q'] = {key: 'q', value: searchTerm.value}
        newFilters['lang'] = {key: 'lang', value:lang.value}
        const result = await searchElastic(newFilters, start.value);
        if (result.error) {
            ErrorBus.emit(ErrorCommands.ERROR, { message: result.error });
        }
        searching.value = false;

        // set new values
        searched.value = searchTerm.value;
        searchErrorMessage.value = result.error || "";
        totalItems.value = result.count;
        resources.value = result.results;
        // console.debug(result.resources.map(r => r.id))
        // start.value = result.start;
        // rowsPerPage.value = result.rows;
    };

    return {
        filterMap,
        languagesList,
        getCourseById,
        getFilter,
        minQueryLength,
        newSearchQuery,
        resetFilters,
        resources,
        rowsPerPage,
        runSearchFromQuery,
        searchCourses,
        searched,
        searchErrorMessage,
        searching,
        searchTerm,
        start,
        totalItems,
        updateFilter
    };
}

export {
    loadLanguagesList,
    useCourseResults,
    useUuidSearch,
    typeFilterOptions,
    durationFilterOptions,
    levelFilterOptions,
    priceSortOptions,
    minQueryLength
};
