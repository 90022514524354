import axios from "axios";
import config from "../config.js";
//import { useUserData } from "@/composables/userData.js";
//import { getKCToken } from '../composables/useSSO';
import { parseError } from "@/api/user";
import {ErrorBus, ErrorCommands} from "@/eventBus";
import TrackedModel from "@/resources/TrackedModel";
import { useAuthStore } from '../stores';

const { BACKEND_URL, REQ_TIMEOUT } = config;

const ENDPOINTS = {
    RESOURCE_ACCESS_REDIRECT: "/resource-redirect/{uuid}"
}

const defaults = {
    baseURL: BACKEND_URL,
    timeout: REQ_TIMEOUT,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
};

function authorizedRequest(isDownload = false) {
    const authStore = useAuthStore();
    const token = authStore.getKCToken();
    if (!token) {
        throw new Error("No token");
    }
    if (isDownload === true) {
        Object.assign(defaults, { responseType: "blob" });
    }
    // console.log("api using token:", token.value)

    // this allows signup to succeed when not using a reverse proxy
    if (process.env.NODE_ENV === "development") {
       // Object.assign(defaults.headers, { "X-Forwarded-For": "127.0.0.1" });
    }
    const headers = Object.assign({ Authorization: `Bearer ${token}` },
        defaults.headers
    );
    // console.log({headers});
    const withToken = Object.assign({}, defaults, { headers });
    // console.log({withToken});
    const authReq = axios.create(withToken);
    // console.log({authReq});

    authReq.interceptors.response.use(function(response) {
        return response;
    }, function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        //COMMENTED FORCED LOGOUT STATUS CAUSE IT'S A NOSENSE
        // if (!error.response || error.response.status == 401) {
        //     const { logoutUser } = useUserData();
        //     logoutUser();
        // }
        return Promise.reject(error);
    });
    return authReq;
    // correctly interpreting the data from the server
    // https://github.com/axios/axios#request-config
}

function manageApiError(error){
    console.error("Got: " + error);
    let errors = ["Errore generico"];
    if (error.response) {
        errors = parseError(error.response.data);
    }
    return {error: errors, result: null};
}


function responseApi(modelClass, results, detail, drf) {
  if(!results){
    ErrorBus.emit(ErrorCommands.ERROR, { message: 'errors.general' });
    return {error: 'errors.general'};
  }
  if(results.error){
    // ErrorBus.emit(ErrorCommands.ERROR, { message: results.error });
    return {error: results.error};
  }
  const data = drf && !detail ? results.result.results : results.result;
  if (detail) {
    return modelClass.fromApiResult(data);
  } else {
    const res = data && data.map(rec => {
      return modelClass.fromApiResult(rec);
    });

    return drf ? {
      count: results.result.count,
      next: results.result.next,
      results: res || data
    } : res || data;
  }
}

function request(token) {
    // unauthorized request
    if (!token) {
        return axios.create(defaults);
    }
    return authorizedRequest(token);
}



function transformFilters(filters) {
    if (!filters) {
        filters = [];
    }
    let arrayFilters = [];
    if(!Array.isArray(filters)){
      Object.entries(filters).forEach(([key, filter]) => {
        if (typeof filter.value !== 'undefined' && filter.value !== null ) {
          arrayFilters.push({
            field: (filter.key || key) + (
              filter.matchMode && filter.matchMode !== 'equals' ?
              '__' + (filter.type ==='string' ? 'i': '') + filter.matchMode.toLowerCase()
              : ''),
            value: filter.value
          });
        }
      });
      filters = arrayFilters;
    }
    let filtersFormatted =  filters.map((filter) => {
        const formatted = filter.type !== 'date' ? filter.value : TrackedModel.asInputDate(filter.value);
        return filter.field + '=' + formatted;
    }).join('&');
    if (filtersFormatted) {
        filtersFormatted = '?'+filtersFormatted;
    }
    return filtersFormatted;
}

async function apiHealth() {
    const res = await request().get('/');
    return res.status === 200;
}

export {
  request,
  authorizedRequest,
  responseApi,
  manageApiError,
  transformFilters,
  BACKEND_URL,
  apiHealth,
  ENDPOINTS
};
