<template>
  <div
    v-if="offline"
    class="offline-warning"
    data-test="offline-warning"
  >
    {{ $t('errors.offline') }} <span
      class="retry"
      data-test="offline-check"
      @click="recheck"
    >{{ $t('errors.retry') }}.</span>
  </div>
  <div v-else-if="apiDown">
    {{ $t('errors.api_down') }} <span
      class="retry"
      @click="recheck"
    >{{ $t('errors.retry') }}</span>
  </div>
</template>
<script>
import { ref } from 'vue'
import { ErrorBus, ErrorCommands } from '@/eventBus'
import { useToasts } from '@/composables/useToasts'
import { apiHealth } from '@/api'
const lastUpdate =  ref(0);
const msgQueue = ref([])
export default {
  name: 'ErrorManager',
  setup() {
    const { toastError } = useToasts()
    const queueSize = 5
    const queueLife = 30 //seconds
    const offline = ref(false)
    const apiDown = ref(false)

    const addToQueue = msg => {
      msgQueue.value.push(msg)
      if (msgQueue.length > queueSize) {
        msgQueue.value.shift()
      }
    }
    const checkLastUpdate = () => {
      if (Date.now() - lastUpdate > queueLife * 1000) {
        msgQueue.value = []
      }
    }

    const checkConnection = () => {
      offline.value = !window.navigator.onLine
    }
    const recheck = async () => {
      try {
        await apiHealth()
      } catch (e) {
        return;
      }
      checkConnection()
      if(!offline.value){
        window.location.reload();
      }
      // apiDown.value = !ApiOk;
    }
    const updateLastUpdate = () => {
       lastUpdate.value = Date.now()
    }
    ErrorBus.on(ErrorCommands.ERROR, data => {
      let msg = data.message ?? 'errors.general';
      if( typeof msg === 'object' && msg.message){ //axios errors
        msg = msg.message;
      }
      checkConnection()
      if (offline.value) {
        return
      }
      checkLastUpdate()
      if (msgQueue.value.lastIndexOf(msg) == -1) {
        addToQueue(msg)
        toastError('Error', msg)
        updateLastUpdate();
      }
    })
    return {
      offline,
      apiDown,
      recheck
    }
  }
}
</script>
<style scoped>
.offline-warning{
  position: fixed;
  bottom:0;
  left: 0;
  width:100%;
  color: white;
  background-color: red;
  padding:10px;
}
.retry{
  font-weight: bold;
  color: white;
  cursor: pointer;
}
</style>
