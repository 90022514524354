<template>
  <div
    class="inline-flex p-1 justify-center items-center gap-2 flex-shrink-0 rounded-full border border-gray-200 bg-white"
    :class="{
      'w-8 h-8': size === 'small',
      'w-10 h-10': size === 'normal',
      'w-12 h-12': size === 'big'
    }"
  >
    <img
      v-if="provider"
      :src="logoImg"
    >
    <i
      v-else
      class="fas fa-university"
    />
  </div>
</template>
<script setup>
import { computed, defineProps } from 'vue';
import useCourseProviders from "@/composables/useCourseProviders";
const { getProvider } = useCourseProviders()

const props = defineProps({
    provider: {
        type: String,
        default: ''
    },
    size: {
        type: String,
        default: 'normal'
    },
});
// const sourceLogo = computed(() => {
//   if (course.value?.provider in logoMap) {
//     return `source-logo__image source-logo__${course.value?.provider}`;
//   }
//   return "source-logo__default fas fa-university";
// });

const logoImg = computed(() => {
  return process.env.BASE_URL + 'providers/' + getProvider(props.provider)['logo'];
});

</script>
