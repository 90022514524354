<template>
  <div class="flex gap-6 flex-col">
    <div class="profile-section">
      <div class="profile-section--content">
        <div class="text-xl font-extrabold text-gray-900">
          {{ $t('user_profile.intro_section_title') }}
        </div>
        <div class="p-section--label">
          {{ $t('user_profile.profile') }}
        </div>
        <div class="roles-and-skills">
          <div class="user-onboarded-info">
            <div class="font-bold">
              {{ $t('users.email') }}:
            </div>
            <div class="title">
              {{ currentUser.email }}
            </div>
          </div>
          <RolesAndSkills
            :job-names="[currentUser.occupation_1_name, currentUser.occupation_2_name]"
            :skill-names="currentUser.skill_names"
          />
        </div>
      </div>
    </div>
    <!-- Interview -->
    <div class="profile-section">
      <div class="p-section--label">
        {{ $t('user_profile.interview.title') }}
      </div>
      <InterviewStatusSection />
    </div>
    <!-- EggUp -->
    <div
      v-if="currentUser && currentUser.addon_catalogs && currentUser.addon_catalogs.indexOf('eggup') > -1"
      class="profile-section"
    >
      <div class="p-section--label">
        {{ $t('user_profile.egg_up.title') }}
      </div>
      <EggUpStatusSection @close-dialog="close" />
    </div>
    <!-- Profile -->
    <hr class="line-divider">
    <div class="profile-section">
      <div class="text-xl font-extrabold text-gray-900">
        {{ $t('user_profile.profile_section_title') }}
      </div>
      <div class="p-section--label">
        {{ $t('user_profile.my_data') }}
      </div>
      <div class="">
        <form
          data-test="update-profile"
          @submit.prevent="updateProfileView"
        >
          <div
            v-if="erroredProfile"
            class="profile-form-error"
          >
            <p
              v-for="err in updateProfileError"
              :key="err"
            >
              {{ err }}
            </p>
          </div>
          <div class="flex flex-col gap-6 md:flex-row items-end">
            <InputWrapper
              name="name"
              type="text"
              label="users.first_name"
              :required="true"
              :field-error="!newName && showError"
            >
              <InputText
                v-model.trim="newName"
                name="name"
                :invalid="!newName && showError"
                min-value="1"
              />
            </InputWrapper>
            <InputWrapper
              name="name"
              type="text"
              label="users.last_name"
              :required="true"
              :field-error="!newSurname && showError"
            >
              <InputText
                v-model.trim="newSurname"
                name="name"
                :invalid="!newSurname && showError"
                min-value="1"
              />
            </InputWrapper>
            <InputWrapper
              name="lang"
              label="general.language"
            >
              <Dropdown
                v-model="newLang"
                :options="LOCALES"
                option-label="label"
                option-value="key"
              />
            </InputWrapper>
            <div
              v-tooltip.left="!profileChanged ? $t('user_profile.wait_save_changes') : ''"
              class="flex-shrink-0"
            >
              <Button
                :label="$t('user_profile.save_changes')"
                severity="secondary"
                class="h-12"
                :disabled="!profileChanged"
                :loading="profileSaving"
                @click="updateProfileView"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Account -->
    <div class="profile-section">
      <div class="profile-section--content">
        <!-- Change Password -->
        <div class="p-section--label">
          {{ $t('user_profile.change_password_title') }}
        </div>
        <div class="flex flex-col md:flex-row justify-between">
          <p class="p-section--content-description md:max-w-[60%]">
            {{ $t('user_profile.change_password_description') }}
          </p>
          <Button
            :label="$t('user_profile.change_password')"
            severity="secondary"
            @click="changePass"
          />
        </div>
        <!-- Logout -->
        <div class="p-section">
          <div class="p-section--label">
            {{ $t('user_profile.logout_cta') }}
          </div>
          <div class="p-section--content">
            <a @click="logout"> {{ $t('user_profile.logout_cta') }} </a>
          </div>
        </div>

        <!-- Notification -->
        <Alert
          v-if="errorResponse"
          :title="$t('errors.general_title')"
          type="error"
        >
          <template #content>
            <p>
              {{ $t('errors.general_msg') }}
            </p>
          </template>
        </Alert>
        <div class="p-section--label">
          {{ $t('user_profile.notification_title') }}
        </div>
        <div class="">
          <InputWrapper
            type="switch"
            name="email_notification"
            label="user_profile.email_notification"
            label-position="right"
          >
            <InputSwitch
              v-model="newEmailNotification"
              @change="changeUserNotificationStatus"
            />
          </InputWrapper>
          <div class="flex items-start justify-between">
            <p class="text-sm">
              {{ $t('user_profile.notification_description') }}
            </p>
            <div
              v-tooltip.left="!isNotificationChanged ? $t('user_profile.wait_save_changes') : ''"
              class="flex-shrink-0"
            >
              <Button
                :label="$t('user_profile.save_changes')"
                severity="secondary"
                class="h-12"
                :disabled="!isNotificationChanged"
                @click="saveChangeUserNotificationStatus"
              />
            </div>
          </div>
        </div>
        <!-- Data Agreement -->
        <div class="p-section--label">
          {{ $t('user_profile.agreement_title') }}
        </div>
        <div class="">
          <InputWrapper
            type="switch"
            name="agreement"
            label="user_profile.agreement"
            label-position="right"
          >
            <InputSwitch
              v-model="dataAgreement"
              @change="changeUserAgreementStatus"
            />
          </InputWrapper>
          <div class="flex items-start justify-between">
            <p class="text-sm">
              {{ $t('user_profile.agreement_description') }}
            </p>
            <div
              v-tooltip.left="!isAgreementChanged ? $t('user_profile.wait_save_changes') : ''"
              class="flex-shrink-0"
            >
              <Button
                :label="$t('user_profile.save_changes')"
                severity="secondary"
                class="h-12"
                :disabled="!isAgreementChanged"
                @click="saveChangeUserAgreementStatus"
              />
            </div>
          </div>
        </div>
        <!-- Policies -->
        <div class="p-section--label">
          {{ $t('user_profile.policies_title') }}
        </div>
        <ul class="p-section--content">
          <li>
            <a
              target="_blank"
              :href="locale === 'it' ? 'https://www.viblio.com/termini-e-condizioni/' : 'https://www.viblio.com/en/terms-and-conditions/'"
            >
              {{ $t('user_profile.policies_term_link') }}
            </a>
          </li>
          <li>
            <a
              target="_blank"
              :href="locale === 'it' ? 'https://www.viblio.com/privacy-policy/' : 'https://www.viblio.com/en/privacy-policy-en/'"
            >
              {{ $t('user_profile.policies_privacy_link') }}
            </a>
          </li>
          <li>
            <a
              target="_blank"
              :href="locale === 'it' ? 'https://www.viblio.com/cookie-policy/' : 'https://www.viblio.com/en/cookie-policy-en/'"
            >
              {{ $t('user_profile.policies_cookie_link') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Delete account -->
    <div class="p-section">
      <div class="p-section--label">
        {{ $t('user_profile.delete_account') }}
      </div>
      <div class="p-section--content">
        <p class="p-section--content-description">
          {{ $t('user_profile.delete_account_description') }}
          <a
            :href="`mailto:${APP_EMAIL}`"
            target="_blank"
          >
            {{ APP_EMAIL }}
          </a>
          {{ $t('user_profile.delete_account_instructions') }}
          <br>
          {{ $t('user_profile.delete_account_instructions_2') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, watchEffect, watch, defineEmits} from "vue";
import Config from "../config.js"
import { useToasts } from "@/composables/useToasts";

import {
  updateProfileError,
} from "@/api/user.js";
import { useUserData, LOCALES } from "@/composables/userData.js";
import useRedirectUnauthorized from "@/composables/useRedirectUnauthorized";

import RolesAndSkills from "@/components/common/RolesAndSkills.vue";
import { useI18n } from 'vue-i18n'

import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import InputWrapper from "@/components/common/InputWrapper.vue";
import InputText from "primevue/inputtext";
import Alert from "@/components/Alert.vue";
import InterviewStatusSection from "@/components/InterviewStatusSection.vue";
import EggUpStatusSection from "@/components/EggUpStatusSection.vue";

useRedirectUnauthorized();
const { getUser, logoutUser, patchUserData, requestPasswordChange, callSwitchEmailNotification, callSetAgreement } = useUserData();
const currentUser = getUser()
const { APP_EMAIL } = Config;
const erroredProfile = computed(() => updateProfileError.value !== "");
const { toastSuccess } = useToasts();
const newName = ref("");
const newSurname = ref("");

// const matchingOptions = LOCALES.filter(
//   (o) => o.key === currentUser.lang
// );
// const newLang = ref(matchingOptions[0]);
const newLang = ref(currentUser.lang);
const uName = ref(currentUser.first_name);
const uSurname = ref(currentUser.last_name);
const uLang = ref(currentUser.lang);
const newEmailNotification = ref(currentUser.email_notification);
const dataAgreement = ref(currentUser.privacy_policy)
const profileChanged = computed(
  () =>
    (uName.value !== newName.value) ||
    (uSurname.value !== newSurname.value) ||
    (uLang.value !== newLang.value)
);
watchEffect(() => {
  newName.value = uName.value;
  newSurname.value = uSurname.value;
  newLang.value = uLang.value;
  // newLang.key = uLang.value;

});
const showError = ref(false);

const profileSaving = ref(false);
const emit = defineEmits(['close-dialog'])

const close = () => {
  emit('close-dialog')
}
const logout = async () => {
  await logoutUser();
};
const changePass = () => {
  requestPasswordChange();
};
const { locale } = useI18n()

const errorResponse = ref(false)

const updateProfileView = async () => {
  if (!newName.value || !newSurname.value) {
    showError.value = true;
    return;
  }
  profileSaving.value = true;
  // const res = await updateUserProfile(newName.value, newSurname.value);
  const patchData = {
    first_name: newName.value,
    last_name: newSurname.value,
    lang: newLang.value
  }
  patchUserData(patchData).then((res) => {
    toastSuccess("user_profile.update_profile_success");
    profileSaving.value = false;
    locale.value = res.lang
    emit('close-dialog')
  }).catch((res) => {
    profileSaving.value = false;
    // errorResponse.value = res // it use updateProfileError
    console.warn('Catch: ', res)
  })
  // else, `updateprofileError` will contain the error details
};

const isNotificationChanged = ref(false);

const changeUserNotificationStatus = () => {
  isNotificationChanged.value = true;
}
const saveChangeUserNotificationStatus = async () => {
  callSwitchEmailNotification(currentUser.id).then((res) => {
    if (!res.error) {
      toastSuccess("user_profile.update_notification_success");
      currentUser.email_notification = newEmailNotification.value
      emit('close-dialog')
    } else {
      currentUser.email_notification = !currentUser.email_notification
      errorResponse.value = true
    }
  })
}

const isAgreementChanged = ref(false);
const changeUserAgreementStatus = () => {
  isAgreementChanged.value = true;

}
const saveChangeUserAgreementStatus = async () => {
  callSetAgreement(currentUser.id, dataAgreement.value).then((res) => {
    if (!res.error) {
      toastSuccess("user_profile.update_notification_success");
      currentUser.privacy_policy = dataAgreement.value
      emit('close-dialog')
    } else {
      currentUser.privacy_policy = !currentUser.privacy_policy
      errorResponse.value = true
    }
  })
}

watch(newEmailNotification, changeUserNotificationStatus);
watch(dataAgreement, changeUserAgreementStatus);

</script>
