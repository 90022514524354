import { ref, watchEffect, toValue } from 'vue'
import { useUserData } from '@/composables/userData'
import { searchElastic } from '@/api/courses.js'

const { getUser } = useUserData()

export default function useCourseSearch(query, offset, filters) {
  const data = ref([])
  const totalItems = ref(0)
  const isLoading = ref(false)
  const user = getUser()

  watchEffect(async () => {
    const value = toValue(query);
    if(!value || value.length < 3){
        return
    }
    data.value = []
    isLoading.value = true
    totalItems.value = 0

    const filterMap = toValue(filters)
    const newFilters = {}

    Object.entries(filterMap).forEach(([key, value]) => {
      let new_value = value
      if (new_value) {
        if(Array.isArray(value)){
          new_value = value.map((item) => item.key)
        }
        newFilters[key] = ({key: key, value: new_value})
      }
    })

    newFilters['q'] = { key: 'q', value }
    newFilters['lang'] = { key: 'lang', value: user.lang }

    const result = await searchElastic(newFilters, toValue(offset))
    if (result.error) {
      console.error('Error fetching collection items:', result.error)
    } else {
      data.value = result.results
      totalItems.value = result.count
    }
    isLoading.value = false
  })

  return {
    data,
    isLoading,
    totalItems,
  }
}
