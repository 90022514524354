<template>
  <div
    v-if="!isLoading && image"
    class="w-[216px] h-[216px] md:w-[248px] md:h-[248px] relative group cursor-pointer shrink-0"
    tabindex="0"
  >
    <div class="smoke-screen rounded-2xl w-full h-full absolute z-10" />
    <SmartImageBackground
      class="absolute w-[216px] h-[216px] md:w-[248px] md:h-[248px] transition ease-in-out duration-500 group-hover:shadow-wide group-hover:brightness-75 focus:outline-blue-500 focus:outline-2 cursor-pointer rounded-2xl overflow-hidden self-stretch grow shrink basis-0 p-4 bg-cover bg-center bg-no-repeat"
      :src="image"
    />
    <div class="flex flex-col h-full relative p-4 z-20">
      <div class="flex justify-between">
        <div
          v-if="newContent"
          class="px-2 rounded-full bg-green-600 flex justify-center items-center text-xs text-white font-bold"
        >
          {{ $t('card.new_content') }}
        </div>
      </div>
      <div class="flex flex-col mt-auto">
        <div class="text-2xl font-extrabold text-white line-clamp-2">
          {{ title }}
        </div>
        <div class="text-white truncate">
          {{ description }}
        </div>
      </div>
    </div>
  </div>
  <SkeletonCard
    v-else-if="isLoading"
  />
</template>

<script setup>
import { defineProps,ref, watch } from 'vue';
import SmartImageBackground from '@/components/common/SmartImageBackground.vue';
import SkeletonCard from './SkeletonCard.vue';

const props = defineProps({
  newContent: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: 'Collection',
  },
  description: {
    type: String,
    default: 'Lorem ipsum dolor sit amet',
  },
  images:{
    type: Array,
    default: () => []
  },
  isLoading: {
    type:Boolean,
    default: false
  }
});

const image = ref('')

const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true,url);
    } else {
      img.onload = () => {
        callback(true,url);
      };

      img.onerror = () => {
        callback(false,url);
      };
    }
  }


watch(() => props.images, (newVal) => {
  for (let i = 0; i < newVal.length; i++) {
    if (!image.value) {
      checkIfImageExists(newVal[i].image, (exists, url) => {
        if (exists && !image.value) {
          image.value = url;
        }
      });
    }
  }
});
</script>

<style scoped>
.smoke-screen {
  background: linear-gradient(180deg, rgba(18, 21, 32, 0.00) 0%, rgba(18, 21, 32, 0.70) 100%);
}
</style>
