<template>
  <div
    class="fixed w-[300px] h-full px-4 py-6 bg-white border-r [border-right-style:solid] border-gray-200 flex-col justify-between flex z-10"
  >
    <div
      class="self-stretch md:h-[calc(100%-100px)] flex-col justify-start items-start gap-8 flex"
      :class="{
        'h-[calc(100%-250px)]': currentUser.addon_catalogs && currentUser.addon_catalogs.indexOf('career_coach') > -1
      }"
    >
      <OrganizationItem v-if="isAuthenticated" />
      <div
        class="self-stretch flex-col justify-start items-start gap-1 flex overflow-auto"
      >
        <SideBarMenuItem
          :label="$t('sidebar.for_you')"
          icon="uil-bookmark"
          :active="currentRoute.name === 'ForYou'"
          @click="navigate({ name: 'ForYou' })"
        />
        <SideBarMenuItem
          :label="$t('sidebar.collections')"
          icon="uil-books"
          expandable
          :subitems="[
            {
              label: $t('sidebar.collections_personal'),
              active:
                currentRoute.name === 'collections-list' &&
                currentRoute.params?.collectionType === 'personal',
              click: () =>
                navigate({
                  name: 'collections-list',
                  params: { collectionType: 'personal' }
                })
            },
            {
              label: $t('sidebar.collections_shared'),
              active:
                currentRoute.name === 'collections-list' &&
                currentRoute.params?.collectionType === 'shared_with_me',
              click: () =>
                navigate({
                  name: 'collections-list',
                  params: { collectionType: 'shared_with_me' }
                })
            },
            {
              label: $t('sidebar.collections_company'),
              active:
                currentRoute.name === 'collections-list' &&
                currentRoute.params?.collectionType === 'company',
              click: () =>
                navigate({
                  name: 'collections-list',
                  params: { collectionType: 'company' }
                })
            },
          ]"
        />
        <SideBarMenuItem
          :label="$t('sidebar.explore')"
          icon="uil-search"
          :active="['search', 'search-results'].includes(currentRoute.name)"
          @click="navigate({name: 'search'})"
        />
        <SideBarMenuItem
          desktop-only
          :label="$t('sidebar.analysis')"
          icon="uil-graph-bar"
          :active="currentRoute.name === 'dashboard'"
          @click="navigate({ name: 'dashboard' })"
        />
        <SideBarMenuItem
          v-if="!userIsMember()"
          :label="$t('sidebar.manage')"
          icon="uil-cog"
          expandable
          :subitems="organizationSubitems"
        />
      </div>
    </div>
    <!-- FOOTER -->
    <div class="flex-col justify-start items-start gap-4 flex bg-white h-max shrink-0">
      <Alert
        v-if="
          isAuthenticated &&
            userIsAnyAdmin() &&
            currentUser.organization_info.pay_in_app &&
            !currentUser.organization_info.payment_attached_date &&
            !isMobile
        "
        :title="
          $t('sidebar.missing_payment_title', { count: planData.totalDays })
        "
        type="info"
      >
        <template #content>
          <p class="text-xs">
            {{ $t('sidebar.missing_payment_text') }}
          </p>
        </template>
      </Alert>
      <CareerCoachBanner v-if="currentUser.addon_catalogs && currentUser.addon_catalogs.indexOf('career_coach') > -1" />
      <!-- line-divider -->
      <div class="self-stretch h-px bg-slate-200" />
      <div class="gap-2 hidden md:flex">
        <div class="text-gray-900 text-sm leading-tight">
          Powered by
        </div>
        <img
          class="h-4"
          src="../../assets/logo_viblio_transparent_short_blue.svg"
        >
      </div>
      <div class="flex flex-col md:hidden gap-2">
        <div
          class="flex gap-2 cursor-pointer"
          data-test="navbar-support"
          @click="toSupport"
        >
          <i class="uil uil-envelope text-gray-500" />
          <div class="text-sm">
            {{ $t('support.nav_bar_text') }}
          </div>
        </div>
        <div
          class="flex gap-2 cursor-pointer"
          data-test="navbar-support"
          @click="toProfile"
        >
          <i class="uil uil-user text-gray-500" />
          <div class="text-sm">
            {{ $t('user.profile') }}
          </div>
        </div>
        <a
          data-test="logout"
          class="flex gap-2 cursor-pointer"
          @click="logoutAndRedirect"
        >
          <div class="text-sm">
            {{ $t('user.logout') }}
          </div>
        </a>
      </div>
    </div>
    <Dialog
      v-model:visible="showProfileDialog"
      class="w-full sm:w-[1040px] m-4"
      modal
      :draggable="false"
      :header="$t('user.profile')"
    >
      <Profile
        @closeDialog="
          () => {
            showProfileDialog = false
          }
        "
      />
    </Dialog>
    <Dialog
      v-model:visible="showSupportDialog"
      class="m-4"
      :header="$t('support.title')"
      modal
      :draggable="false"
    >
      <Support
        @closeDialog="
          () => {
            showSupportDialog = false
          }
        "
      />
    </Dialog>
  </div>
</template>
<script setup>
import { defineEmits, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useUserData } from '@/composables/userData'
import OrganizationItem from '@/components/sidebar/OrganizationItem.vue'

import Alert from '@/components/Alert.vue'
import SideBarMenuItem from './SideBarMenuItem.vue'
import { useUserRoles } from '../../composables/userData'
import { computed } from 'vue'
import Profile from '@/components/Profile.vue'
import Support from '@/components/Support.vue'
import Dialog from 'primevue/dialog'

import { useWindowSize } from '@vueuse/core'
import CareerCoachBanner from './CareerCoachBanner.vue'

const { isAuthenticated, logoutUser, getUser } = useUserData()
const { userOrganizationId, userIsMember, userIsAdmin, userIsAnyAdmin } = useUserRoles()

const { t } = useI18n()
const router = useRouter()
const currentUser = getUser()
const route = useRoute()
const currentRoute = computed(() => router.currentRoute.value)
const currentOrganizationId = computed(() => {
    return ['plan-users', 'organization-users', 'organization-groups'].indexOf(route.name) > -1 ? route?.params?.id : userOrganizationId()
  }
)
const organizationSubitems = computed(() => {
  const items = [
    {
      label: t('sidebar.plans'),
      active: currentRoute.name === 'plan-users',
      click: () =>
        navigate({
          name: 'plan-users',
          params: { id: currentOrganizationId.value }
        }),
    },
    {
      label: t('sidebar.users'),
      active: currentRoute.name === 'organization-users',
      click: () =>
        navigate({
          name: 'organization-users',
          params: { id: currentOrganizationId.value },
        }),
    },
    {
      label: t('sidebar.groups'),
      active: currentRoute.name === 'organization-groups',
      click: () =>
        navigate({
          name: 'organization-groups',
          params: { id: currentOrganizationId.value },
        }),
    },
  ]
  if (userIsAdmin()) {
    const orgItem = {
      label: t('sidebar.organizations'),
      active: currentRoute.name === 'organizations',
      click: () =>
        navigate({
          name: 'organizations',
          params: { id: userOrganizationId() },
        }),
    }
    items.splice(0, 0, orgItem);
  }
  return items;
})

const emit = defineEmits(['close'])
const { width } = useWindowSize()
const isMobile = computed(() => width.value < 600)

const navigate = (route) => {
  router.push(route)
  emit('close')
}

const planData = currentUser.getCurrentPlanTrialEndDate()

// const navigateToSettings = () => {
//   const route = userIsAdmin() ? { name: 'organizations' } : { name: 'organization', params: { id: userOrganizationId() } }
//   router.push(route)
// }

const logoutAndRedirect = () => {
  logoutUser()
}

const showProfileDialog = ref(false)
const showSupportDialog = ref(false)
const toProfile = () => {
  showProfileDialog.value = true
}
const toSupport = () => {
  showSupportDialog.value = true
}
</script>
