<template>
  <CardCarousel
    :id="id"
    :name="name"
    :data="data"
    :system-type="systemType"
    :is-loading="isLoading"
    :only-create-mode="onlyCreateMode"
  />
</template>
<script setup>
import { defineProps } from 'vue';
import useCollectionItems from '@/composables/useCollectionItems'
import CardCarousel from './CardCarousel.vue';

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  systemType: {
    type: String,
    default: ''
  },
  onlyCreateMode: { type: Boolean, default: false},
  resourcesIds: { type: Array, default: () => []},
})
const { data, isLoading } = useCollectionItems(props.id, props.resourcesIds, 10)
</script>
