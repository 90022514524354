
export default class BaseModel {
  constructor(data) {
    this.id = data.id || "NO_ID"; // new UUID()

    this.permissions = {
      edit: true,
      delete: true,
      manage: false,
      select: true,
    };

    return this;
  }

  static forApi(recordObj) {
    let recordData = {};
    Object.getOwnPropertyNames(recordObj).forEach(key => {
      recordData[key] = recordObj[key];
    });
    return recordData;
  }

  thisForApi() {
    return BaseModel.forApi(this);
  }

  // static getDataForPatchOperation(data){
  //   const newObj = {};
  //   for(const i in data) {
  //     if(PATCH_FIELDS.includes(i)) {
  //       if(i === 'image' && this.image.is_placeholder) { continue; }
  //       if(i === 'resources') { newObj[i] = data[i].map(res=>{
  //         return ((typeof res) == 'object') ? res.id : res;
  //       }); }
  //       else {
  //         newObj[i] = data[i];
  //       }
  //     }
  //   }
  //   return newObj;
  // }

  static asDate(date) {
    try {
      const asDate = new Date(date);
      return asDate;
    } catch (e) {
      console.warn("Could not set date", date, e);
      return new Date(); // fallback: current date
    }
  }
  static asInputDate(date) {
    if (!date) {
      return null;
    }
    let input = date;
    if (!(input  instanceof Date)){
      input = BaseModel.asDate(date);
    }
    return input.toISOString().split('T')[0];
  }
  static asOutputDate(date, locale) {
    if (!date) {
      return null;
    }
    let input = date;
    if (!(input  instanceof Date)){
      input = BaseModel.asDate(date);
    }
    return input.toLocaleDateString(locale);
  }


  static getValidListOptions(records, fieldName, defaultEmptyLabel, addEmptyItem=true){
    let forListBoxOption = [];
    if (addEmptyItem) {
      forListBoxOption.push({key: 0, label: defaultEmptyLabel});
    }
    if (records) {
      forListBoxOption = forListBoxOption.concat(records.map(record => {
        return record.convertForListBoxOption(fieldName);
      }));
    }
    return forListBoxOption;
  }



  convertForListBoxOption(fieldName) {
    return {
      key: this.id,
      label: this[fieldName],
    };
  }

  static fromApiResult(recordData) {
    // const mappedData = {
    //   id: recordData.id,
    //   creator: recordData.creator,
    //   title: recordData.title,
    //   description: recordData.description,
    //   userId: recordData.user_id,
    //   active: recordData.active,
    //   resources: recordData.resources,
    //   image: recordData.image,
    //   share: recordData.share,
    //   created: recordData.date_create,
    //   updated: recordData.date_update
    // };
    const modelObject = new BaseModel(recordData);

    return modelObject;
  }

//   static parseError(data) {
//     if (data === undefined)
//       return ["Errore generico"];
//
//     const keys = Object.keys(data);
//     let errors = [];
//     if (Array.isArray(data[keys]) && data[keys].length > 0) {
//         for (let i = 0; i < data[keys].length; i++) {
//             errors.push(data[keys][i].message || keys[i]+": " + data[keys][i]);
//         }
//     } else if (data) {
//       for (let j = 0; j < keys.length; j++) {
//         if (typeof data[keys[j]] === 'string') {
//           errors.push(data[keys[j]]);
//         }else if(typeof data[keys[j]] === 'object'){
//           data.map(err => {
//             errors.push(err);
//           });
//         }
//       }
//     } else {
//         errors.push("Operazione fallita");
//     }
//     return errors;
// }
}
