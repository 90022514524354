<template>
  <div class="new-collection flex flex-col gap-6">
    <Alert
      v-if="errorResponse"
      :title="$t('errors.general_title')"
      type="error"
    >
      <template #content>
        {{ $t('errors.general_msg') }}
      </template>
    </Alert>
    <InputWrapper
      name="name"
      type="text"
      label="collections.new_collection_name"
      :required="true"
      :field-error="!coll.title && showError"
    >
      <InputText
        v-model.trim="coll.title"
        name="name"
        :placeholder="$t('collections.new_collection_title_placeholder')"
        :invalid="!coll.title && showError"
        min-value="1"
      />
    </InputWrapper>
    <InputWrapper
      name="description"
      type="area"
      label="general.description"
    >
      <Textarea
        v-model.trim="coll.description"
        :placeholder="$t('collections.new_collection_description_placeholder')"
        rows="3"
      />
    </InputWrapper>

    <div class="flex flex-col-reverse gap-4 sm:flex-row sm:justify-end">
      <Button
        type="button"
        :label="$t('general.cancel')"
        severity="tertiary"
        @click="cancel"
      />
      <Button
        type="button"
        data-test="collection-manager-create-new"
        :label="$t(refCreate ? (course.id !== 'NO_ID' ? 'collections.create_new': 'collections.create_cta') : 'general.save')"
        :loading="isSaving"
        @click="addToNewCollection"
      />
    </div>
  </div>
</template>

<script setup>
import Button from "primevue/button";
import InputWrapper from "@/components/common/InputWrapper.vue";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import {defineEmits, defineProps, ref} from "vue";
import {useUserCollections} from "@/composables/useUserCollections";
import {useToasts} from "@/composables/useToasts";
import Resource from "@/resources/Resource";
import Collection from "@/resources/Collection";
import Alert from "@/components/Alert.vue";

const { createCollection, updateCollection } = useUserCollections()
const { toastSuccess } = useToasts();

  // name: "CollectionForm",
const props = defineProps({
    create: { type: Boolean, required: true },
    show: { type: Boolean, default: false },
    collection: { type: Collection, default: () => {return new Collection({}); }},
    course: { type: Resource, default: () => {return new Resource({}); }},
});
const emit = defineEmits(['close-modal', 'refresh-detail'])

const originalColl = {...props.collection};
const coll = ref(originalColl);

const refCreate = ref(props.create);
const showError = ref(false);
const errorResponse = ref(false);
const isSaving = ref(false);

const cancel = () => {
  coll.value = props.collection
  emit('close-modal')
}
const addToNewCollection = async () => {
  errorResponse.value = false;
   if(!coll.value.title) {
      showError.value = true
      return;
    }
   isSaving.value = true;
   const resource_id = props.course && props.course.id !== 'NO_ID' ? [props.course.id] :[];
   if (props.create) {
     createCollection(coll.value.title, coll.value.description, resource_id)
       .then((res) => {
         if (res && !res.error) {
           toastSuccess('', refCreate.value ? (props?.course?.id === 'NO_ID' ? 'collections.create_success' : 'collections.create_add_success') : 'collections.course_added', {
             translation: {
               collection: coll.value.title,
               collection_id: res.id
             }
           })
           emit('close-modal')
           emit('refresh-detail')
         } else {
           console.log('then', res.error, res)
           errorResponse.value = true;
         }
       })
       .catch((e) => {
         isSaving.value = false;
         errorResponse.value = true;
         console.log('catch', e)
       })
   } else {
     updateCollection({
       title: coll.value.title,
       description: coll.value.description,
       id: coll.value.id,
     }).then( (newData) =>  {
       if(newData && !newData.error){
         toastSuccess('', 'collections.collection_updated',{ translation: {name: coll.value.title}});
           emit('close-modal')
           emit('refresh-detail', newData)
       }
     })
   }
}
</script>

