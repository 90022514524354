import { ref } from 'vue'
import { authorizedRequest } from '@/api'
import config from "@/config";

import Resource from '@/resources/Resource'

export default function useSpecialCollection(id) {
  const data = ref([])
  const isLoading = ref(true)
  const error = ref(null)

  const loadCollection = async () => {
    try {
      isLoading.value = true
      const result = await authorizedRequest().get(config.BACKEND_URL+`/api/v2/drf/resources/${id}/`);
      data.value = result.data.results.map(res => new Resource(res));
    } catch (e) {
      console.error('[Detail.vue] error: ' + e)
      isLoading.value = false
    }
    isLoading.value = false
  }
  loadCollection()
  const refresh = async () => {
    loadCollection()
  }
  return { data, isLoading, error, refresh }
}
