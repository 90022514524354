<template>
  <div>
    <div
      class="shadow w-full flex-row justify-start items-start inline-flex rounded-xl bg-white relative cursor-pointer"
      @click="$emit('open-details')"
    >
      <div
        class="flex-col justify-start items-start flex m-2 sm:m-0 sm:h-[188px] sm:w-[172px] h-[112px] w-[96px] flex-shrink-0 rounded-l-xl"
      >
        <SmartImageBackground
          class="self-stretch grow shrink basis-0 p-0 sm:p-2 bg-cover bg-center bg-no-repeat rounded-lg sm:rounded-l-xl sm:rounded-r-none"
          :src="course.image?.src"
          :placeholder="course.placeholder?.src"
        >
          <div class="flex justify-between">
            <ProviderLogo
              class="flex sm:hidden"
              size="small"
              :provider="course?.provider"
            />
            <ProviderLogo
              class="hidden sm:flex"
              size="normal"
              :provider="course?.provider"
            />
          </div>
        </SmartImageBackground>
        <div class="absolute bottom-2 sm:hidden">
          <SkillLevelIndicator
            :level="course.level"
          />
          <div
            v-if="collections_count > 0"
            class="flex gap-1 text-sm font-bold text-blue-700"
          >
            <i class="icon-folder-heart-filled" />
            {{ $t('collections.in_collection') }}
          </div>
        </div>
      </div>
      <div
        class="p-4 w-full bg-white flex flex-col gap-4 justify-between self-stretch rounded-r-xl"
      >
        <div class="flex-col w-full gap-1 flex">
          <div
            class="flex gap-2"
          >
            <div class="flex gap-1">
              <Pill
                type="info"
                :text="$t(`course_results.type_values.${course.resource_type.toLowerCase().replace(' ', '_')}`)"
                :icon="resourceIconMap[course.resource_type || 'DEFAULT']"
              />
              <Pill
                v-if="currentUser?.addon_catalogs.indexOf(course.catalog) > -1"
                type="info-dark"
                :text="$t('card.included')"
              />
              <Pill
                v-else-if="course.price === 0"
                type="success"
                :text="$t('card.free')"
              />
              <Pill
                v-else
                type="warn"
                :text="$t('card.paid')"
              />
            </div>
            <Button
              v-if="removeFromCollection"
              severity="interactive"
              icon="p-button-icon uil uil-trash"
              class="w-6 h-6 p-[0!important] text-gray-700 bg-gray-100 hover:bg-gray-300 hover:border-transparent focus:border-blue-700 ml-auto"
              @click="onRemoveFromCollection"
            />
          </div>
          <div class="justify-start items-start gap-1 inline-flex">
            <div
              v-if="course.completed"
              class="text-lg uil uil-check-circle text-green-600"
            />
            <div class="grow shrink basis-0 text-gray-900 text-lg font-bold leading-normal line-clamp-1">
              {{ course.title }}
            </div>
          </div>
          <div
            class="text-slate-700 text-sm font-normal leading-tight line-clamp-2 min-h-8"
            v-html="course.description"
          />
        </div>
        <div
          class="flex gap-4 mt-auto h-8 w-full justify-between"
        >
          <div class="hidden sm:flex sm:flex-col">
            <SkillLevelIndicator
              :level="course.level"
            />
            <div
              v-if="!removeFromCollection && collections_count > 0"
              class="flex gap-1 text-sm font-bold text-blue-700"
            >
              <i class="icon-folder-heart-filled" />
              {{ $t('collections.in_collection') }}
            </div>
          </div>
          <div class="flex gap-4 ml-auto">
            <LikeUnlike
              :resource="course"
              :status="course.user_resource_status"
            />
            <Button
              v-if="addToCollection"
              class="w-8 h-8 flex-shrink-0 ml-auto z-0"
              icon="uil uil-folder-heart"
              severity="secondary"
              @click="showAddCourseToCollectionModal"
            />
            <Button
              class="w-full hidden sm:flex"
              :label="$t('card.more_details')"
              size="small"
              @click="$emit('open-details')"
            />
            <Button
              class="w-8 h-8 flex-shrink-0 sm:hidden"
              icon="uil uil-arrow-right"
              @click="$emit('open-details')"
            />
          </div>
        </div>
      </div>
    </div>
    <CollectionManager
      v-if="isAuthenticated && course"
      v-model="showDialog"
      :fit="true"
      :course="course"
      class="w-[600px]"
      data-test="add-to-collection"
      @refreshDetail="refreshDetail"
    />
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref} from 'vue'
import Button from 'primevue/button'
import SkillLevelIndicator from './SkillLevelIndicator.vue';
import LikeUnlike from './common/LikeUnlike.vue';
import SmartImageBackground from './common/SmartImageBackground.vue';
import Pill from './common/Pill.vue';
import Resource from "@/resources/Resource";

import { useUserData } from "@/composables/userData";
import CollectionManager from "@/components/collections/CollectionManager.vue";
import ProviderLogo from "@/components/common/ProviderLogo.vue";

const { getUser, isAuthenticated } = useUserData();
const currentUser = getUser()

const resourceIconMap = {
  AUDIO: 'uil uil-headphones',
  VIDEO: 'uil uil-video',
  PODCAST: 'uil uil-microphone',
  DOCUMENT: 'uil uil-file',
  COURSE: 'uil uil-tv-retro',
  WEBINAR: 'uil uil-webcam',
  YOUTUBE_PLAYLIST: 'uil uil-layer-group',
  CODECADEMY_PLAYLIST: 'uil uil-layer-group',
  DEFAULT: 'uil uil-list-ul',
}

const props = defineProps( {
  course: { type: Resource, required: true},
  removeFromCollection: { type: Boolean, default: false},
  addToCollection: { type: Boolean, default: true}
})

const collections_count = ref(0)
const showDialog = ref(false);
collections_count.value = props.course.collections

const showAddCourseToCollectionModal = (event) => {
  event.stopPropagation();
  showDialog.value = true;
}

const emit = defineEmits(['remove-resource', 'open-details'])
const onRemoveFromCollection = (event) => {
  event.stopPropagation();
  emit('remove-resource')

}
const refreshDetail = async (id) => {
  console.log('TODO: refreshDetail',id)
  collections_count.value = collections_count.value + 1
}

</script>
