import { ref } from 'vue';
import {useUserRoles} from '@/composables/userData';
import {getCollections} from "@/api/collections";


export default function useCollectionsForUser(load_type) {

  const { userOrganizationId, userId } = useUserRoles();

  const currentUserId = userId();
  const isLoading = ref(true);
  const collectionList = ref({
    'personal': [],
    'company': [],
    'share_group': [],
    'share_user': []
  });
  const loadedCollections = ref({
    'personal': false,
    'company': false,
    'share_group': false,
    'share_user': false
  });

  const params = {
    'personal': [{
      field: 'user',
      value: currentUserId
    }],
    'company':  [{
      field: 'share',
      value: 'SHARE_ORG'
    }, {
      field: 'creator',
      value: 'USER'
    }, {
      field: 'shared_with_organization',
      value: userOrganizationId()
    }],
    'share_group': [{
      field: 'shared_with_user_groups',
      value: currentUserId
    }],
    'share_user': [{
      field: 'shared_with_user',
      value: currentUserId
    }]
  };

  const loadCollections = (type, forceLoad) => {
    isLoading.value = true;
    if ((collectionList.value[type] && collectionList.value[type].length < 1 ) || forceLoad) {
      getCollections(-1, 0, params[type]).then((response) => {
        isLoading.value = false;
        if (response.error) {
          console.log('getCollections, error!', response, response.error);
          loadedCollections.value[type] = true;
        } else {
          if (type === 'share_user') {
            collectionList.value[type] = (response.results || []).filter(coll => coll.shareLevel !== 'orggroup') || [];
          } else {
            collectionList.value[type] = response.results || [];
          }
          loadedCollections.value[type] = true;
        }
      });
    }
  };
  loadCollections(load_type);
  const refreshCollections = (type) => {
    loadCollections(type, true);
  };

  return {
    collectionList,
    loadedCollections,
    refreshCollections,
    isLoading
  };
}
