<template>
  <div>
    <ForYouCard
      v-if="currentUser.itw_status === 'REPORT_READY'"
      :title="$t(itwData.title)"
      :description-small="$t(itwData.subtitle)"
      :description="description"
      color="red"
      @click="
        $router.push({
          name: 'collection-detail',
          params: { id: 'interview|interview' },
        })
      "
    />
    <div
      v-else
      class="w-[216px] h-[216px] md:w-[248px] md:h-[248px] rounded-2xl overflow-hidden bg-red-500/[.08] flex-shrink-0
      transition ease-in-out duration-500
      hover:shadow-wide
      focus:outline-2
      focus:outline-red-5000"
      tabindex="0"
    >
      <div class="relative">
        <LogoInterviewRound />
      </div>
      <div class="flex flex-col h-full justify-between p-4">
        <div v-if="['IN_PROGRESS', 'FAILED'].indexOf(currentUser.itw_status) > -1">
          <div
            class="w-8 h-8 rounded-full flex justify-center items-center text-xl"
            :class="{
              'bg-blue-900 text-blue-100': currentUser.itw_status === 'IN_PROGRESS',
              'bg-orange-500 text-orange-50': currentUser.itw_status === 'FAILED',
            }"
          >
            <i
              class="uil"
              :class="{
                'uil-hourglass': currentUser.itw_status === 'IN_PROGRESS',
                'uil-exclamation-octagon': currentUser.itw_status === 'FAILED',
              }"
            />
          </div>
        </div>
        <div>
          <div class="text-base sm:text-lg font-bold text-blue-900">
            {{ $t(itwData.title) }}
          </div>
          <div
            v-if="itwData.subtitle"
            class="text-blue-900 mt-5 md:mt-14"
          >
            {{ $t(itwData.subtitle) }}
          </div>
        </div>
        <Button
          v-if="itwData.has_cta"
          data-test="banner-cta"
          severity="secondary"
          icon="uil-arrow-right"
          icon-pos="right"
          class="p-0 sm:p-3 underline w-fit"
          :label="$t(itwData.has_cta)"
          @click="itwData.cta_action()"
        />
      </div>
    </div>
    <InterviewModal
      v-model="showDialog"
      :mode="mode"
    />
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import InterviewModal from "@/views/InterviewModal.vue";
import LogoInterviewRound from "@/components/cards/LogoInterviewRound.vue";
import Button from "primevue/button";
import ForYouCard from "@/components/cards/ForYouCard.vue";
import {useUserData} from "@/composables/userData";


defineProps({
  description: { type: String, default: '' }
})

const mode = ref('initialize')
const showDialog = ref(false)
const { getUser  } = useUserData();
const currentUser = getUser()
const itwData = computed(() => {
    return {
      'title': currentUser.itw_status === '' ? 'interview.none_title' : (
        currentUser.itw_status === 'CREATED' ? 'interview.created_title' : (
        currentUser.itw_status === 'IN_PROGRESS' ? 'interview.in_progress_title' : (
        currentUser.itw_status === 'FAILED' ? 'interview.banner_failed' : (
        currentUser.itw_status === 'REPORT_READY' ? 'interview.done_title' : ''
      )))),
      'subtitle': currentUser.itw_status === 'IN_PROGRESS' ? 'interview.in_progress_subtitle' : (
        currentUser.itw_status === 'REPORT_READY' ? 'interview.done_subtitle' : ''
      ),
      'has_cta': currentUser.itw_status === '' ? 'interview.none_cta' : (
        currentUser.itw_status === 'CREATED' ? 'interview.created_button' : (
        currentUser.itw_status === 'FAILED' ? 'interview.failed_cta' : ''
      )),
      'cta_action': ['', 'CREATED', 'FAILED' ].indexOf(currentUser.itw_status) > -1 ? showInterviewModal : false,
    };
  }
)

const showInterviewModal = () => {
    mode.value = currentUser.itw_status === 'CREATED' ? 'continue' : 'initialize';
    showDialog.value = true;
}
</script>
