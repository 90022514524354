<template>
  <div
    class="px-4 py-2 bg-blue-100 rounded-lg border-l-8 border-l-blue-300 [border-left-style:solid] justify-start items-start gap-2 inline-flex"
    :class="{
      'bg-blue-100 border-l-blue-300': type === 'info',
      'bg-green-100 border-l-green-300': type === 'confirm',
      'bg-orange-100 border-l-orange-300': type === 'warning',
      'bg-red-100 border-l-red-300': type === 'error',
      'bg-gray-100 border-l-gray-300': type === 'neutral'
    }"
  >
    <i
      class="uil"
      :class="{[icon]: icon,
               'text-blue-700': type === 'info',
               'text-green-700': type === 'confirm',
               'text-orange-700': type === 'warning',
               'text-red-700': type === 'error',
               'text-gray-700': type === 'neutral'
      }"
    />
    <div class="w-full flex-col sm:flex-row justify-between items-start gap-4 flex">
      <div
        class="flex-col justify-center items-start inline-flex"
        :class="{
          'text-blue-700': type === 'info',
          'text-green-700': type === 'confirm',
          'text-orange-700': type === 'warning',
          'text-red-700': type === 'error',
          'text-gray-700': type === 'neutral'
        }"
      >
        <div class="self-stretch text-base font-bold">
          {{ title }}
        </div>
        <div class="self-stretch text-xs">
          <slot name="content" />
        </div>
      </div>
      <slot name="cta" />
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'
const icons = {
  info: 'uil-info-circle',
  confirm: 'uil-check',
  warning: 'uil-exclamation-octagon',
  error: 'uil-exclamation-triangle',
  neutral: 'uil-info-circle'
}
const props = defineProps({
  type: { type: String, default: 'info' },
  icon: { type: String, default: '' },
  title: { type: String, default: '' }
})

const icon = props.icon ? props.icon : icons[props.type];

</script>
