<template>
  <transition name="fade">
    <div
      v-show="isOpen"
      class="modal-backdrop"
      :class="{ open: isOpen }"
      @click="closeModal()"
    >
      <div
        class="modal-cls"
        :class="[
          large && (!expired || allowedInExpired) ? 'modal-large' : 'modal-dialog',
          fit || (expired && !allowedInExpired) ? 'modal-fit' : '',
          modalClass ? modalClass : ''
        ]"
        :style="modalStyle"
        @click.stop
      >
        <button
          v-if="!unclose"
          class="modal-close"
          @click="$emit('on-close', true)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path
              d="M0 0h24v24H0z"
              fill="none"
            />
          </svg>
        </button>
        <div
          v-if="!expired || allowedInExpired"
        >
          <div
            v-if="title"
            class="modal-title"
          >
            {{ title }}
          </div>
          <div class="modal-body">
            <slot />
          </div>
        </div>
        <ModalForExpired v-else />
      </div>
    </div>
  </transition>
</template>

<script>

import {getCurrentSubscription} from '@/composables/userData.js';
import ModalForExpired from "@/components/common/ModalForExpired.vue";

export default {
  components: {
    ModalForExpired
  },
  props: {
    isOpen: Boolean,
    title: { type: String, default: '' },
    large: Boolean,
    fit: Boolean,
    allowedInExpired: { type: Boolean, default: false },
    modalClass: { type: String, default: '' },
    modalStyle: { type: String, default: '' },
    unclose: {type: Boolean, default: false},
  },
  setup(props, { emit }){
    const subscription = getCurrentSubscription();
    const expired = !subscription || ['active', 'pending'].indexOf(subscription.plan_status) === -1;
    const closeModal = () => {
      if(!props.unclose){
        emit('on-close')
      }
    }
    return {
      expired,
      closeModal
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter .modal-dialog,
.fade-leave-to .modal-dialog {
  transform: translateY(-20%);
}

.modal-backdrop {
  background: rgba(47, 45, 45, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.modal-cls {
  background: rgb(255, 255, 255);
  padding: 1.5rem 2rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.6rem;
  transition: 0.5s;
  position: relative;
  overflow-y: auto;
}

.modal-dialog {
  width: 30rem;
  height: 90%;
}

.modal-large {
  width: 60rem;
  height: 90%;
}

.modal-fit {
  width: 37rem !important;
  height: fit-content;
}
.modal-close {
  background: none;
  border: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  outline: none;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close svg {
  fill: rgb(150, 150, 150);
}

.modal-close svg:hover {
  fill: rgb(100, 100, 100);
}

.modal-title {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: rgb(100, 100, 100);
}

</style>
