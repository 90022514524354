<template>
  <div
    v-if="appLoading"
    class="page-spinner-container"
  >
    <img src="../assets/viblio-spinner.png">
  </div>
</template>
<script>
import { appLoading } from '../stores';
export default {
  setup() {
    return {
      appLoading
    }
  },
}
</script>
<style scoped>
.page-spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
}

</style>