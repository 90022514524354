<template>
  <div class="p-4 bg-blue-50 rounded-lg flex-col justify-start items-start gap-4 flex shrink-0">
    <img
      class="w-[30px] h-[30px]"
      src="../../assets/mascot.svg"
    >
    <div class="self-stretch flex-col justify-start items-start gap-1 flex">
      <div class="self-stretch text-blue-700 font-extrabold">
        <span class="font-extrabold leading-tight">{{ $t('career_coach.lead') }}</span>
      </div>
      <div class="self-stretch text-blue-700 text-sm leading-tight">
        {{ $t('career_coach.sublead') }}
      </div>
    </div>
    <div class="w-full">
      <Button
        size="small"
        :label="$t('career_coach.cta')"
        class="w-full gradient-background"
        icon="uil uil-arrow-right"
        icon-pos="right"
        @click="router.push({name: 'career-coach'})"
      />
    </div>
  </div>
</template>
<script setup>
import Button from 'primevue/button'
import { useRouter } from 'vue-router';
const router = useRouter()
</script>
<style scoped>
.gradient-background{
    background-image: linear-gradient(125deg, #004BE5 18.32%, #4ADE80 97.38%);
    background-clip: padding-box;
    transition: all 0.5s ease-in-out;
}
.gradient-background:hover{
  background: #162E91;
}
.gradient-background:focus{
  background: #004BE5;
}
</style>