<template>
  <div
    class="toast"
    :data-test="'toast-' + type"
  >
    <div class="inside">
      <strong
        v-if="title"
        v-html="title"
      />
      <div
        v-if="message"
        v-html="message"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TranslatedMessage',
  props: {
    title: { type: String, required: false, default: '' },
    message: { type: String, required: false, default: '' },
    type: { type: String, required: false, default: '' }
  }
}
</script>
