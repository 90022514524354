<template>
  <div class="container" />
</template>
<script>

import { ref } from "vue";
import {stripePortalSession} from "@/api/user";
import {useUserData} from "@/composables/userData";
import {ModalBus, ModalCommands} from "@/eventBus";

import Confirm from "@/components/common/Confirm.vue";
import ChangePlanForm from "@/components/management/plans/ChangePlanForm.vue";
import {useI18n} from "vue-i18n";

export default {
  name: 'ChangePlanFormWrapper',
  components: {
  },
  props: {
    currentPlan: { type: String, default: () => {} },
    choosePlan: { type: String, default: "" },
    personal: { type: Boolean, default: false },
    planStatus: { type: String, default: "" },
  },
  setup(props) {

    const { getUser } = useUserData()
    const { t } = useI18n()
    const currentUser = getUser()
    const plan = ref(props.currentPlan)

    if (props.planStatus === 'expired' && (!currentUser.organization_info || !currentUser.organization_info.payment_attached_date)) {
      ModalBus.emit(ModalCommands.OPEN, {
        component: Confirm,
        allowedInExpired: true,
        props: {
          title: t("plans.miss_payment_modal.title"),
          subtitle: t("plans.miss_payment_modal.subtitle"),
          message: "",
          confirmText: t("plans.miss_payment_modal.confirm"),
          cancelText: t('general.cancel'),
          fit: true,
          confirmFunction: () => {
            stripePortalSession(true)
          },
        },
      });
    } else {
      ModalBus.emit(ModalCommands.OPEN, {
        component: ChangePlanForm,
        allowedInExpired: true,
        props: {
          currentPlan: props.planStatus !== 'expired' ? (props.personal ? 'personal' : 'business') : null,
          choosePlan: props.choosePlan ? props.choosePlan : props.personal ? 'business' : 'personal',
          currentFrequency: plan.value ? plan.value.currentFrequency : 'monthly',
          newRenewalDateStr: plan.value ? plan.value.newRenewalDateStr : '',
          isFreeTrial: plan.value ? plan.value.isFreeTrial : false,
          activeUsers: plan.value ? plan.value.activeUsers : 1,
        },
        fit: true,
        modalClass: "plan-form-modal",
      })
    }
  },
}
</script>
<style scoped>
</style>
