import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import  TranslatedMessage from "@/components/common/TranslatedMessage";
export const useToasts = () => {
  const toast = useToast();
  const { t } = useI18n();
  const buildToastComponentArgs = (title, message, type, options) => {
    return {
      component: options?.component || TranslatedMessage,
      props: {
        title: title ? t(title, options?.translation): '',
        message: message? t(message, options?.translation): '',
        type: type
      }
    }
  }
  const toastSuccess = (title, message, opts = {}) => {
    toast.success(buildToastComponentArgs(title, message, 'success', opts), opts);
  }

  const toastInfo = (title, message, opts = {}) => {
    toast.info(buildToastComponentArgs(title, message, 'info', opts),opts);
  }

  const toastError = (title, message, opts = {}) => {
    toast.error(buildToastComponentArgs(title, message, 'error', opts),opts);
  }

  const toastWarning = (title, message, opts = {}) => {
    toast.warning(buildToastComponentArgs(title, message, 'warning', opts),opts);
  }


  return {
    toastSuccess,
    toastInfo,
    toastError,
    toastWarning
  }

}
