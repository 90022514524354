<template>
  <div class="footer-register">
    <div>
      <div class="part1">     
        <span class="reserved-rights"> © 2023 Viblio. All rights reserved.
          <a
            target="_blank"
            :href="linkTerms"
          >{{ $t('register.terms') }}</a>
          |  <a
            target="_blank"
            :href="linkPrivacy"
          >Privacy Policy</a>
          | <a
            target="_blank"
            :href="linkCookies"
          >Cookie Policy</a> 
        </span>
      </div> 
      <div
        ref="langMenu"
        class="footer-language"
      >
        <span
          class="locale-current"
          @click="toggleVisibilty"
        >{{ name_lang }} <span
          id="arrow"
          :class="(!isVisible)?'fas fa-angle-up':'fas fa-angle-down'"
          @click="toggleVisibilty"
        /></span>
        <ul
          v-if="isVisible"
          id="lang-menu"
        >
          <li
            class="language-menu"
            @click="updateLocale('en')"
          >
            <a
              class="language-item"
              @click="updateLocale('en')"
            >English</a>
          </li>
          <li
            class="language-menu"
            @click="updateLocale('it')"
          >
            <a
              class="language-item"
              @click="updateLocale('it')"
            >Italiano</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import { ref,onMounted } from 'vue';

export default {
  name: "FooterRegister",
  components:{},
  props: { 
            language: { type: String, default: "en" },
          },
  setup(props){
    const name_lang = ref("");
    const { locale } = useI18n();
    locale.value= props.language
    const langMenu = ref(null)

    const linkTerms = ref("");
    const linkPrivacy = ref("");
    const linkCookies = ref("");
  
    const setLinks = () => {
      linkTerms.value = (locale.value=='en')?"https://www.viblio.com/terms-and-conditions/":"https://www.viblio.com/termini-e-condizioni/";
      linkPrivacy.value = (locale.value=='en')?"https://www.viblio.com/privacy-policy-en/":"https://www.viblio.com/privacy-policy/";
      linkCookies.value = (locale.value=='en')?"https://www.viblio.com/cookie-policy-en/":"https://www.viblio.com/cookie-policy/";
    }
    const isVisible = ref(false);
    console.log(locale.value);
    const updateLocale = (lang) => {
      locale.value = lang
      name_lang.value = (lang=='en')?"English":"Italiano"
      setLinks();
    };
    const toggleVisibilty = () => {
        isVisible.value = !isVisible.value
        console.log(isVisible.value)
    }
    onMounted(()=>{
    document.addEventListener("click", (e)=>{
        if(e.target == langMenu.value || e.target.parentNode == langMenu.value) return
        isVisible.value = false;
    })
    })
    
    updateLocale(locale.value);
    
    return {name_lang,
        updateLocale,
        isVisible,
        toggleVisibilty,
        langMenu,
        linkTerms,
        linkPrivacy,
        linkCookies
    }
  }
 
}
</script>
<style>
.footer-register{
    display: flex;
    width: 83.66vw;
    margin-top: auto;
    margin-bottom: 4rem;
    margin-left: 5vw;
    margin-right: 5vw;
    flex-direction: column;
    gap: var(--space-4, 1rem);
    border-radius: var(--space-0, 0rem);
    justify-content: space-between;
}
.footer-register > div {
    display: flex;
    width: 100%;
    padding: var(--space-0, 0rem);
    justify-content: space-between;
    /* align-items: center; */
    border-radius: var(--space-0, 0rem);
}
.footer-register > div > span {
    color: var(--Gray-draken, #353538);
    font-family: Nunito Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.locale-current:hover{
  border: 1px solid var(--Primary-Blue-600, #1B64EF);
  color: var(--Grays-Gray-400, #94A3B8);
  cursor: pointer;
}

.locale-current{
  overflow: hidden;

color: var(--grays-gray-900-base, #121520);
font-feature-settings: 'calt' off;
text-overflow: ellipsis;
/* Paragraph/Normal/Regular */
font-family: "Nunito Sans";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
text-decoration: none;
flex: 1 0 0;
border-radius: var(--space-2, 0.5rem);

border: 1px solid var(--Primary-Blue-600, #1B64EF);
border: 1px solid var(--Grays-Gray-300, #CBD5E1);
background: var(--Grays-White, #FFF);
display: flex;
padding: 0.75rem var(--space-4, 1rem);

justify-content: center;
align-items: center;
gap: var(--space-2, 0.5rem);

align-self: stretch;
cursor: pointer;
}
#arrow{
    color: var(--Grays-Gray-400, #94A3B8);
    cursor: pointer;
}

#lang-menu{
 

padding: var(--space-2, 0.5rem) 0.0625rem;
flex-direction: column;
align-items: center;
border-radius: var(--space-2, 0.5rem);
border: 1px solid var(--Grays-Gray-200, #E2E8F0);
background: var(--Grays-White, #FFF);

/* Box shadow */
box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
position: relative;
    top: -11.5rem;
    list-style-type: none;
    
}
.language-menu{
  display: flex;
padding: 0.75rem 1rem;
justify-content: center;
align-items: center;
gap: 0.5rem;
align-self: stretch;
padding:0;
}
.language-item{
text-decoration: none;
color: var(--grays-gray-900-base, #121520);
display: flex;
padding: 0.75rem 1rem;
justify-content: center;
align-items: center;
gap: 0.5rem;
align-self: stretch;
cursor: pointer;
}
.language-item:hover{
  color: var(--Primary-Blue-600, #1B64EF);
  }
  @media screen and (max-width: 600px) {
    .reserved-rights{
      width:100%
    }
    .footer-register > div {
      display: block;
    }
    .part1{
      width:100%;
    }
    .footer-language{
      width:100%;
    }
  }
</style>
