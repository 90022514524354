<template>
  <div class="flex flex-col items-start gap-2">
    <div class="flex flex-row items-start gap-2 text-gray-900">
      <span class="font-bold">
        {{ $t('user_profile.job_title') }}:
      </span>
      <span>
        {{ jobNames.filter(name => !!name).join(', ') }}
      </span>
    </div>
    <div
      class="flex flex-row items-start gap-2 text-gray-900"
    >
      <span class="font-bold">
        {{ $t('user_profile.skills') }}:
      </span>
      <span
        v-if="skillNames.length > 0"
        class=""
      >
        {{ skillNames.join(', ') }}
      </span>
      <span v-else>
        {{ $t('user_profile.no_skills') }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "RolesAndSkills",
  props: {
    jobNames: {
      type: Array,
      default: () => []
    },
    skillNames: {
      type: Array,
      default: () => []
    },
  },
  setup(){
  }
}
</script>
<style scoped>
.jobs {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--space-4, 1rem);

  align-self: stretch;
}
</style>
