<template>
  <div class="flex w-full flex-col">
    <div
      tabindex="0"
      class="min-h-10 w-full p-2 bg-white rounded-lg justify-start items-center gap-2 inline-flex hover:bg-slate-100 cursor-pointer group border focus:outline-none border-transparent border-solid focus:border-blue-700"
      @click="click"
    >
      <div
        v-if="icon"
        class="w-6 h-6 relative group-hover:text-blue-700 group-focus:text-blue-700"
        :class="{'text-slate-400': disabled, 'text-blue-700': active}"
      >
        <i
          class="uil"
          :class="icon"
        />
      </div>
      <div class="flex flex-col">
        <div
          class="grow shrink basis-0"
          :class="{
            'bg-slate-100': disabled,
            'text-blue-700': active,
            'font-bold text-base': !subitem,
            'text-sm': subitem,
            'text-gray-900': !active
          }"
        >
          {{ label }}
        </div>
        <div
          v-if="desktopOnly"
          class="text-gray-500 text-xs font-bold flex md:hidden"
        >
          {{ $t('general.desktop_only') }}
        </div>
      </div>
      <div
        v-if="expandable"
        class="flex"
      >
        <i
          class="uil text-2xl"
          :class="{'uil-angle-up': expanded, 'uil-angle-down': !expanded}"
        />
      </div>
    </div>
    <div
      v-if="expandable && expanded"
      class="pl-8 my-2 flex-col justify-start items-start gap-1 flex"
    >
      <SideBarMenuItem
        v-for="item in subitems"
        :key="item.label"
        subitem
        :label="item.label"
        :disabled="item.disabled"
        :active="item.active"
        @click="item.click"
      />
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref } from 'vue'
const expanded = ref(false)
const emit = defineEmits(['click'])
const props = defineProps({
  label: { type: String, default: '' },
  subitem: { type: Boolean, default: false },
  subitems: { type: Array, default: () => [] },
  icon: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  active: { type: Boolean, default: false },
  expandable: { type: Boolean, default: false },
  desktopOnly: { type: Boolean, default: false },
})

const click = () => {
  if (props.expandable) {
    expanded.value = !expanded.value
  }
  emit('click')
}
</script>
