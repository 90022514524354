<template>
  <div class="small-modal">
    <div class="logo-container">
      <div class="logo-small" />
    </div>
    <div class="modal-title">
      {{ $t('agreement.title') }}
    </div>
    <div class="modal-text">
      {{ $t('agreement.text',{nome: currentUser.organization_info.name}) }}
    </div>
    <Button
      class="register-page-button modal-primary-button"
      :label="$t('agreement.button-ag')"
      @click="switchAgreement(true)"
    />
    <Button
      class="secondary-button"
      link
      :label="$t('agreement.button-not')"
      @click="switchAgreement(false)"
    />
    <div class="explain">
      {{ $t('agreement.undertext') }}
    </div>
  </div>
</template>
  <script>
import Button from 'primevue/button';
import { ModalBus, ModalCommands } from '@/eventBus'
import { useI18n } from "vue-i18n";
import { useUserData } from '../composables/userData';

  export default {
    name: "ModalAskForAgreement",
    components: {

     Button
    },
    setup() {
        const { getUser, callSetAgreement  } = useUserData();
        const currentUser = getUser()
        const { t } = useI18n();
        const closeModal = () => {
            ModalBus.emit(ModalCommands.CLOSE, {})
        }

        const switchAgreement = (value) => {
            callSetAgreement(currentUser.id,value)
            closeModal()
        }

        return { closeModal, t, switchAgreement,currentUser }
    }
}
</script>
<style>
.small-modal{
    display: flex;
width: 37.625rem;
padding: 1.5rem;
flex-direction: column;
align-items: flex-start;
gap: var(--space-2, 0.5rem);
border-radius: var(--space-5, 1.25rem);

background: var(--Gray-Gray-0, #FFF);
/* Box shadow */
box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
}
.modal-title{
    color: #000;

/* Highlight text/Bold */
font-family: "Nunito Sans";
font-size: 1.375rem;
font-style: normal;
font-weight: 700;
line-height: 2rem; /* 145.455% */
align-self: stretch;
}
.modal-text{
    color: #000;
font-feature-settings: 'calt' off;

/* Paragraph/Normal/Regular */
font-family: "Nunito Sans";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
align-self: stretch;
}
.explain{
    color: #000;
font-feature-settings: 'calt' off;

/* Paragraph/Extra small/Regular */
font-family: "Nunito Sans";
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 1rem; /* 133.333% */
align-self: stretch;
}
.secondary-button{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0.5rem;
align-self: stretch;
border-radius: 2.5rem;
color: var(--Primary-Blue-900, #162E91);
/* Paragraph/Big/Bold */
font-family: "Nunito Sans";
font-size: 1.125rem;
font-style: normal;
font-weight: 700;
line-height: 1.5rem; /* 133.333% */
align-self: stretch;
text-align:center;
width:100%;
}
.secondary-button > span{
    width:100%;
}
.modal-primary-button{
    font-family: "Nunito Sans";
font-size: 1.125rem;
font-style: normal;
font-weight: 700;
line-height: 1.5rem;
}
@media screen and (max-width: 600px) {

}
</style>
